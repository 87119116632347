import React, { useContext, useEffect, useState } from "react";
import CreateBrand from "../Components/CreateBrand";
import { fetchPosts } from "../Utils/FetchApis";
import { fetchGetPWKey } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import UserContext from "../Utils/User_Context";

type Props = {
  CancellBtn: () => void;
  successBtnHandler: () => void;
  token: any;
};
interface Option {
  label: string;
  value: string;
  children?: Option[];
}
function CreateBrandScreen(props: Props) {
  const navigation = useNavigate();
  useEffect(() => {
    let id = localStorage.getItem("id");
    let name = localStorage.getItem("username");
    let email = localStorage.getItem("email");
    if (id === null && name === null && email === null) {
      navigation("/login");
    }
    if (!isAdmin) {
    } else {
      getChannelListHandler();
    }
  }, []);

  const [imgData, setImgData] = useState<any>("");
  const [title, setTitle] = useState("");
  const [imgVal, setImgVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [imgUpload, setImgUpload] = useState<any>("");
  const [imageError, setImageError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [channelOptions, setChannelOptions] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  let id = localStorage.getItem("id");
  const { onHandleAdList, adMainList, isAdmin } = useContext(UserContext);

  // var imgData:any=''

  const handleFile = async (e: any) => {
    let extention = e.target.files[0].name.toLowerCase().endsWith(".webp");
    if (extention) {
      setImgVal(e.target.value);
      try {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setImgData(reader.result);
          setImgUpload(reader.result);
        };
      } catch (error) {
        console.log("handleFile error", error);
      }
    } else {
      setImgVal("");
      Swal.fire({
        icon: "error",
        title: "Image is not in valid format..!",
      });
    }
  };

  const brandAddHandler = async (e: string) => {
    setLoading(true);
    let img = imgData.split(",")[1];
    let data;
    if (isAdmin) {
      data = {
        name: e,
        channelId: selectedOption?.value,
        iconBase64: img,
      };
    } else {
      data = {
        name: e,
        channelId: id,
        iconBase64: img,
      };
    }

    if (data?.name && data.iconBase64) {
      try {
        let response = await fetchPosts(
          ApiNames.brandAdd,
          data,
          props.token.jwttoken
        );
        if (response.status === 0) {
          setLoading(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Brand Created",
            showConfirmButton: false,
            timer: 1500,
          });
          props.CancellBtn();
          props.successBtnHandler();
        } else if (response.status === 1) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.response,
          });
        }
      } catch (err) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something Went Wrong..!",
        });
      }
    } else {
      setLoading(false);
      setError(true);
    }
    if (data.name) {
      setNameError(false);
    } else {
      setNameError(true);
    }
    if (data.iconBase64) {
      setImageError(false);
    } else {
      setImageError(true);
    }
  };
  const getChannelListHandler = async () => {
    let array = [];

    try {
      const result = await fetchGetPWKey(
        ApiNames.channelView,
        props.token.jwttoken
      );
      if (result.status === 0) {
        let data = result.response;

        for (let i = 0; i < data.length; i++) {
          array.push({ label: data[i].name, value: data[i].id });
        }

        setChannelOptions(array);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.response,
        });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const handleOptionChange = (option: Option | null) => {
    setSelectedOption(option);
  };
  return (
    <>
      <CreateBrand
        CancellBtn={props.CancellBtn}
        handleFile={handleFile}
        brandAddHandler={brandAddHandler}
        imgData={imgData}
        title={title}
        imgVal={imgVal}
        loading={loading}
        imgUpload={imgUpload}
        nameError={nameError}
        imageError={imageError}
        setImageError={setImageError}
        setNameError={setNameError}
        channelOptions={channelOptions}
        handleOptionChange={handleOptionChange}
        selectedOption={selectedOption}
        isAdmin={isAdmin}
      />
    </>
  );
}

export default CreateBrandScreen;
