import React from "react";
import { DateRangePicker } from "react-date-range";
import format from "date-fns/format";
import data from "../Utils/data.json";
import { PieChart, Pie, ResponsiveContainer } from "recharts";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import img from "../Assets/analytics-default.png";
import ReactPaginate from "react-paginate";
import { LoadingOutlined } from "@ant-design/icons";
import "../StyleSheets/DashboardStyle.css";
import { Spin } from "antd";

type Props = {
  adData: any;
  brandData: any;
  deviceData: any;
  campaignData: any;
  channelData: any;
  isloader: boolean;
  data1: any;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  range: any;
  setRange: any;
  handleDayClick: () => void;
  handleWeekClick: () => void;
  handleMonthClick: () => void;
  hideOnEscape: (e: KeyboardEvent) => void;
  hideOnClickOutside: (e: MouseEvent) => void;
  refOne: React.RefObject<HTMLDivElement>;
  navigation: any;
  displayedUsers: {
    title: string;
    starttime: string;
    SMStext: string;
    subscribers: number;
    smssend: number;
    unsubcount: number;
    action: string;
  }[];
  handlePageChange: ({ selected }: { selected: number }) => void;
  pageCount: number;
  pageSize: number;
  search: (query1: string, query2: string) => void;
  filteredData: any;
  searchQuery1: string;
  setSearchQuery1: React.Dispatch<React.SetStateAction<string>>;
  searchQuery2: string;
  setSearchQuery2: React.Dispatch<React.SetStateAction<string>>;
};

function Dashboard(props: Props) {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "80px",
      }}
      spin
    />
  );
  return (
    <>
      {props.isloader === false ? (
        <div className="Main-Dashboard">
          <div className="Main-Dashboard-Header">
            <div className="Main-Dashboard-right">
              <h2 className="Main-Dashboard-title Dashboard-default-color" style={{marginLeft:"20px"}}>
                Dashboard
              </h2>
              <p className="Dashboard-default-color" style={{marginLeft:"20px"}}>
                Welcome to Dashboard
              </p>
            </div>
            <div className="Main-Dashboard-left">
              {/* <div className="calendarWrap">
              <input
                value={`${format(
                  props.range[0].startDate,
                  "MM/dd/yyyy"
                )}-${format(props.range[0].endDate, "MM/dd/yyyy")}`}
                readOnly
                className="inputBox "
                onClick={() => props.setOpen(true)}
              />

              <div ref={props.refOne} className="datepicker">
                {props.open && (
                  <DateRangePicker
                    onChange={(item: any) => props.setRange([item.selection])}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                    ranges={props.range}
                    months={2}
                    direction="horizontal"
                    className="calendarElement"
                  />
                )}
              </div>
            </div> */}
              {/* <ul>
              <li onClick={props.handleMonthClick}>
                <span>Monthly</span>
              </li>
              <li onClick={props.handleWeekClick}>
                <span>Weekly</span>
              </li>
              <li onClick={props.handleDayClick}>
                <span>Daily</span>
              </li>
            </ul> */}
            </div>
          </div>
          <div className="dashboardrow">
            <div className="Recent-Application">
              <div className="ibox">
                <div className="Recent-Application-Top">
                  <h5 className="Main-Dashboard-title">Recent ADs</h5>
                  {/* <span className="d-btn">Add New Applicant</span> */}
                </div>
                <div className="table">
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={1} rowSpan={1}>
                          Ad Name
                        </th>
                        <th colSpan={1} rowSpan={1}>
                          Channel
                        </th>
                        <th colSpan={1} rowSpan={1}>
                          Area
                        </th>
                        <th colSpan={1} rowSpan={1}>
                          Brand
                        </th>
                      </tr>
                    </thead>
                      {props.adData.length > 0 ?
                      <tbody>
                        {!props.searchQuery1
                          ? props.displayedUsers?.map(
                              (data: any, index: number) => (
                                <tr className="odd">
                                  <td>{data.title}</td>
                                  <td>{data.channel.name}</td>
                                  <td>{data.areas[0].name}</td>
                                  <td>{data.brand.name}</td>
                                </tr>
                              )
                            )
                          : props.filteredData?.map(
                              (data: any, index: number) => (
                                <tr className="odd">
                                  <td>{data.title}</td>
                                  <td>{data.starttime}</td>
                                  <td>
                                    <span className="label label-danger">
                                      Stopped
                                    </span>
                                  </td>
                                </tr>
                              )
                            )}
                      </tbody>
                      
                      :
                      <tbody>
                        <tr style={{height:"43px"}}>
                          <td>
                          <div style={{position: "absolute", textAlign: "center", left: "40%"}}>No Record Found</div>
                          </td>
                        </tr>
                        
                      </tbody>
                      }
                  </table>
                </div>
                {/* <span
                onClick={() => props.navigation("/campaigns/list")}
                className="text-center View-more"
                style={{ cursor: "pointer" }}
              >
                View More
              </span> */}
                <div className="d-bottom">
                  <div className="dataTables_info">
                    {/* Showing 1 to {props.displayedUsers.length} of {data.length}{" "}
                  entries */}
                  </div>
                  <div
                    className="bottom-navbar"
                    style={{
                      position: "absolute",
                      bottom: "9px",
                      right: "27px",
                    }}
                  >
                    <ReactPaginate
                      pageCount={props.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={1}
                      onPageChange={props.handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="CampaignsOverview">
              <div className="ibox" style={{height:'470px'}}>
                <h5 className="title">Ad Categories Overview</h5>
                {props.adData.length > 0?
                <div className="chart">
                  <ResponsiveContainer
                    className="ResponsiveContainer"
                    width="100%"
                    height={400}
                  >
                    <PieChart>
                      <Pie
                        data={props.data1}
                        dataKey="value"
                        outerRadius={150}
                        innerRadius={70}
                        fill="green"
                      />
                    </PieChart>
                  </ResponsiveContainer>
                  <div className="PieChartData">
                    <ul>
                      <li>
                        <div className="pending"></div>Gold
                      </li>
                      <li>
                        <div className="signed"></div>Platinum
                      </li>
                      <li>
                        <div className="conpleted"></div>
                        Silver
                      </li>
                    </ul>
                  </div>
                </div>:<div style={{textAlign:'center',marginTop:"160px"}}>No Record Found</div>}
              </div>
            </div>
          </div>
          <div className="dashboardBottom">
            <div className="dashboardbtm">
              <div className="ibox">
                {/* <h4>Pro Ad Block</h4> */}
                <h5>{props.campaignData?.length}</h5>
                <h5 className="title">Total Campaigns</h5>
                <img src={img} alt="missing" className="dashboardimg" />
              </div>
            </div>
            <div className="dashboardbtm">
              <div className="ibox">
                {/* <h4>Pro Ad Block</h4> */}
                <h5>{props.adData?.length}</h5>
                <h5 className="title">Total Ads</h5>
                <img src={img} alt="missing" className="dashboardimg" />
              </div>
            </div>
            <div className="dashboardbtm">
              <div className="ibox">
                {/* <h4>Pro Ad Block</h4> */}
                <h5>{props.deviceData?.length}</h5>
                <h5 className="title">Total Devices</h5>
                <img src={img} alt="missing" className="dashboardimg" />
              </div>
            </div>
            <div className="dashboardbtm">
              <div className="ibox">
                {/* <h4>Pro Ad Block</h4> */}
                <h5>{props.channelData?.length}</h5>
                <h5 className="title">Total Channels</h5>
                <img src={img} alt="missing" className="dashboardimg" />
              </div>
            </div>
            <div className="dashboardbtm">
              <div className="ibox">
                {/* <h4>Pro Ad Block</h4> */}
                <h5>{props.brandData?.length}</h5>
                <h5 className="title">Total Brands</h5>
                <img src={img} alt="missing" className="dashboardimg" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spin indicator={antIcon} />
      )}
    </>
  );
}

export default Dashboard;
