import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Login from "../Components/Login";
import ApiNames from "../Constants/ApiNames";
import { fetchPosts } from "../Utils/FetchApis";
import UserContext from "../Utils/User_Context";

type Props = {
  token: any;
};

const LoginScreen = (props: Props) => {
  let navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { setIsAdmin } = useContext(UserContext);

  const handleValues = async (
    username: string,
    password: string,
    captcha: string,
    captchaRef: any
  ) => {
    setIsLoader(true);
    let capt = captchaRef;
    const data = {
      username: username,
      password: password,
      recaptchaResponse: captcha,
    };

    try {
      let response = await fetchPosts(
        ApiNames.login,
        data,
        props.token.jwttoken
      );
      if (response.status === 0) {
        setErrorMsg("");
        setIsLoader(false);
        localStorage.setItem("email", response.response.email);
        localStorage.setItem("id", response.response.id);
        localStorage.setItem("username", response.response.username);
        localStorage.setItem("role", response.response.role);
        if (response.response.role === "ADMIN") {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
        navigate("/dashboard");
      } else if (response.status === 1) {
        setIsLoader(false);
        setErrorMsg(response.response);
        capt.reset();
      }
    } catch (err) {
      setErrorMsg("Somthing Went Wrong...!");
      setIsLoader(false);
    }
  };

  return (
    <Login
      handleValues={handleValues}
      isLoader={isLoader}
      errorMsg={errorMsg}
    />
  );
};

export default LoginScreen;
