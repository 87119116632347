import React, { useEffect, useState } from "react";
import EditBrand from "../Components/EditBrand";
import ApiNames from "../Constants/ApiNames";
import { useNavigate } from "react-router-dom";
import { fetchPosts } from "../Utils/FetchApis";
import Swal from "sweetalert2";

type Props = {
  CancellBtn: () => void;
  successBtnHandler: () => void;
  token: any;
  seletedBrand: any;
};

function EditBrandScreen(props: Props) {
  const navigation = useNavigate();
  useEffect(() => {
    let id = localStorage.getItem("id");
    let name = localStorage.getItem("username");
    let email = localStorage.getItem("email");
    if (id === null && name === null && email === null) {
      navigation("/login");
    }
  }, []);

  const [imgData, setImgData] = useState<any>("");
  const [imgVal, setImgVal] = useState("");
  const [imgUpload, setImgUpload] = useState<any>("");
  const [isLoader, setIsLoader] = useState(false);

  const handleFile = async (e: any) => {
    let extention = e.target.files[0].name.toLowerCase().endsWith(".webp");

    if (extention) {
      setImgVal(e.target.value);
      try {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
          let imgData = reader.result?.toString();
          setImgUpload(reader.result);
          let img = imgData?.split(",")[1];
          setImgData(img);
        };
      } catch (error) {
        console.log("handleFile error", error);
      }
    } else {
      setImgVal("");
      alert("Image is not in valid format..!");
    }
  };

  const brandAddHandler = async (e: any) => {
    setIsLoader(true);
    let id = props.seletedBrand.id;
    const data = {
      id: id,
      name: e,
      iconBase64: imgData,
    };
    try {
      let response = await fetchPosts(
        ApiNames.brandEdit,
        data,
        props.token.jwttoken
      );
      if (response.status === 0) {
        alert(response.response);
        props.successBtnHandler();
        props.CancellBtn();
        Swal.fire({
          position: "center",
          icon: "success",
          title: response.response,
          showConfirmButton: false,
          timer: 1500,
        });
        setIsLoader(false);
      } else if (response.status === 1) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.response,
        });
        setIsLoader(false);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
      setIsLoader(false);
    }
  };

  return (
    <>
      <EditBrand
        CancellBtn={props.CancellBtn}
        handleFile={handleFile}
        brandAddHandler={brandAddHandler}
        imgData={imgData}
        imgVal={imgVal}
        seletedBrand={props.seletedBrand}
        imgUpload={imgUpload}
        isLoader={isLoader}
      />
    </>
  );
}

export default EditBrandScreen;
