import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

type Props = {
  CancellBtn: () => void;
  handleFile: (e: any) => void;
  brandAddHandler: (e: any) => void;
  imgData: any;
  imgVal: string;
  seletedBrand: any;
  imgUpload: any;
  isLoader:boolean
};
interface FormValues {
  title: string;
}
function EditBrand(props: Props) {
  const validate = Yup.object({
    title: Yup.string().required("Please Enter Name"),
  });
  const formik = useFormik({
    initialValues: {
      title: props.seletedBrand.name,
    },
    validationSchema: validate,
    onSubmit: (values: FormValues) => {
      props.brandAddHandler(values.title);
    },
  });
  return (
    <>
      <div id="custom-modal">
        <div id="m-modal-content">
          <div className="modalTop">
            <h2>Edit Brand</h2>
            <span
              className="close"
              onClick={() => {
                props.CancellBtn();
              }}
            >
              &times;
            </span>
          </div>
          <div className="forminput newBrand">
            <div className="inputGroup">
              <label htmlFor="channelname">Title</label>
              <div className="innerInput">
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  placeholder="Title"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
              </div>
              <form action="#" className="dropzone" id="dropzoneForm">
                <div className="fallback">
                  <input
                    className="form-control"
                    type="file"
                    accept=".webp"
                    value={props.imgVal}
                    onChange={(e) => props.handleFile(e)}
                  />
                </div>
              </form>
            </div>
          </div>
          {props.imgUpload ? (
            <div className="img">
              <img src={props.imgUpload} alt="" />
            </div>
          ) : (
            <div className="img">
              <img src={props.seletedBrand.iconUrl} alt="" />
            </div>
          )}

          <div className="modalBtn">
            <button
              className="cancelbtn"
              onClick={() => {
                props.CancellBtn();
              }}
            >
              cancel
            </button>
            {props.isLoader? (
              <div
                className="spinner "
                style={{ marginLeft: "90px", marginTop: "15px" }}
              ></div>
            ) : (
              <button className="createbtn" onClick={formik.submitForm}>
                Create
              </button>
            )}
          </div>
        </div>
      </div>
      <div id="overlay"></div>
    </>
  );
}

export default EditBrand;
