import React from "react";
import "../StyleSheets/CommonListStyle.css";
// import { FaPlus } from "react-icons/fa";
import data from "../Utils/data.json";
import ReactPaginate from "react-paginate";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

type Props = {
  displayedUsers: any;
  displayedUsers2:any;
  handlePageChange: ({ selected }: { selected: number }) => void;
  pageCount: number;
  pageCount2:number
  search: (query1: string) => void;
  filteredData: any;
  searchQuery1: string;
  setSearchQuery1: React.Dispatch<React.SetStateAction<string>>;
  searchQuery2: string;
  setSearchQuery2: React.Dispatch<React.SetStateAction<string>>;
  isLoader:boolean
};

const CampaignView = (props: Props) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 60,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
      spin
    />
  );
  return (
    <>
    {props.isLoader? (
      <Spin indicator={antIcon} />):(
      <div className="Container" style={{minHeight:'78vh'}}>
        <div className="title-btn">
          <h2 className="title">Subscriptions Details</h2>
        <div className="search">
          <label>
            Search:
            <input
              type="text"
              name="name"
              value={props.searchQuery1}
              onChange={(e) => {
                props.setSearchQuery1(e.target.value);
                props.search(e.target.value);
              }}
            />
          </label>
        </div>
        </div>
        {props.searchQuery1 && props.displayedUsers2.length===0 || !props.searchQuery1 && props.displayedUsers.length===0 ?<div style={{textAlign:"center",marginTop:"200px",fontSize:"16px",fontWeight:"bold"}}>No Record Found</div>:<>
        <div className="table">
          <table>
            <thead>
              <tr>
                <th colSpan={1} rowSpan={1}>
                  Brand
                </th>
                <th colSpan={1} rowSpan={1} className="text-center">
                  Mobile Number
                </th>
                <th colSpan={1} rowSpan={1} className="text-center">
                  Subscriptions Date
                </th>
              </tr>
            </thead>

            <tbody>
              {!props.searchQuery1
                ? props.displayedUsers?.map((data: any, index: number) => (
                    <tr className="odd">
                      <td>{data.brand.name}</td>
                      <td className="text-center">{data.phoneNumber}</td>
                      <td className="text-center">{data.consentedAt}</td>
                    </tr>
                  ))
                : props.displayedUsers2?.map((data: any, index: number) => (
                    <tr className="odd">
                     <td>{data.brand.name}</td>
                      <td className="text-center">{data.phoneNumber}</td>
                      <td className="text-center">{data.consentedAt}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
        <div className="d-bottom" style={{position:"absolute",bottom:90,right:60}}>
          <div className="dataTables_info"></div>
          {!props.searchQuery1?
          <div className="bottom-navbar">
            <ReactPaginate
              pageCount={props.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={1}
              onPageChange={props.handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>:<div className="bottom-navbar">
            <ReactPaginate
              pageCount={props.pageCount2}
              marginPagesDisplayed={2}
              pageRangeDisplayed={1}
              onPageChange={props.handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>}
        </div>
        </>}
      </div>)}
    </>
  );
};

export default CampaignView;
