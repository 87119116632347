import React from "react";
import teslaIMg from "../Assets/teslabsolute.png";
import "../StyleSheets/CampaginDashBoardStyle.css";
import { useFormik } from "formik";

type Props = {
  setOpenEditChannel: React.Dispatch<React.SetStateAction<boolean>>;
  handleEditCampaign: (title: string, smsText: string) => void;
  setTitleError: React.Dispatch<React.SetStateAction<boolean>>;
  titleError: boolean;
  setDescriptionError: React.Dispatch<React.SetStateAction<boolean>>;
  descriptionError: boolean;
  selectedCampaign:any
  loading: boolean;
};
interface FormValues {
  title: string;
  smsText: string;
}

function EditCampagin(props: Props) {
  const formik = useFormik({
    initialValues: {
      title: props.selectedCampaign.title,
      smsText: props.selectedCampaign.smsText,
    },

    onSubmit: (values: FormValues) => {
      props.handleEditCampaign(values.title, values.smsText);
    },
  });
  return (
    <>
      <div id="m-modal-content">
        <div className="modalTop">
          <h2>Edit Campaign</h2>
          <span
            className="close"
            onClick={() => {
              props.setOpenEditChannel(false);
            }}
          >
            &times;
          </span>
        </div>
        <div className="forminput">
        <div className="inputGroup">
            <label htmlFor="channelname">Ad Title</label>
            <div className="innerInput">
              <input
                placeholder="title"
                type="title"
                name="title"
                className="form-control titlepad"
                onChange={(e) => {
                  formik.handleChange(e);
                  props.setTitleError(false);
                }}
                value={formik.values.title}
              />
              {props.titleError ? (
                <p className="error">Please Enter Title</p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="inputGroup">
            <label htmlFor="">SMS Text</label>
            <div className="innerInput">
              <textarea
                name="smsText"
                className="form-control"
                onChange={(e) => {
                  formik.handleChange(e);
                  props.setDescriptionError(false);
                }}
                value={formik.values.smsText}
                style={{
                  width: "100%",
                  borderRadius: "15px",
                  outline: "none",
                }}
              />
              {props.descriptionError ? (
                <p className="error">Please Enter Sms Text</p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className="modalBtn">
          <button
            className="cancelbtn"
            onClick={() => {
              props.setOpenEditChannel(false);
            }}
          >
            Cancel
          </button>
          {props.loading ? (
            <div
              className="spinner "
              style={{ marginLeft: "90px", marginTop: "15px" }}
            ></div>
          ) : (
            <button className="createbtn" onClick={formik.submitForm}>
              Create
            </button>
          )}
          {/* <button className="createbtn" onClick={formik.submitForm}>Create</button> */}
        </div>
      </div>
      <div id="overlay"></div>
    </>
  );
}

export default EditCampagin;
