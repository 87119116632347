import React from "react";
import "../StyleSheets/CreateChannelStyle.css";
import userNameImg from "../Assets/username.png";
import mailImg from "../Assets/email.png";
import lockImg from "../Assets/lock.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Spin } from "antd";

interface FormValues {
  channelName: string;
  userName: string;
  email: string;
  password: string;
  LongCode:string
}
type Props = {
  handleCrossSign: () => void;
  handleCreate: (
    name: string,
    username: string,
    email: string,
    password: string,
    LongCode:string
  ) => Promise<void>;
  errorMessage: string;
  loading: boolean;
};

function CreateChannel(props: Props) {
  const validate = Yup.object().shape({
    channelName: Yup.string().required("Please Enter Title"),
    userName: Yup.string().required("Please Enter User Name"),
    password: Yup.string().required("Please Enter Password"),
    email: Yup.string().required("Please Enter Email"),
    LongCode : Yup.string().required("Please Enter Long Code"),
  });
  const formik = useFormik({
    initialValues: {
      channelName: "",
      userName: "",
      email: "",
      password: "",
      LongCode:""
    },
    validationSchema: validate,
    onSubmit: (values: FormValues) => {
      props.handleCreate(
        values.channelName,
        values.userName,
        values.email,
        values.password,
        values.LongCode
      );
    },
  });
  return (
    <>
      <div id="m-modal-content">
        <div className="modalTop">
          <h2>Create Channel</h2>
          <span
            className="close"
            onClick={() => {
              props.handleCrossSign();
            }}
          >
            &times;
          </span>
        </div>
        <div className="forminput">
          <div className="inputGroup">
            <label htmlFor="channelname">Channel Name</label>
            <div className="innerInput">
              <input
                type="text"
                name="channelName"
                className="innerInput"
                onChange={formik.handleChange}
                value={formik.values.channelName}
                placeholder="Channel Name"
              />
              <img src={userNameImg} alt="" />
              {formik.errors.channelName ? (
                <div className="modalerrortooltip">
                  <p>{formik.errors.channelName}</p>
                </div>
              ) : (
                <></>
              )}
              {/* <input
                type="text"
                placeholder="Channel Name"
                className="pl errorMsg"
              /> */}
              {/* <div className="modalerrortooltip">
                <p>Location already exists</p>
              </div> */}
            </div>
          </div>
          <div className="inputGroup">
            <label htmlFor="channelname">Username</label>
            <div className="innerInput">
              <input
                placeholder="user name"
                type="text"
                name="userName"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.userName}
              />
              <img src={userNameImg} alt="" />
              {formik.errors.userName ? (
                <div className="modalerrortooltip">
                  <p>{formik.errors.userName}</p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="inputGroup">
            <label htmlFor="channelname">Sms Long Code</label>
            <div className="innerInput">
              <input
                placeholder="sms long code"
                type="LongCode"
                name="LongCode"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.LongCode}
              />
              <img src={userNameImg} alt="email" />
              {formik.errors.LongCode ? (
                <div className="modalerrortooltip">
                  <p>{formik.errors.LongCode}</p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="inputGroup">
            <label htmlFor="channelname">Email</label>
            <div className="innerInput">
              <input
                placeholder="Email"
                type="email"
                name="email"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              <img src={mailImg} alt="email" />
              {formik.errors.email ? (
                <div className="modalerrortooltip">
                  <p>{formik.errors.email}</p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="inputGroup">
            <label htmlFor="channelname">Password</label>
            <div className="innerInput">
              <input
                placeholder="password"
                type="password"
                name="password"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              <img src={lockImg} alt="password" />
              {formik.errors.password ? (
                <div className="modalerrortooltip">
                  <p>{formik.errors.password}</p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="modalerrorText">{props.errorMessage}</div>

        <div className="modalBtn">
          <button
            className="cancelbtn"
            onClick={() => {
              props.handleCrossSign();
            }}
          >
            Cancel
          </button>
          {props.loading ? (
           
              <div className="spinner " style={{marginLeft:'90px',marginTop:'15px'}}></div>
          ) : (
            <button className="createbtn" onClick={formik.submitForm}>
              Create
            </button>
          )}
        </div>
      </div>

      <div id="overlay"></div>
    </>
  );
}

export default CreateChannel;
