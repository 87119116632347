import * as React from "react";
import "../StyleSheets/Login.css";
import { Formik, FormikErrors, Form, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Logo from "../Assets/Ad pro logo.webp";

interface MyFormValues {
  newPassword: string;
  confirmPassword: string;
}
type Props = {
  handleValues: (password: string) => void;
  errorMsg: string;
  isLoader: boolean;
};
const SignupSchema = Yup.object().shape({
  newPassword: Yup.string().required("Password Required"),
  confirmPassword: Yup.string().required("Password Required"),
});
const ChangePassword = (props: Props) => {
  const navigate = useNavigate();
  const initialValues: MyFormValues = { newPassword: "", confirmPassword: "" };
  const onSubmit = (values: MyFormValues) => {
    if (values.newPassword === values.confirmPassword) {
      props.handleValues(values.newPassword);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Both Password does not match...!",
      });
    }
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "60px",
      }}
      spin
    />
  );
  return (
    <div className="clogin-screen">
      <div className="logo animated fadeInLeft imgDiv">
        <img src={Logo} />
      </div>

      <div className="middle-box text-center loginscreen login-area animated fadeInRight">
        <div>
          <div className="title">
            <h1>Create New Password</h1>
            <div className="sub-title">Enter your credentials to continue</div>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={SignupSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form className="formContainer">
                <label className="formLabel" htmlFor="firstName">
                  New Password
                </label>
                <Field className="formInput" id="email" name="newPassword" />
                <div className="errMsg">
                  {errors.newPassword &&
                    touched.newPassword &&
                    errors.newPassword}
                </div>

                <label className="formLabel" htmlFor="firstName">
                  Confirm Password
                </label>
                <Field
                  className="formInput"
                  id="password"
                  name="confirmPassword"
                />
                <div className="errMsg">
                  {errors.confirmPassword &&
                    touched.confirmPassword &&
                    errors.confirmPassword}
                </div>

                {props.errorMsg ? (
                  <div className="errMsg">{props.errorMsg}</div>
                ) : null}
                {props.isLoader === false ? (
                  <button
                    className="btn block full-width m-b login-btn"
                    type="submit"
                  >
                    Submit
                  </button>
                ) : (
                  <Spin indicator={antIcon} />
                )}
              </Form>
            )}
          </Formik>

          <p className="m-t cpy-rght">
            Copyright © 2023<span id="companyName"> Pro Tax Block</span> Pvt.
            Ltd. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
