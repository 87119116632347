import React from "react";
import teslaIMg from "../Assets/teslabsolute.png";
import "../StyleSheets/CampaginDashBoardStyle.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

type Props = {
  setOpenCreateChannel: React.Dispatch<React.SetStateAction<boolean>>;
  brandOptions: any;
  selectedOption: any;
  handleOptionChange: (option: any) => void;
  handleCreateCampaign: (title: string, smsText: string, date: string) => void;
  brandError: boolean;
  setBrandError: React.Dispatch<React.SetStateAction<boolean>>;
  titleError: boolean;
  setTitleError: React.Dispatch<React.SetStateAction<boolean>>;
  timeError: boolean;
  setTimeError: React.Dispatch<React.SetStateAction<boolean>>;
  descriptionError: boolean;
  setDescriptionError: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  stateBrand:any
};
interface FormValues {
  title: string;
  brand: string;
  date: any;
  smsText: string;
  
}

function CreateCampaign(props: Props) {
  const validate = Yup.object({
    title: Yup.string().required("Please Enter Title"),
    brand: Yup.string().required("Please Enter Brand"),

    smsText: Yup.string().max(200).required("Please Enter SMS Text"),
  });
  const formik = useFormik({
    initialValues: {
      title: "",
      brand: "",
      date: new Date(),
      smsText: "",
    },

    onSubmit: (values: FormValues) => {
      props.handleCreateCampaign(values.title, values.smsText, values.date);
    },
  });
  return (
    <>
      <div id="m-modal-content">
        <div className="modalTop">
          <h2>New Campaign</h2>
          <span
            className="close"
            onClick={() => {
              props.setOpenCreateChannel(false);
            }}
          >
            &times;
          </span>
        </div>
        <div className="forminput">
          <div className="inputGroup">
            <div className="selectwrapper">
              <label htmlFor="Brand">Brand</label>
              <Select
                className="istselect"
                id="Brand"
                options={props.brandOptions}
                onChange={(e) => {
                  props.handleOptionChange(e);
                  props.setBrandError(false);
                }}
                value={props.stateBrand? props.stateBrand: props.selectedOption}
              />
              {props.brandError ? (
                <p className="error">Please Select Brand</p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="inputGroup">
            <label htmlFor="channelname">Ad Title</label>
            <div className="innerInput">
              <input
                placeholder="title"
                type="title"
                name="title"
                className="form-control"
                onChange={(e) => {
                  formik.handleChange(e);
                  props.setTitleError(false);
                }}
                value={formik.values.title}
              />
              {props.titleError ? (
                <p className="error">Please Enter Title</p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="inputGroup">
            <label htmlFor="">Start Date</label>
            <div className="innerInput">
              <DatePicker
                selected={formik.values.date}
                className="form-control flatpickrDateTimeInput form-control input"
                onChange={(date: any) => formik.setFieldValue("date", date)}
                showTimeInput={false}
                dateFormat="yyyy-MMMM-d"
                minDate={new Date()}
              />
            </div>
          </div>
          <div className="inputGroup">
            <label htmlFor="">SMS Text</label>
            <div className="innerInput">
              <textarea
                name="smsText"
                className="form-control"
                onChange={(e) => {
                  formik.handleChange(e);
                  props.setDescriptionError(false);
                }}
                value={formik.values.smsText}
                style={{
                  width: "100%",
                  borderRadius: "15px",
                  outline: "none",
                }}
              />
              {props.descriptionError ? (
                <p className="error">Please Enter Sms Text</p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="modalBtn">
          <button
            className="cancelbtn"
            onClick={() => {
              props.setOpenCreateChannel(false);
            }}
          >
            Cancel
          </button>
          {props.loading ? (
            <div
              className="spinner "
              style={{ marginLeft: "90px", marginTop: "15px" }}
            ></div>
          ) : (
            <button className="createbtn" onClick={formik.submitForm}>
              Create
            </button>
          )}
        </div>
      </div>

      <div id="overlay"></div>
    </>
  );
}

export default CreateCampaign;
