import React, { useState, useEffect, useRef, useContext } from "react";
import LoginScreen from "./Screens/LoginScreen";
import ForgetPasswordScreen from "./Screens/ForgetPasswordScreen";
import "antd/dist/reset.css";
import "./StyleSheets/App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
  Outlet,
  Link,
} from "react-router-dom";
import {
  MenuOutlined,
  AppstoreFilled,
  BellFilled,
  BarChartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import CampaignViewScreen from "./Screens/CampaignViewScreen";
import { Footer } from "antd/es/layout/layout";
import DashboardScreen from "./Screens/CampaignDashboardScreen";
import ChannelAddScreen from "./Screens/ChannelAddScreen";
import ChannelEditScreen from "./Screens/ChannelEditScreen";
import ChannelCreateScreen from "./Screens/ChannelCreateScreen";
import MainDashboardScreen from "./Screens/DashboardScreen";
import ChangePasswordScreen from "./Screens/ChangePasswordScreen";
import { fetchPostToken } from "./Utils/FetchApis";
import ApiNames from "./Constants/ApiNames";
import DashboardChangePasswordScreen from "./Screens/DashboardChangePasswordScreen";
import ChannelDashboardScreen from "./Screens/ChannelDashboardScreen";
import BrandAndAddDashboardScreen from "./Screens/BrandAndAddDashboardScreen";
import AdAddScreen from "./Screens/AdAddScreen";
import DevicesDashboardScreen from "./Screens/DevicesDashboardScreen";
import CampaignDashboardScreenNew from "./Screens/CampaignDashboardScreenNew";
import Logo from "./Assets/Ad pro logo (1).webp";
import UserContext from "./Utils/User_Context";
import CampaignReportScreen from "./Screens/CampaignReportScreen";
import BrandsAndAdReportScreen from "./Screens/Brands&AdReportScreen";
import ChannelReportScreen from "./Screens/ChannelReportScreen";

const { Header, Sider } = Layout;

const App: React.FC = () => {
  const [displayValue, setDisplayValue] = useState<string>("none");
  const spanRef = useRef<any>(null);
  const location = useLocation();
  const navigation = useNavigate();
  const [token, setToken] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [adMainList, setAdMainList] = useState<any>([]);
  const [isAdmin, setISAdmin] = useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  useEffect(() => {
    authJWT();
    let role = localStorage.getItem("role");
    if (role === "CHANNEL") {
      setIsAdmin(false);
    } else {
      setIsAdmin(true);
    }

    if (window.innerWidth < 768) {
      setCollapsed(true);
    }
  }, []);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (spanRef.current && !spanRef.current.contains(event.target as Node)) {
        setDisplayValue("none");
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const authJWT = async () => {
    try {
      let response = await fetchPostToken(ApiNames.generateToken);
      if (response) {
        setToken(response);
      } else {
        console.log("Authentication failed. Please try again.");
      }
    } catch (err) {
      console.log("Authentication failed: " + err);
    }
  };
  const handleLogoutClick = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("_grecaptcha");
    localStorage.removeItem("id");
    navigation("");
  };

  const onHandleAdList = (val: any) => {
    setAdMainList(val);
  };
  const setIsAdmin = (val: boolean) => {
    setISAdmin(val);
  };

  return (
    <UserContext.Provider
      value={{
        adMainList,
        onHandleAdList,
        isAdmin,
        setIsAdmin,
      }}
    >
      <div>
        {(typeof window != "undefined" &&
          location.pathname.includes("/login")) ||
        location.pathname.includes("/forgotPassword") ||
        location.pathname.includes("/changePassword") ? (
          ""
        ) : (
          <Layout style={{ minHeight: "100vh" }}>
            <Sider
              className="siderContainer"
              trigger={null}
              collapsible
              collapsed={collapsed}
            >
              <div className="logoContainer">
                {collapsed ? (
                  <div
                    className="logo-mb"
                    style={{ color: "white", marginTop: "20px" }}
                  >
                    PTB
                  </div>
                ) : (
                  <img src={Logo} className="logo" />
                )}
              </div>
              <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[window.location.pathname]}
                onClick={({ key }) => {
                  let id = localStorage.getItem("id");
                  let name = localStorage.getItem("username");
                  let email = localStorage.getItem("email");
                  if (id && name && email) {
                    navigation(key);
                  } else {
                    navigation("/login");
                  }
                }}
                items={[
                  {
                    key: "/dashboard",
                    icon: <AppstoreFilled />,
                    label: "Dashboard",
                  },
                  {
                    key: "/campaigns",
                    icon: <BarChartOutlined />,
                    label: "Campaigns",
                    children: [
                      {
                        label: "Campaigns Management",
                        key: "/campaigns/dashboard",
                      },
                      {
                        label: "Subscriptions",
                        key: "/campaigns/subscriptions",
                      },
                      { label: "Report", key: "/campaigns/report" },
                    ],
                  },
                  {
                    key: "/channels",
                    icon: <AppstoreFilled />,
                    label: "Channels",
                    children: [
                      {
                        label: "Channels Management",
                        key: "/channels/channelmanagement",
                      },
                      { label: "Report", key: "/channels/report" },
                    ],
                  },
                  // {
                  //   key: "/ad",
                  //   icon: <AppstoreFilled />,
                  //   label: "Ads",
                  //   children: [
                  //     { label: "Create Add", key: "/ad/create" },
                  //     // { label: "Add", key: "/ad/add" },
                  //   ],
                  // },
                  {
                    key: "/brand",
                    icon: <AppstoreFilled />,
                    label: "Brand & AD",
                    children: [
                      {
                        label: "Dashboard",
                        key: "/brand/brandandadddashboard",
                      },
                      { label: "Create Ad", key: "/brand/createad" },
                      { label: "Report", key: "/brand/report" },
                    ],
                  },
                  {
                    key: "/device",
                    icon: <AppstoreFilled />,
                    label: "Device",
                    children: [{ label: "View", key: "/device/dashboard" }],
                  },
                ]}
              />
            </Sider>
            <Layout className="site-layout">
              <Header style={{ padding: 0, background: colorBgContainer }}>
                <div className="headerContainer">
                  {React.createElement(MenuOutlined, {
                    className: "trigger",
                    onClick: () => setCollapsed(!collapsed),
                  })}

                  <div className="header-icone">
                    {React.createElement(BellFilled, {
                      className: "notification",
                    })}

                    <div ref={spanRef}>
                      {React.createElement(UserOutlined, {
                        className: "notification header-user",
                        onClick: () => setDisplayValue("block"),
                      })}
                    </div>

                    <div
                      className="dropdown-content"
                      style={{ display: displayValue }}
                    >
                      <Link to="/dashboardChangepassword">Change Password</Link>
                      <Link to="/login" onClick={handleLogoutClick}>
                        Logout
                      </Link>
                    </div>
                  </div>
                </div>
              </Header>
              <div style={{ marginTop: "20px", minHeight: "81vh" }}>
                <Routes>
                  <Route
                    path="/dashboard"
                    element={<MainDashboardScreen token={token} />}
                  />
                  <Route path="/campaigns" element={<Outlet />}>
                    <Route
                      path="/campaigns/dashboard"
                      element={<CampaignDashboardScreenNew token={token} />}
                    />
                    <Route
                      path="/campaigns/subscriptions"
                      element={<CampaignViewScreen token={token} />}
                    />
                    <Route
                      path="/campaigns/report"
                      element={<CampaignReportScreen token={token} />}
                    />
                    <Route
                      path="/campaigns/dashboard"
                      element={<DashboardScreen />}
                    />
                  </Route>
                  <Route path="/channels" element={<Outlet />}>
                    <Route
                      path="/channels/channelmanagement"
                      element={<ChannelDashboardScreen token={token} />}
                    />
                    <Route
                      path="/channels/create"
                      element={<ChannelCreateScreen token={token} />}
                    />
                    <Route
                      path="/channels/add"
                      element={<ChannelAddScreen token={token} />}
                    />
                    <Route
                      path="/channels/edit"
                      element={<ChannelEditScreen />}
                    />
                    <Route
                      path="/channels/report"
                      element={<ChannelReportScreen token={token} />}
                    />
                  </Route>

                  {/* </Route> */}
                  <Route path="/brand" element={<Outlet />}>
                    <Route
                      path="/brand/brandandadddashboard"
                      element={<BrandAndAddDashboardScreen token={token} />}
                    />
                    <Route
                      path="/brand/createad"
                      element={<AdAddScreen token={token} />}
                    />
                    <Route
                      path="/brand/report"
                      element={<BrandsAndAdReportScreen token={token} />}
                    />
                  </Route>
                  <Route path="/device" element={<Outlet />}>
                    <Route
                      path="/device/dashboard"
                      element={<DevicesDashboardScreen token={token} />}
                    />
                  </Route>
                  <Route
                    path="/dashboardChangepassword"
                    element={
                      <DashboardChangePasswordScreen
                        token={token}
                        handleLogoutClick={handleLogoutClick}
                      />
                    }
                  />
                </Routes>
              </div>
              <Footer style={{ backgroundColor: "white" }}>
                Copyright © 2023 Pro Ads Pvt. Ltd. All Rights Reserved.
              </Footer>
            </Layout>
          </Layout>
        )}
        <Routes>
          <Route path="/login" element={<LoginScreen token={token} />} />
          <Route
            path="/forgotPassword"
            element={<ForgetPasswordScreen token={token} />}
          />
          <Route
            path="/changePassword"
            element={<ChangePasswordScreen token={token} />}
          />
          <Route path="/" element={<Navigate replace to={"/login"} />} />
        </Routes>
      </div>
    </UserContext.Provider>
  );
};

export default App;
