import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ForgetPassword from "../Components/ForgetPassword";
import ApiNames from "../Constants/ApiNames";
import {
  fetchGetPWKey,
  fetchPostforgetpass,
  fetchPosts,
} from "../Utils/FetchApis";
import Swal from "sweetalert2";

type Props = {
  token: any;
};

const ForgetPasswordScreen = (props: Props) => {
  const [errorMsg,setErrorMsg]=useState('')
  const [isLoader,setIsLoader]=useState(false)
  const navigation = useNavigate()
  const search = useLocation().search;
  const resetKey = new URLSearchParams(search).get("resetKey");
  useEffect(() => {
    if (resetKey) {
      if(props.token){
        handlePWResetKey();
      }
    }
  }, [resetKey,props.token]);

  const handlePWResetKey = async () => {
    try {
      let response = await fetchGetPWKey(
        ApiNames.checkPWResetKey+"?resetKey="+resetKey,
        props.token.jwttoken
      );
      if (response.status === 0) {
        navigation('/changePassword', { state: { resetKey:resetKey } })
      } else if (response.status === 1) {
        setErrorMsg(response.response)
      }
    } catch (err) {
      setErrorMsg("Somthing Went Wrong...!")
      console.log("PWResetKey failed: " + err);
    }
  };

  const handleValues = async (email: string) => {
    let data = new FormData();
    data.append("email", email);
    try {
      setIsLoader(true)
      let response = await fetchPostforgetpass(
        ApiNames.forgotPassword,
        data,
        props.token.jwttoken
      );
      if (response.status === 0) {
        setIsLoader(false)
        Swal.fire({
          position: "center",
          icon: "success",
          title: response.response,
          showConfirmButton: false,
          timer: 2200,
        });
      } else if (response.status === 1) {
        setIsLoader(false)
        setErrorMsg(response.response)
      }
    } catch (err) {
      setIsLoader(false)
      setErrorMsg("Somthing Went Wrong...!")
      console.log("forgetpassword failed: " + err);
    }
  };
  return <ForgetPassword handleValues={handleValues} errorMsg={errorMsg} isLoader={isLoader}/>;
};

export default ForgetPasswordScreen;
