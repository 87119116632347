const ApiNames = {
  generateToken: "generateToken",
  login: "login",
  forgotPassword: "forgotPassword",
  checkPWResetKey: "checkPWResetKey",
  recoverAccount: "recoverAccount",
  changePassword: "changePassword",
  channelView: "channel",
  channelcreate: "channel/create",
  channeledit: "channel/edit",
  channelareas: "channel/areas",
  channelareasadd: "channel/areas/add",
  channelAreasEdit: "channel/areas/edit",
  brandAdd: "brand/add",
  brandList: "brand",
  brandEdit: "brand/edit",
  adCategoriesAndTenures: "ad/categoriesAndTenures",
  unblockChannel: "unblockChannel",
  blockChannel: "blockChannel",
  adCreate: "ad/create",
  adList: "ad",
  deviceList: "device",
  campaignList: "campaign",
  campaignAdd: "campaign/add",
  campaignEdit: "campaign/edit",
  campaignStop: "campaign/stop",
  campaignStart: "campaign/start",
  subscriberList: "brand/subscriberList",
  adResume: "ad/resume",
  adPause: "ad/pause",
  channelRegenerateKey: "channel/regenerateKey",
  deviceBlock: "device/block",
  deviceUnblock: "device/unblock",
  statsChannel: "stats/channel",
  statsCampaign: "stats/campaign",
  statsBrandAd: "stats/brandAd",
};

export default ApiNames;
