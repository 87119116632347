import React from "react";
import channelImg from "../Assets/channel.png";
import campaignImg from "../Assets/campaign.png";
import brandImg from "../Assets/brand.png";
import facheck from "../Assets/fa-check.png";
import moreinfo from "../Assets/moreinfo.png";
import offline from "../Assets/offline.png";
import upchevron from "../Assets/up-chevron.png";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import blockImg from "../Assets/fi-rr-ban.png";
import UnblockImg from "../Assets/fi-rr-ban2.png";

import "../StyleSheets/DevicesDashboardStyle.css";

type Props = {
  deviceData: any;
  incrementPage: () => void;
  decrementPage: () => void;
  pageNumbersToShow: any[];
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  pageNumbers: number[];
  currentPage: number;
  currentData: any;
  searchQuery: string;
  handleSearchQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filteredData: any;
  handleDeviceInfo: (string: any, index: number) => string;
  refreshHandler: () => void;
  isLoader: boolean;
  moreInfo: boolean;
  setMoreInfo: React.Dispatch<React.SetStateAction<boolean>>;
  moreInfoIndex: number;
  setMoreInfoIndex: React.Dispatch<React.SetStateAction<number>>;
  HandleDeviceBlock: (id: number) => void;
  HandleDeviceUnBlock: (id: number) => void;
  role: string | null;
};
function DevicesDashboard(props: Props) {
  var deviceDataList = props.deviceData;
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      spin
    />
  );
  function convertToCSV(data: any) {
    const header = [
      "Device Name",
      "Device Id",
      "Channel",
      "Area",
      "Status",
      "Login Time",
    ];
    const rows = data.map((item: any) => [
      item.deviceInfo.split(",")[0].split(":")[1], // Extract Device Name from deviceInfo
      item.deviceId,
      item.channel.name,
      item.area.name + " " + item.area.address,
      item.aliveStatus ? "Active" : "Offline",
      item.lastLoginAt,
    ]);

    const csv = [header, ...rows].map((row) => row.join(",")).join("\n");
    return csv;
  }
  function handleDownload() {
    const csvData = convertToCSV(deviceDataList);

    // Create a Blob object containing the CSV data
    const blob = new Blob([csvData], { type: "text/csv" });

    // Create a URL for the Blob object
    const url = window.URL.createObjectURL(blob);

    // Create an invisible anchor element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "device_data.csv"; // Set the filename for the downloaded file

    // Simulate a click on the anchor element to trigger the download
    a.click();

    // Clean up by revoking the Blob URL
    window.URL.revokeObjectURL(url);
  }

  return (
    <>
      <div className="d-top">
        <div className="d-row">
          <div className="cstm-col-md-6">
            <div className="d-top-left">
              <div className="channelBox">
                <img src={channelImg} alt="" />
                <div className="innerBox">
                  <p className="text">Channel</p>
                  <span>Locations - Devices</span>
                </div>
              </div>
              <div className="channelBox">
                <img src={brandImg} alt="" />
                <div className="innerBox">
                  <p className="text">Brand & Ads</p>
                </div>
              </div>
              <div className="channelBox">
                <img src={campaignImg} alt="" />
                <div className="innerBox">
                  <p className="text">Campaign</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cstm-col-md-6">
            <div className="d-top-right"></div>
          </div>
        </div>
      </div>

      {props.isLoader === false ? (
        <>
          {props.deviceData.length !== 0 ? (
            <>
              <div className="wrapper__content">
                <div className="m-row">
                  <div className="device-cstm-col-md-8">
                    <div className="cafe_side">
                      <div className="cafe-top">
                        <div className="cafe-top-left">
                          <p>Devices</p>
                          <span>
                            {deviceDataList.length} Devices |
                            <a onClick={props.refreshHandler}> All Devices</a>
                          </span>
                        </div>

                        <div className="cafe-top-right">
                          <button
                            onClick={handleDownload}
                            style={{
                              padding: "10px",
                              marginBottom: "10px",
                              color: "#FFF",
                              borderRadius: "5px",
                              backgroundColor: "#2db27c",
                              cursor: "pointer",
                              marginRight: "10px",
                            }}
                          >
                            Download CSV
                          </button>
                          <div className="search-box">
                            <input
                              type="text"
                              className="search-input"
                              placeholder="Search..."
                              value={props.searchQuery}
                              onChange={props.handleSearchQueryChange}
                            />
                            <svg
                              className="search-icon"
                              width="23"
                              height="23"
                              viewBox="0 0 29 29"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.53979 11.8167C2.53979 14.2921 3.52313 16.6661 5.27346 18.4164C7.0238 20.1667 9.39777 21.1501 11.8731 21.1501C13.9459 21.1526 15.9593 20.4582 17.5898 19.1784L22.7115 24.3117C22.8199 24.4211 22.9489 24.5079 23.0911 24.5671C23.2333 24.6263 23.3858 24.6568 23.5398 24.6568C23.6938 24.6568 23.8463 24.6263 23.9885 24.5671C24.1306 24.5079 24.2597 24.4211 24.3681 24.3117C24.4775 24.2033 24.5643 24.0742 24.6235 23.9321C24.6827 23.7899 24.7132 23.6374 24.7132 23.4834C24.7132 23.3294 24.6827 23.1769 24.6235 23.0347C24.5643 22.8926 24.4775 22.7635 24.3681 22.6551L19.2348 17.5334C20.5146 15.9029 21.209 13.8895 21.2065 11.8167C21.2065 9.34138 20.2231 6.96741 18.4728 5.21707C16.7225 3.46673 14.3485 2.4834 11.8731 2.4834C9.39777 2.4834 7.0238 3.46673 5.27346 5.21707C3.52313 6.96741 2.53979 9.34138 2.53979 11.8167ZM11.8731 4.81673C13.2576 4.81673 14.611 5.22727 15.7621 5.99644C16.9133 6.76561 17.8105 7.85886 18.3403 9.13795C18.8701 10.417 19.0087 11.8245 18.7386 13.1824C18.4685 14.5402 17.8018 15.7875 16.8229 16.7665C15.8439 17.7454 14.5966 18.4121 13.2388 18.6822C11.8809 18.9523 10.4734 18.8137 9.19434 18.2839C7.91526 17.7541 6.82201 16.8569 6.05284 15.7057C5.28367 14.5546 4.87313 13.2012 4.87313 11.8167C4.87313 9.96022 5.61063 8.17974 6.92338 6.86698C8.23614 5.55423 10.0166 4.81673 11.8731 4.81673Z"
                                fill="#A3A3A3"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>

                      <div className="devicesTable">
                        {deviceDataList ? (
                          <>
                            <table>
                              <thead>
                                <tr>
                                  <th className="th-one"></th>
                                  <th className="th-one">Device Name</th>
                                  <th className="th-two">Device Id</th>
                                  <th className="th-three">Channel </th>
                                  <th className="th-four">Area</th>
                                  {/* <th className="th-five"></th> */}
                                  <th className="th-six">Status</th>
                                  <th className="th-five">Login Time</th>
                                </tr>
                              </thead>

                              <tbody>
                                {!props.searchQuery ? (
                                  <>
                                    {props.currentData.map(
                                      (e: any, index: any) => {
                                        return (
                                          <tr>
                                            <td>
                                              {e.isBlocked ? (
                                                <img
                                                  src={blockImg}
                                                  onClick={() =>
                                                    props.HandleDeviceUnBlock(
                                                      e.id
                                                    )
                                                  }
                                                  className="devceIconStyle"
                                                />
                                              ) : (
                                                <img
                                                  src={UnblockImg}
                                                  onClick={() =>
                                                    props.HandleDeviceBlock(
                                                      e.id
                                                    )
                                                  }
                                                  className="devceIconStyle"
                                                />
                                              )}
                                            </td>
                                            <td className="td-one">
                                              {props.handleDeviceInfo(
                                                e.deviceInfo,
                                                1
                                              )}
                                            </td>
                                            <td className="imei td-two">
                                              {e.deviceId}
                                            </td>
                                            <td className="td-three">
                                              {e.channel.name}
                                            </td>
                                            <td className="td-four">
                                              {e.area.name}
                                              <span> {e.area.address}</span>
                                            </td>

                                            {/* <td
                                              className="moreinfo td-five"
                                              id="m-info"
                                              onClick={() => {
                                                props.setMoreInfo(
                                                  !props.moreInfo
                                                );
                                                props.setMoreInfoIndex(index);
                                              }}
                                            >
                                              <img src={moreinfo} alt="" />
                                              more info
                                              {props.moreInfo &&
                                              props.moreInfoIndex === index ? (
                                                <>
                                                  <div className="moreInfoModal">
                                                    <div
                                                      className="cheImg"
                                                      onClick={() => {
                                                        props.setMoreInfo(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      <span
                                                        className="close"
                                                        style={{
                                                          fontSize: "26px",
                                                        }}
                                                      >
                                                        ×
                                                      </span>
                                                    </div>

                                                    <div className="dev-modal">
                                                      <span>Device Modal</span>
                                                      <p className="modalnumber">
                                                        {props.handleDeviceInfo(
                                                          e.deviceInfo,
                                                          2
                                                        )}
                                                      </p>
                                                    </div>

                                                    <div className="moreInfoWraper">
                                                      <div className="mod-col">
                                                        <span>IP Address </span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            3
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>
                                                          Device Brand
                                                        </span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            4
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>
                                                          Device Hardware
                                                        </span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            5
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>Device OS</span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            6
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>OS Version</span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            7
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>API Level</span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            8
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>Device Type</span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            9
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>App Version</span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            10
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>
                                                          App Version Code
                                                        </span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            11
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>
                                                          Last Update Time
                                                        </span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            12
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>Carrier</span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            13
                                                          )}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div id="overlay2"></div>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </td> */}
                                            {e.aliveStatus ? (
                                              <td className="flex td-six">
                                                <button className="paid-button">
                                                  <img src={facheck} alt="" />
                                                  Active
                                                </button>
                                              </td>
                                            ) : (
                                              <td className="flex">
                                                <button className="pending-button">
                                                  <img src={offline} alt="" />
                                                  Offline
                                                </button>
                                              </td>
                                            )}
                                            <td className="td-three">
                                              {e.lastLoginAt}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {props.filteredData.map(
                                      (e: any, index: number) => {
                                        return (
                                          <tr>
                                            <td>
                                              {e.isBlocked ? (
                                                <img
                                                  src={blockImg}
                                                  onClick={() =>
                                                    props.HandleDeviceUnBlock(
                                                      e.id
                                                    )
                                                  }
                                                  className="devceIconStyle"
                                                />
                                              ) : (
                                                <img
                                                  src={UnblockImg}
                                                  onClick={() =>
                                                    props.HandleDeviceBlock(
                                                      e.id
                                                    )
                                                  }
                                                  className="devceIconStyle"
                                                />
                                              )}
                                            </td>
                                            <td className="td-one">
                                              {props.handleDeviceInfo(
                                                e.deviceInfo,
                                                1
                                              )}
                                            </td>
                                            <td className="imei td-two">
                                              {e.deviceId}
                                            </td>
                                            <td className="td-three">
                                              {e.channel.name}
                                            </td>
                                            <td className="td-four">
                                              {e.area.name}
                                              <span> {e.area.address}</span>
                                            </td>

                                            {/* <td
                                              className="moreinfo td-five"
                                              id="m-info"
                                              onClick={() => {
                                                props.setMoreInfo(
                                                  !props.moreInfo
                                                );
                                                props.setMoreInfoIndex(index);
                                              }}
                                            >
                                              <img src={moreinfo} alt="" />
                                              more info
                                              {props.moreInfo &&
                                              props.moreInfoIndex === index ? (
                                                <>
                                                  <div className="moreInfoModal">
                                                    <div
                                                      className="cheImg"
                                                      onClick={() => {
                                                        props.setMoreInfo(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      <span
                                                        className="close"
                                                        style={{
                                                          fontSize: "26px",
                                                        }}
                                                      >
                                                        ×
                                                      </span>
                                                    </div>

                                                    <div className="dev-modal">
                                                      <span>Device Modal</span>
                                                      <p className="modalnumber">
                                                        {props.handleDeviceInfo(
                                                          e.deviceInfo,
                                                          2
                                                        )}
                                                      </p>
                                                    </div>

                                                    <div className="moreInfoWraper">
                                                      <div className="mod-col">
                                                        <span>IP Address </span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            3
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>
                                                          Device Brand
                                                        </span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            4
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>
                                                          Device Hardware
                                                        </span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            5
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>Device OS</span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            6
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>OS Version</span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            7
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>API Level</span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            8
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>Device Type</span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            9
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>App Version</span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            10
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>
                                                          App Version Code
                                                        </span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            11
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>
                                                          Last Update Time
                                                        </span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            12
                                                          )}
                                                        </p>
                                                      </div>
                                                      <div className="mod-col">
                                                        <span>Carrier</span>
                                                        <p className="modalnumber">
                                                          {props.handleDeviceInfo(
                                                            e.deviceInfo,
                                                            13
                                                          )}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div id="overlay2"></div>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </td> */}
                                            {e.aliveStatus ? (
                                              <td className="flex td-six">
                                                <button className="paid-button">
                                                  <img src={facheck} alt="" />
                                                  Active
                                                </button>
                                              </td>
                                            ) : (
                                              <td className="flex">
                                                <button className="pending-button">
                                                  <img src={offline} alt="" />
                                                  Offline
                                                </button>
                                              </td>
                                            )}
                                            <td className="td-three">
                                              {e.lastLoginAt}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </>
                        ) : (
                          <>
                            <h5 className="no-device">No Devices found</h5>
                          </>
                        )}
                      </div>
                      <div className=" pagingation">
                        <div className="pagination-buttons">
                          <button
                            onClick={props.decrementPage}
                            disabled={props.currentPage === 1}
                            style={{ cursor: "pointer" }}
                            className="pageNationBtn"
                          >
                            <LeftOutlined />
                          </button>
                          <div className="pagnum">
                            {props.pageNumbersToShow.map((number) => (
                              <div
                                key={number}
                                onClick={() => props.setCurrentPage(number)}
                                style={{
                                  background:
                                    props.currentPage === number
                                      ? "rgba(48, 78, 117, 0.15)"
                                      : "",
                                  color:
                                    props.currentPage === number
                                      ? "#304e75"
                                      : "",
                                }}
                              >
                                {number}
                              </div>
                            ))}
                          </div>
                          <button
                            style={{ cursor: "pointer" }}
                            onClick={props.incrementPage}
                            disabled={
                              props.currentPage === props.pageNumbers.length
                            }
                            className="pageNationBtn"
                          >
                            <RightOutlined />
                          </button>
                        </div>
                      </div>
                      {/* <div className="Devices__pagingation">
                    <div className="pagination-buttons">
                      <button
                        onClick={props.decrementPage}
                        disabled={props.currentPage === 1}
                        style={{ cursor: "pointer" }}
                        className="pageNationBtn"
                      >
                        <LeftOutlined />
                      </button>
                      <div className="pagnum">
                        {props.pageNumbersToShow.map((number) => (
                          <div
                            key={number}
                            onClick={() => props.setCurrentPage(number)}
                            style={{
                              background:
                                props.currentPage === number
                                  ? "rgba(48, 78, 117, 0.15)"
                                  : "",
                              color:
                                props.currentPage === number ? "#304e75" : "",
                            }}
                          >
                            {number}
                          </div>
                        ))}
                      </div>
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={props.incrementPage}
                        disabled={
                          props.currentPage === props.pageNumbers.length
                        }
                        className="pageNationBtn"
                      >
                        <RightOutlined />
                      </button>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                  fontWeight: "bold",
                }}
              >
                No Record Found
              </div>

              {props.role === "CHANNEL" ? (
                <></>
              ) : (
                <>
                  {" "}
                  <a
                    onClick={props.refreshHandler}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                      fontFamily: "10px",
                    }}
                  >
                    View All Devices
                  </a>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <Spin indicator={antIcon} />
      )}
    </>
  );
}
{
  /* <Spin indicator={antIcon} /> */
}

export default DevicesDashboard;
