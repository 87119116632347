import React, { useEffect, useState, useContext } from "react";
import CreateCampaign from "../Components/CreateCampaign";
import { useNavigate } from "react-router";
import { fetchGetPWKey, fetchPosts } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import Swal from "sweetalert2";
import * as moment from "moment";
import UserContext from "../Utils/User_Context";

type Props = {
  setOpenCreateChannel: React.Dispatch<React.SetStateAction<boolean>>;
  token: any;
  successBtnHandler: () => void;
  selectedBrandOption: any;
};
interface Option {
  label: string;
  value: string;
  children?: Option[];
}

function CreateCampaignScreem(props: Props) {
  const navigation = useNavigate();

  const [brandOptions, setBrandOptions] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [brandError, setBrandError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stateBrand, setStateBrand] = useState<any>("");
  const { onHandleAdList, adMainList, isAdmin } = useContext(UserContext);

  useEffect(() => {
    let id = localStorage.getItem("id");
    let name = localStorage.getItem("username");
    let email = localStorage.getItem("email");
    if (id === null && name === null && email === null) {
      navigation("/login");
    }
  }, []);

  useEffect(() => {
    if (props.selectedBrandOption) {
      setStateBrand(props.selectedBrandOption);
      setSelectedOption(props.selectedBrandOption);
    }
    if (props.token) {
      getBrandListHandler();
    }
  }, [props.token]);

  let id = localStorage.getItem("id");

  const getBrandListHandler = async () => {
    let array = [];
    let endPoint;
    if (!isAdmin) {
      endPoint = `${ApiNames.brandList}?channelId=${id}`;
    } else {
      endPoint = ApiNames.brandList;
    }
    try {
      const result = await fetchGetPWKey(endPoint, props.token.jwttoken);
      if (result.status === 0) {
        let data = result.response;
        for (let i = 0; i < data.length; i++) {
          array.push({ label: data[i].name, value: data[i].id });
        }
        setBrandOptions(array);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.response,
        });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleOptionChange = (option: Option | null) => {
    setStateBrand("");
    setSelectedOption(option);
  };
  const handleCreateCampaign = async (
    title: string,
    smsText: string,
    date: string
  ) => {
    let d = moment(date).format("YYYY-MM-DD hh:mm:ss");
    let data = {
      brandId: selectedOption?.value,
      title: title,
      startDatetime: d,
      smsText: smsText,
    };
    setLoading(true);
    if (data.brandId && data.smsText && data.startDatetime && data.title) {
      try {
        let response = await fetchPosts(
          ApiNames.campaignAdd,
          data,
          props.token.jwttoken
        );

        if (response.status === 0) {
          setLoading(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Campaign Created",
            showConfirmButton: false,
            timer: 1500,
          });
          props.setOpenCreateChannel(false);
          props.successBtnHandler();
        } else if (response.status === 1) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.response,
          });
        }
      } catch (err) {
        console.log(" failed: " + err);
        setLoading(false);
      }
    } else {
      setLoading(false);
      if (data.brandId) {
        setBrandError(false);
      } else {
        setBrandError(true);
      }
      if (data.title) {
        setTitleError(false);
      } else {
        setTitleError(true);
      }
      if (data.smsText) {
        setDescriptionError(false);
      } else {
        setDescriptionError(true);
      }
      if (data.startDatetime) {
        setTimeError(false);
      } else {
        setTimeError(true);
      }
    }
  };
  return (
    <>
      <CreateCampaign
        stateBrand={stateBrand}
        selectedOption={selectedOption}
        handleOptionChange={handleOptionChange}
        brandOptions={brandOptions}
        setOpenCreateChannel={props.setOpenCreateChannel}
        handleCreateCampaign={handleCreateCampaign}
        brandError={brandError}
        setBrandError={setBrandError}
        titleError={titleError}
        setTitleError={setTitleError}
        timeError={timeError}
        setTimeError={setTimeError}
        descriptionError={descriptionError}
        setDescriptionError={setDescriptionError}
        loading={loading}
      />
    </>
  );
}

export default CreateCampaignScreem;
