import React, { useState } from "react";

type Props = {
  data: any[] | undefined;
  channelData: any;
  channel: any;
};

const ReportTableView = (props: Props) => {
  const convertToCSV = () => {
    const csvRows = [];

    // Extract table headers
    const headers = Array.from(document.querySelectorAll("table th")).map(
      (header) => header.textContent
    );

    csvRows.push(headers.join(",")); // Add headers to CSV

    // Extract table data
    const tableRows = document.querySelectorAll("table tbody tr");
    tableRows.forEach((row) => {
      const rowData = Array.from(row.children).map((cell) => cell.textContent);
      csvRows.push(rowData.join(","));
    });

    return csvRows.join("\n");
  };
  const handleDownload = () => {
    const csvData = convertToCSV();
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "table_data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };
  return (
    <>
      <div
        className="Container"
        style={{ minHeight: "78vh", width: "100%", padding: "0" }}
      >
        <button
          onClick={handleDownload}
          style={{
            padding: "10px",
            marginBottom: "10px",
            color: "#FFF",
            borderRadius: "5px",
            backgroundColor: "#2db27c",
            cursor: "pointer",
          }}
        >
          Download CSV
        </button>
        <>
          <div
            className="table"
            style={
              props.data && props.data?.length > 7
                ? { height: "502px", overflow: "auto" }
                : { height: "auto" }
            }
          >
            {props.channel != undefined ? (
              <>
                <table>
                  <thead>
                    <tr>
                      <th
                        colSpan={1}
                        rowSpan={1}
                        style={{ textAlign: "center" }}
                      >
                        Total Channels
                      </th>
                      <th
                        colSpan={1}
                        rowSpan={1}
                        style={{ textAlign: "center" }}
                      >
                        Total Devices
                      </th>
                      <th
                        colSpan={1}
                        rowSpan={1}
                        style={{ textAlign: "center" }}
                      >
                        Total Ads
                      </th>
                      <th
                        colSpan={1}
                        rowSpan={1}
                        style={{ textAlign: "center" }}
                      >
                        Total Areas
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: "center" }}>
                      <td style={{ textAlign: "center" }}>
                        {props.channel.channelCount}
                      </td>

                      <td style={{ textAlign: "center" }}>
                        {props.channel.deviceCount}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {props.channel.adCount}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {props.channel.areaCount}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <>
                {props.channelData != undefined ? (
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th colSpan={1} rowSpan={1}>
                            Device Name
                          </th>
                          <th colSpan={1} rowSpan={1}>
                            IMEI
                          </th>
                          <th colSpan={1} rowSpan={1}>
                            Area
                          </th>
                          <th colSpan={1} rowSpan={1}>
                            Ad Count
                          </th>
                          <th colSpan={1} rowSpan={1}>
                            Status
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {props.channelData?.length === 0 ? (
                          <tr>
                            <td
                              colSpan={9}
                              rowSpan={9}
                              style={{
                                textAlign: "center",
                                display: "contents",
                              }}
                            >
                              <th colSpan={9} rowSpan={9}>
                                <h2>No Data Found</h2>
                              </th>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {props.channelData?.map((e: any, index: number) => (
                              <tr
                                key={index}
                                className={index % 2 === 0 ? "odd" : "even"}
                              >
                                <td
                                  style={{ textAlign: "center" }}
                                  className="tbldata"
                                >
                                  <p className="td-tittle">
                                    {e.deviceInfo}
                                    <span className="tooltiptext">
                                      {e.deviceInfo}
                                    </span>
                                  </p>
                                </td>
                                <td
                                  style={{ textAlign: "center" }}
                                  className="tbldata"
                                >
                                  <p className="td-tittle ">
                                    {e.deviceId}
                                    <span className="tooltiptext">
                                      {" "}
                                      {e.deviceId}
                                    </span>
                                  </p>
                                </td>

                                <td
                                  style={{ textAlign: "center" }}
                                  className="tbldata"
                                >
                                  <p className="td-tittle ">
                                    {e.area.address}
                                    <span className="tooltiptext">
                                      {e.area.address}
                                    </span>
                                  </p>
                                </td>

                                <td
                                  style={{ textAlign: "center" }}
                                  className="tbldata"
                                >
                                  <p className="td-tittle ">
                                    {e.adCount}
                                    <span className="tooltiptext">
                                      {e.adCount}
                                    </span>
                                  </p>
                                </td>
                                <td
                                  style={{ textAlign: "center" }}
                                  className="tbldata"
                                >
                                  {e.aliveStatus == false ? (
                                    <>Offline</>
                                  ) : (
                                    <>Online</>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    {" "}
                    <table>
                      <thead>
                        <tr>
                          <th colSpan={1} rowSpan={1}>
                            Title
                          </th>
                          <th colSpan={1} rowSpan={1} className="text-center">
                            Channel
                          </th>
                          <th colSpan={1} rowSpan={1} className="text-center">
                            Area
                          </th>
                          <th colSpan={1} rowSpan={1}>
                            Start Date
                          </th>
                          <th colSpan={1} rowSpan={1} className="text-center">
                            ImpressionCount
                          </th>
                          <th colSpan={1} rowSpan={1} className="text-center">
                            Category
                          </th>
                          <th colSpan={1} rowSpan={1}>
                            Tenure
                          </th>
                          <th colSpan={1} rowSpan={1} className="text-center">
                            Amount
                          </th>
                          <th colSpan={1} rowSpan={1} className="text-center">
                            Status
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {props.data?.length === 0 ? (
                          <tr>
                            <td
                              colSpan={9}
                              rowSpan={9}
                              style={{
                                textAlign: "center",
                                display: "contents",
                              }}
                            >
                              <th colSpan={9} rowSpan={9}>
                                <h2>No Data Found</h2>
                              </th>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {" "}
                            {props.data?.map((e: any, index) => (
                              <tr
                                key={index}
                                className={index % 2 === 0 ? "odd" : "even"}
                              >
                                <td
                                  style={{ textAlign: "center" }}
                                  className="tbldata"
                                >
                                  <p className="td-tittle">
                                    {e.title}
                                    <span className="tooltiptext">
                                      {e.title}
                                    </span>
                                  </p>
                                </td>
                                <td
                                  style={{ textAlign: "center" }}
                                  className="tbldata"
                                >
                                  <p className="td-tittle ">
                                    {e.channel.name}
                                    <span className="tooltiptext">
                                      {" "}
                                      {e.channel.name}
                                    </span>
                                  </p>
                                </td>
                                {e.areas?.map((e: any) => (
                                  <td
                                    style={{ textAlign: "center" }}
                                    className="tbldata"
                                  >
                                    <p className="td-tittle ">
                                      {e.address}
                                      <span className="tooltiptext">
                                        {e.address}
                                      </span>
                                    </p>
                                  </td>
                                ))}

                                <td
                                  style={{ textAlign: "center" }}
                                  className="tbldata"
                                >
                                  <p className="td-tittle ">
                                    {e.startDate}
                                    <span className="tooltiptext">
                                      {e.startDate}
                                    </span>
                                  </p>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {e.totalImpressionCount}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {e.category}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {e.tenure}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {e.amount}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {e.status}
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            )}
          </div>
          {/* <div
              className="d-bottom"
              style={{ position: "absolute", bottom: 90, right: 60 }}
            >
              <div className="dataTables_info"></div>
              {!props.searchQuery1 ? (
                <div className="bottom-navbar">
                  <ReactPaginate
                    pageCount={props.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={1}
                    onPageChange={props.handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              ) : (
                <div className="bottom-navbar">
                  <ReactPaginate
                    pageCount={props.pageCount2}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={1}
                    onPageChange={props.handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </div> */}
        </>
      </div>
    </>
  );
};

export default ReportTableView;
