import React from "react";

interface userContextType {
  adMainList: any;
  onHandleAdList: (val: any) => void;
  isAdmin: boolean;
  setIsAdmin: (val: boolean) => void;
}

const UserContext = React.createContext<userContextType>({
  adMainList: [],
  onHandleAdList: () => {},
  isAdmin: true,
  setIsAdmin: () => {},
});

export default UserContext;
