import React, { useEffect, useState, useContext } from "react";
import { fetchGetPWKey } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import Swal from "sweetalert2";
import BrandsAndAdReport from "../Components/Brands&AdReport";
import UserContext from "../Utils/User_Context";

type Props = {
  token: any;
};

function BrandsAndAdReportScreen(props: Props) {
  const [clickedElement, setClickedElement] = useState<number>(-1);
  const [brandData, setBrandData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [nodataFound, setNoDataFound] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [tempData, setTempData] = useState<any>([]);
  const [brandsDetail, setBrandDetail] = useState<any>([]);
  const [reverseList, setReverseList] = useState(true);
  const [brandAdStatsData, setBrandAdStatsData] = useState<any>("");
  const [fromdate, setFromdate] = useState<any>("");
  const [todate, setTodate] = useState<any>("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [showTalbleView, setShowTalbleView] = useState(false);
  const [adsDetail, setAdsDetail] = useState<any>();
  const [filterAdlist, setFilterAdlist] = useState<any[]>();

  const { isAdmin } = useContext(UserContext);

  let id = localStorage.getItem("id");

  useEffect(() => {
    if (props.token) {
      brandDateRequest();
      getAd();
    }
  }, [props.token]);

  const brandDateRequest = async () => {
    setIsLoader(true);
    let endPoint;
    if (!isAdmin) {
      endPoint = `${ApiNames.brandList}?channelId=${id}`;
    } else {
      endPoint = ApiNames.brandList;
    }
    try {
      const result = await fetchGetPWKey(endPoint, props.token.jwttoken);
      if (result.status === 0) {
        setIsLoader(false);
        let temp = result.response;
        console.log(result.response);
        setBrandData(temp.reverse());
        setSelectedBrand(temp[clickedElement]);
      } else {
        setIsLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.response,
        });
      }
    } catch (error) {
      setIsLoader(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };

  const onAllPressed = () => {
    setFilteredData([]);
    setNoDataFound(false);
  };

  const sortHandler = () => {
    if (brandData) {
      // brandData.sort(function (a: any, b: any) {
      //   if (a.name < b.name) {
      //     return -1;
      //   }
      //   if (a.name > b.name) {
      //     return 1;
      //   }
      //   return 0;
      // });
      if (reverseList === true) {
        setReverseList(false);
        let temp = [...brandData];
        temp?.sort((a: any, b: any) => a.name.localeCompare(b.name));

        onAllPressed();
        setClickedElement(-1);
        setTempData(temp);
      } else {
        let temp = [...tempData];

        temp?.reverse();
        onAllPressed();
        setClickedElement(-1);
        setTempData(temp);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No Data Found..!",
      });
    }
  };
  const recentHandler = () => {
    setTempData([]);
    if (brandData) {
      setReverseList(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No Data Found..!",
      });
    }
  };
  const handleDropDown = () => {
    if (openDropdown === true) {
      setOpenDropdown(false);
    } else {
      setOpenDropdown(true);
    }
  };

  const brandAdStats = async (ApiName: any) => {
    let endPoint;
    if (!isAdmin) {
      endPoint = `${ApiName}&channelId=${id}`;
    } else {
      endPoint = ApiName;
    }
    try {
      const result = await fetchGetPWKey(endPoint, props.token.jwttoken);
      if (result.status === 0) {
        setBrandAdStatsData(result.response);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
      console.log(error);
    }
  };

  const onDateHandler = (FDate: any, TDate: any) => {
    if (props.token) {
      if (brandAdStatsData && clickedElement !== -1) {
        const ApiName =
          ApiNames.statsBrandAd +
          "?fromDate=" +
          FDate +
          "&toDate=" +
          TDate +
          "&brandId=" +
          brandData[clickedElement]?.id;
        brandAdStats(ApiName);
      } else {
        brandAdStats(
          ApiNames.statsBrandAd +
            "?fromDate=" +
            FDate +
            "&toDate=" +
            TDate +
            "&brandId="
        );
      }
      setFromdate(FDate);
      setTodate(TDate);
    }
  };

  const onClickedElement = (index: number) => {
    setClickedElement(index);
    setSelectedBrand(brandData[index]);

    if (index === -1) {
      const ApiName =
        ApiNames.statsBrandAd +
        "?fromDate=" +
        fromdate +
        "&toDate=" +
        todate +
        "&brandId=";
      brandAdStats(ApiName);
    } else {
      const ApiName =
        ApiNames.statsBrandAd +
        "?fromDate=" +
        fromdate +
        "&toDate=" +
        todate +
        "&brandId=" +
        brandData[index].id;
      brandAdStats(ApiName);
    }
  };

  const getAd = async () => {
    let endPoint;
    if (!isAdmin) {
      endPoint = `${ApiNames.adList}?brandId=&channelId=${id}`;
    } else {
      endPoint = ApiNames.adList;
    }
    setIsLoader(true);
    try {
      let response = await fetchGetPWKey(endPoint, props.token.jwttoken);
      if (response.status === 0) {
        setIsLoader(false);
        let res = response.response;
        setFilterAdlist(res);
        setAdsDetail(res);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.response,
        });
      }
    } catch (error) {
      setIsLoader(false);
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };

  const onBrandClick = (data: any, index: number) => {
    try {
      if (index === -1) {
        setFilterAdlist(adsDetail);
      } else {
        let temp = [];
        for (let i = 0; i < adsDetail.length; i++) {
          if (adsDetail[i].brand.id === data.id) {
            temp.push(adsDetail[i]);
          }
        }
        if (temp.length === 0) {
        } else {
          // setNoDataFound(false);
        }
        console.log("data------->", temp);
        setFilterAdlist(temp);
      }

      // setFilteredData(temp);
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };
  // if (clickedElement === -1) {
  //   let temp = [];
  //   temp.push(adsDetail);
  //   setFilterAdlist(temp);
  //   // setFilteredData(temp);
  // }

  return (
    <>
      <BrandsAndAdReport
        brandData={brandData}
        onAllPressed={onAllPressed}
        clickedElement={clickedElement}
        sortHandler={sortHandler}
        recentHandler={recentHandler}
        handleDropDown={handleDropDown}
        openDropdown={openDropdown}
        loader={loader}
        reverseList={reverseList}
        tempData={tempData}
        isLoader={isLoader}
        selectedBrand={selectedBrand}
        brandAdStatsData={brandAdStatsData}
        onClickedElement={onClickedElement}
        onDateHandler={onDateHandler}
        setShowTalbleView={setShowTalbleView}
        showTalbleView={showTalbleView}
        onBrandClick={onBrandClick}
        brandsDetail={brandsDetail}
        filterAdlist={filterAdlist}
      />
    </>
  );
}

export default BrandsAndAdReportScreen;
