import format from "date-fns/format";
import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays, subDays } from "date-fns";
type Props = {
  onDateHandler: (FDate: any, TDate: any) => void
};

function DatePickerComponent(props: Props) {
  const [calender, setCalender] = useState("");
  const [open, setOpen] = useState(false);
  const refDate = useRef<HTMLInputElement>(null);
  const [range, setRange] = useState<any>([
    {
      startDate: subDays(new Date(),30),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    const dateRangeText =
    format(subDays(new Date(),30), "yyyy-MM-dd") +
    " to " +
    format(new Date(), "yyyy-MM-dd");
    props.onDateHandler(format(subDays(new Date(),30), "yyyy-MM-dd"),format(new Date(), "yyyy-MM-dd"))
    setCalender(dateRangeText);
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  const hideOnEscape = (event: any) => {
    if (event.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (event: any) => {
    // if(refDate.current && !refDate.current.contains(event.target)){
    //   setOpen(false);
    // }

    const isOutside = !event.target.closest(".calendarElement");
    if (isOutside) {
      setOpen(false);
    }
  };

  const handleSelect = (ranges: any) => {
    setRange(ranges)
    const formattedStartDate = new Date(ranges[0].startDate);
    const formattedEndDate = ranges[0].endDate;
    const dateRangeText =
      format(formattedStartDate, "yyyy-MM-dd") +
      " to " +
      format(formattedEndDate, "yyyy-MM-dd");
      props.onDateHandler(format(formattedStartDate, "yyyy-MM-dd"),format(formattedEndDate, "yyyy-MM-dd"))
    setCalender(dateRangeText);
  };
  const onhandleInput = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <input
        style={{
          background: "#fff",
          cursor: "pointer",
          padding: "5px 10px",
          border: "2px solid #ccc",
          width: "100%",
          borderRadius: "5px",
          textAlign:'center'
        }}
        value={calender}
        onClick={onhandleInput}
        readOnly
      />
      <div ref={refDate} className="datepickerComponent">
      {open ? (
        <DateRangePicker
          onChange={(item: any) => handleSelect([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          ranges={range}
          showPreview={true}
          months={2}
          direction="horizontal"
          className="calendarElement"
          preventSnapRefocus={true}
          calendarFocus="backwards"
        />
      ) : null}
      </div>
    </div>
  );
}

export default DatePickerComponent;
