import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import CampaignView from "../Components/CampaignView";
import data from "../Utils/SubscriberData.json";
import { fetchGetPWKey } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import Swal from "sweetalert2";
import UserContext from "../Utils/User_Context";

type Props = {
  token: any;
};

const CampaignViewScreen = (props: Props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery1, setSearchQuery1] = useState<string>("");
  const [searchQuery2, setSearchQuery2] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>([]);
  const [data1, setData1] = useState<any>([]);
  const [isLoader, setIsLoader] = useState(false);

  const { isAdmin } = useContext(UserContext);
  let id = localStorage.getItem("id");

  const navigation = useNavigate();
  useEffect(() => {
    setIsLoader(true);
    let id = localStorage.getItem("id");
    let name = localStorage.getItem("username");
    let email = localStorage.getItem("email");
    if (id === null && name === null && email === null) {
      setIsLoader(false);
      navigation("/login");
    }
  }, []);
  useEffect(() => {
    if (props.token) {
      SubscriberData();
    }
  }, [props.token]);

  const SubscriberData = async () => {
    let endPoint;
    if (!isAdmin) {
      endPoint = `${ApiNames.subscriberList}?channelId=${id}`;
    } else {
      endPoint = ApiNames.subscriberList;
    }
    try {
      const result = await fetchGetPWKey(endPoint, props.token.jwttoken);
      if (result.status === 0) {
        setIsLoader(false);
        let temp = result.response;
        setData1(temp);
      } else {
        setIsLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.response,
        });
      }
    } catch (error) {
      setIsLoader(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };

  const search = (query1: string) => {
    const filtered = data1.filter(
      (item: any) =>
        item.phoneNumber.toLowerCase().includes(query1.toLowerCase()) ||
        item.consentedAt.toLowerCase().includes(query1.toLowerCase()) ||
        item.brand.name.toLowerCase().includes(query1.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };
  const pageSize = 9;
  const pageCount = Math.ceil(data1.length / pageSize);
  const displayedUsers = data1.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );
  const pageCount2 = Math.ceil(filteredData.length / pageSize);
  const displayedUsers2 = filteredData.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );

  return (
    <CampaignView
      displayedUsers2={displayedUsers2}
      displayedUsers={displayedUsers}
      handlePageChange={handlePageChange}
      pageCount={pageCount}
      pageCount2={pageCount2}
      search={search}
      filteredData={filteredData}
      searchQuery1={searchQuery1}
      setSearchQuery1={setSearchQuery1}
      searchQuery2={searchQuery2}
      setSearchQuery2={setSearchQuery2}
      isLoader={isLoader}
    />
  );
};

export default CampaignViewScreen;
