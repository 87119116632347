import React from "react";
import * as Yup from "yup";
import { Formik, FormikErrors, Form, Field } from "formik";
import { Spin } from "antd";
import Logo from "../Assets/Ad pro logo.webp";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
type Props = {
  handleValues: (email: string) => void;
  errorMsg: string;
  isLoader: boolean;
};
interface MyFormValues {
  email: string;
}
const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid email")
    .required("Email Required"),
});

const ForgetPassword = (props: Props) => {
  const navigation = useNavigate();
  const onSubmit = (values: MyFormValues) => {
    props.handleValues(values.email);
  };
  const initialValues: MyFormValues = { email: "" };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "25px",
        marginTop: "20px",
      }}
      spin
    />
  );
  return (
    <div className="clogin-screen">
      <div className="logo animated fadeInLeft imgDiv">
        <img src={Logo} />
      </div>

      <div className="middle-box text-center loginscreen login-area animated fadeInRight forgetHeight">
        <div>
          <div className="title">
            <h1>Forgot Password</h1>
            <div className="sub-title">
              Enter your email address and recovery link will be emailed to you.
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={SignupSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form className="formContainer">
                <label className="formLabel" htmlFor="firstName">
                  Email Address
                </label>
                <Field className="formInput" id="email" name="email" />
                <div
                  style={{ color: "red", textAlign: "left", marginTop: "5px" }}
                >
                  {errors.email && touched.email && errors.email}
                </div>
                {props.errorMsg ? (
                  <div style={{ color: "red", marginTop: "10px" }}>
                    {props.errorMsg}
                  </div>
                ) : null}
                {props.isLoader === false ? (
                  <>
                    <button
                      className="btn block full-width m-b login-btn"
                      type="submit"
                    >
                      Submit
                    </button>
                    <button
                      className="btn block full-width m-b login-btn"
                      onClick={() => navigation("/login")}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <Spin indicator={antIcon} />
                )}
              </Form>
            )}
          </Formik>

          <p className="m-t cpy-rght">
            Copyright © 2023<span id="companyName"> AD Pro Block</span> Pvt.
            Ltd. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
