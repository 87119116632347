import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from "../Components/Dashboard";
import data from "../Utils/data.json";
import { fetchGetPWKey } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import Swal from "sweetalert2";

type Props = {
  token: any;
};

const MainDashboardScreen = (props: Props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState<boolean>(false);
  const [range, setRange] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [searchQuery1, setSearchQuery1] = useState<string>("");
  const [searchQuery2, setSearchQuery2] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>([]);
  const [isloader, setIsLoader] = useState(false);
  const [adData, setAdData] = useState<any>([]);
  const [brandData, setBrandData] = useState<any>([]);
  const [deviceData, setDeviceData] = useState<any>([]);
  const [campaignData, setCampaignData] = useState<any>([]);
  const [channelData, setChannelData] = useState<any>([]);

  const [Data1, setData1] = useState<any>([
    { name: "Platinum", Value: 0, fill: "#f8bf34" },
    { name: "Gold", Value: 0, fill: "#e5e4e2" },
    { name: "Silver", Value: 0, fill: "#808080" },
  ]);

  const refOne = useRef<HTMLDivElement>(null);

  const navigation = useNavigate();

  useEffect(() => {
    let id = localStorage.getItem("id");
    let name = localStorage.getItem("username");
    let email = localStorage.getItem("email");
    if (id === null && name === null && email === null) {
      navigation("/login");
    }
  }, []);

  const search = (query1: string, query2: string) => {
    const filtered = data.filter(
      (item) =>
        item.title.toLowerCase().includes(query1.toLowerCase()) &&
        item.status.toLowerCase().includes(query2.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };
  const pageSize = 7;
  const pageCount = Math.ceil(adData?.length / pageSize);
  const displayedUsers = adData?.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
    return () => {
      document.removeEventListener("keydown", hideOnEscape, true);
      document.removeEventListener("click", hideOnClickOutside, true);
    };
  }, []);
  useEffect(() => {
    if (props.token) {
      getBrandData();
      getAd();
      getDeviceData();
      getCampaignData();
      getChannelData();
    }
  }, [props.token]);

  const handleDayClick = () => {
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
      },
    ]);
  };
  const handleMonthClick = () => {
    setRange([
      {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          new Date().getDate()
        ),
        endDate: new Date(),
      },
    ]);
  };
  const handleWeekClick = () => {
    setRange([
      {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        endDate: new Date(),
      },
    ]);
  };
  const hideOnEscape = (e: KeyboardEvent): void => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e: MouseEvent): void => {
    if (refOne.current && !refOne.current.contains(e.target as Node)) {
      setOpen(false);
    }
  };

  // All List Data

  const getAd = async () => {
    setIsLoader(true);
    try {
      let response = await fetchGetPWKey(ApiNames.adList, props.token.jwttoken);
      if (response.status === 0) {
        setIsLoader(false);
        let res = response.response;
        setAdData(res.reverse());
        if (res.length > 0) {
          let Platinum = res.filter((item: any) =>
            item.category.toLowerCase().includes("Platinum".toLowerCase())
          );
          let Gold = res.filter((item: any) =>
            item.category.toLowerCase().includes("Gold".toLowerCase())
          );
          let Silver = res.filter((item: any) =>
            item.category.toLowerCase().includes("Silver".toLowerCase())
          );
          setData1([
            { name: "Platinum", value: Platinum.length, fill: "#f8bf34" },
            { name: "Gold", value: Gold.length, fill: "#e5e4e2" },
            { name: "Silver", value: Silver.length, fill: "#808080" },
          ]);
        }
      } else {
        setIsLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.response,
        });
      }
    } catch (error) {
      setIsLoader(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };

  const getBrandData = async () => {
    setIsLoader(true);
    try {
      const result = await fetchGetPWKey(
        ApiNames.brandList,
        props.token.jwttoken
      );
      if (result.status === 0) {
        setIsLoader(false);
        let temp = result.response;
        setBrandData(temp.reverse());
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.response,
        });
      }
    } catch (error) {
      setIsLoader(false);
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };

  const getDeviceData = async () => {
    setIsLoader(true);
    try {
      const result = await fetchGetPWKey(
        ApiNames.deviceList,
        props.token.jwttoken
      );
      if (result.status === 0) {
        setIsLoader(false);
        setDeviceData(result.response);
      } else {
        setIsLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.response,
        });
      }
    } catch (error) {
      setIsLoader(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };

  const getCampaignData = async () => {
    setIsLoader(true);
    try {
      let response = await fetchGetPWKey(
        ApiNames.campaignList,
        props.token.jwttoken
      );
      if (response.status === 0) {
        setIsLoader(false);
        let res = response.response;
        setCampaignData(res.reverse());
      } else {
        setIsLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.response,
        });
      }
    } catch (error) {
      setIsLoader(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };

  const getChannelData = async () => {
    try {
      const result = await fetchGetPWKey(
        ApiNames.channelView,
        props.token.jwttoken
      );
      if (result.status === 0) {
        let temp = result.response;
        setChannelData(temp);
      } else {
        setIsLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.response,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };

  return (
    <>
      <Dashboard
        adData={adData}
        brandData={brandData}
        deviceData={deviceData}
        campaignData={campaignData}
        channelData={channelData}
        isloader={isloader}
        data1={Data1}
        open={open}
        setOpen={setOpen}
        range={range}
        setRange={setRange}
        handleDayClick={handleDayClick}
        handleMonthClick={handleMonthClick}
        handleWeekClick={handleWeekClick}
        hideOnEscape={hideOnEscape}
        hideOnClickOutside={hideOnClickOutside}
        refOne={refOne}
        navigation={navigation}
        displayedUsers={displayedUsers}
        handlePageChange={handlePageChange}
        pageCount={pageCount}
        pageSize={pageCount}
        search={search}
        filteredData={filteredData}
        searchQuery1={searchQuery1}
        setSearchQuery1={setSearchQuery1}
        searchQuery2={searchQuery2}
        setSearchQuery2={setSearchQuery2}
      />
    </>
  );
};

export default MainDashboardScreen;
