import React, { PureComponent, useEffect } from "react";
import channelImg from "../Assets/channel.png";
import campaignImg from "../Assets/campaign.png";
import brandImg from "../Assets/brand.png";
import atozImg from "../Assets/atoz.png";
import menuImg from "../Assets/menu-bar.png";
import recentImg from "../Assets/recent.png";
import "../StyleSheets/ChannelDashboardStyle.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row, Spin } from "antd";
import analyticsGreen from "../Assets/analytics-green.png";
import analyticsDefault from "../Assets/analytics-default.png";
import analyticsOrange from "../Assets/analytics-orange.png";
import analyticsRed from "../Assets/analytics-red.png";
import facheck from "../Assets/fa-check.png";
import offline from "../Assets/offline.png";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import ReportTableView from "./ReportTableView";

import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePickerComponent from "./DatePickerComponent";

type Props = {
  selectedChannel: any;
  channelData: any;
  addDevicesEditData: any;
  clickedElement: number;
  mainPageLoading: boolean;
  handleDropDown: () => void;
  sortHandler: () => void;
  recentHandler: () => void;
  openDropdown: boolean;
  reverseList: boolean;
  tempData: any;
  onDateHandler: (FDate: any, TDate: any) => void;
  onClickedElement: (index: number) => void;
  channelStatsData: any;
  handleDeviceInfo: (string: any, index: number) => string;
  incrementPage: () => void;
  decrementPage: () => void;
  pageNumbersToShow: any[];
  pageNumbers: number[];
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentData: any;
  setShowTalbleView: React.Dispatch<React.SetStateAction<boolean>>;
  showTalbleView: boolean;
  role: string | null;
};

function ChannelReport(props: Props) {
  if (props.reverseList) {
    var channelListData = props.channelData;
  } else {
    var channelListData = props.tempData;
  }

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      spin
    />
  );

  return (
    <>
      <div className="d-top">
        <div className="d-row">
          <div className="cstm-col-md-6">
            <div className="d-top-left">
              <div className="channelBox">
                <img src={channelImg} alt="" />
                <div className="innerBox">
                  <p className="text">Channel</p>
                  <span>Locations - Devices</span>
                </div>
              </div>
              <div className="channelBox">
                <img src={brandImg} alt="" />
                <div className="innerBox">
                  <p className="text">Brand & Ads</p>
                </div>
              </div>
              <div className="channelBox">
                <img src={campaignImg} alt="" />
                <div className="innerBox">
                  <p className="text">Campaign</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper__content">
        {!props.mainPageLoading ? (
          <Row style={{ margin: "20px" }}>
            <Col span={6}>
              <div className="channel__left" style={{ paddingBottom: "30px " }}>
                <div className="channel__top">
                  <div className="ch-head">
                    <p>Channels</p>
                    {props.role === "CHANNEL" ? (
                      <></>
                    ) : (
                      <>
                        <span>{channelListData.length} Channels</span>
                      </>
                    )}
                  </div>

                  {props.role === "CHANNEL" ? (
                    <></>
                  ) : (
                    <>
                      {" "}
                      <div className="recent-right">
                        <div
                          className="recent__Activity"
                          onClick={() => {
                            props.handleDropDown();
                          }}
                        >
                          <img src={menuImg} alt="" />
                          {props.openDropdown ? (
                            <>
                              <div className="recentDropDown">
                                <p className="atoz" onClick={props.sortHandler}>
                                  <img src={atozImg} alt="" />
                                  A-Z
                                </p>
                                <p
                                  className="recent"
                                  onClick={props.recentHandler}
                                >
                                  <img src={recentImg} alt="" />
                                  Recent
                                </p>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <ul className="channer_ul side_bar" style={{ height: "490px" }}>
                  {channelListData.length > 0 ? (
                    <>
                      {props.role === "CHANNEL" ? (
                        <></>
                      ) : (
                        <>
                          <li
                            onClick={(e) => {
                              props.onClickedElement(-1);
                            }}
                            style={{
                              backgroundColor:
                                props.clickedElement === -1 ? "#f9f9f9" : "",
                            }}
                          >
                            <div className="li_left">
                              <div className="letter">
                                <p>O</p>
                              </div>
                              <div className="m-loc">
                                <p>OverView</p>
                              </div>
                            </div>
                          </li>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {channelListData.map((e: any, index: any) => {
                    return (
                      <li
                        onClick={() => {
                          props.onClickedElement(index);
                        }}
                        style={{
                          backgroundColor:
                            props.clickedElement === index ? "#f9f9f9" : "",
                        }}
                      >
                        {props.role === "CHANNEL" ? (
                          <>
                            {" "}
                            <div className="li_left">
                              <div className="letter">
                                <p>{e.fullName?.charAt(0)?.toUpperCase()}</p>
                              </div>
                              <div className="m-loc">
                                <p>{e.fullName}</p>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {" "}
                            <div className="li_left">
                              <div className="letter">
                                <p>{e.name?.charAt(0)?.toUpperCase()}</p>
                              </div>
                              <div className="m-loc">
                                <p>{e.name}</p>
                                {e.status ? (
                                  <span style={{ color: "#2DB27C" }}>
                                    Active
                                  </span>
                                ) : (
                                  <span style={{ color: "#FD3C4F" }}>
                                    InActive
                                  </span>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
            <Col span={18}>
              {props.clickedElement === -1 ? (
                <div
                  className="cafe_side "
                  style={{ marginLeft: "15px", height: "630px" }}
                >
                  <div className="cafe-top">
                    <div className="cafe-top-left">
                      <p>Channels Report</p>
                    </div>
                    <div
                      style={{
                        width: "30%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={() => {
                            if (props.showTalbleView === false) {
                              props.setShowTalbleView(true);
                            } else {
                              props.setShowTalbleView(false);
                            }
                          }}
                        />
                        <span className="slider round"></span>
                      </label>
                      <DatePickerComponent
                        onDateHandler={props.onDateHandler}
                      />
                    </div>
                  </div>
                  {props.showTalbleView ? (
                    <>
                      <ReportTableView
                        data={undefined}
                        channelData={undefined}
                        channel={props.channelStatsData}
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      <Row style={{ justifyContent: "space-between" }}>
                        {props.role === "CHANNEL" ? (
                          <></>
                        ) : (
                          <>
                            {" "}
                            <Col span={6} style={{ maxWidth: "24%" }}>
                              {" "}
                              <div className="digitalBox ">
                                <div className="app-count">
                                  <p className="noa">
                                    {props.channelStatsData.channelCount}
                                  </p>
                                  <img
                                    src={analyticsGreen}
                                    className="waveImg"
                                    alt=""
                                  />
                                  <p className="application">Total Channels</p>
                                </div>
                              </div>
                            </Col>
                          </>
                        )}

                        <Col span={6} style={{ maxWidth: "24%" }}>
                          {" "}
                          <div className="digitalBox ">
                            <div className="app-count">
                              <p className="noa">
                                {props.channelStatsData.deviceCount}
                              </p>
                              <img
                                src={analyticsDefault}
                                className="waveImg"
                                alt=""
                              />
                              <p className="application">Total Devices</p>
                            </div>
                          </div>
                        </Col>
                        <Col span={6} style={{ maxWidth: "24%" }}>
                          {" "}
                          <div className="digitalBox ">
                            <div className="app-count">
                              <p className="noa">
                                {props.channelStatsData.adCount}
                              </p>
                              <img
                                src={analyticsOrange}
                                className="waveImg"
                                alt=""
                              />
                              <p className="application">Total Ads</p>
                            </div>
                          </div>
                        </Col>
                        <Col span={6} style={{ maxWidth: "24%" }}>
                          {" "}
                          <div className="digitalBox ">
                            <div className="app-count">
                              <p className="noa">
                                {props.channelStatsData.areaCount}
                              </p>
                              <img
                                src={analyticsRed}
                                className="waveImg"
                                alt=""
                              />
                              <p className="application">Total Areas</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {props.channelStatsData?.areaDevicesGraphData?.length >
                      0 ? (
                        <ResponsiveContainer
                          className="barChartContainer"
                          width="100%"
                          height="65%"
                        >
                          <BarChart
                            width={500}
                            height={300}
                            data={props.channelStatsData.areaDevicesGraphData}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 0,
                              bottom: 20,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="areaName" />
                            <YAxis />
                            <Tooltip />
                            <Legend
                              verticalAlign="top"
                              wrapperStyle={{ lineHeight: "40px" }}
                            />
                            <ReferenceLine y={0} stroke="#000" />
                            <Brush
                              dataKey="areaName"
                              height={30}
                              stroke="#8884d8"
                            />
                            <Bar dataKey="deviceCount" fill="#417EC0" />
                          </BarChart>
                        </ResponsiveContainer>
                      ) : null}
                    </>
                  )}
                </div>
              ) : (
                <div
                  className="cafe_side "
                  style={{ marginLeft: "15px", height: "620px" }}
                >
                  <div className="cafe-top">
                    <div className="cafe-top-left" style={{ display: "flex" }}>
                      <p>Channels Report</p>
                      <div className="cafeTopPara">|</div>
                      <div className="cafeTopPara">
                        {props.selectedChannel?.name}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "30%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={() => {
                            if (props.showTalbleView === false) {
                              props.setShowTalbleView(true);
                            } else {
                              props.setShowTalbleView(false);
                            }
                          }}
                        />
                        <span className="slider round"></span>
                      </label>
                      <DatePickerComponent
                        onDateHandler={props.onDateHandler}
                      />
                    </div>
                  </div>

                  {props.showTalbleView ? (
                    <>
                      {" "}
                      <ReportTableView
                        data={undefined}
                        channelData={props.currentData}
                        channel={undefined}
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      <Row style={{ justifyContent: "space-between" }}>
                        <Col span={6} style={{ maxWidth: "24%" }}>
                          {" "}
                          <div className="digitalBox ">
                            <div className="app-count">
                              <p className="noa">
                                {props.channelStatsData.deviceCount}
                              </p>
                              <img
                                src={analyticsDefault}
                                className="waveImg"
                                alt=""
                              />
                              <p className="application">Total Devices</p>
                            </div>
                          </div>
                        </Col>
                        <Col span={6} style={{ maxWidth: "24%" }}>
                          {" "}
                          <div className="digitalBox ">
                            <div className="app-count">
                              <p className="noa">
                                {props.channelStatsData.adCount}
                              </p>
                              <img
                                src={analyticsOrange}
                                className="waveImg"
                                alt=""
                              />
                              <p className="application">Total Ads</p>
                            </div>
                          </div>
                        </Col>
                        <Col span={6} style={{ maxWidth: "24%" }}>
                          {" "}
                          <div className="digitalBox ">
                            <div className="app-count">
                              <p className="noa">
                                {props.channelStatsData.areaCount}
                              </p>
                              <img
                                src={analyticsRed}
                                className="waveImg"
                                alt=""
                              />
                              <p className="application">Total Areas</p>
                            </div>
                          </div>
                        </Col>
                        <Col span={6} style={{ maxWidth: "24%" }}></Col>
                      </Row>
                      {props.currentData?.length > 0 ? (
                        <div className="devicesTable">
                          <table>
                            <thead>
                              <tr>
                                <th className="th-three">Device Name</th>
                                <th className="th-two">IMEI</th>
                                <th className="th-four">Area</th>
                                <th style={{ width: "12%" }}>Ad Count</th>

                                <th className="th-five">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.currentData.map((e: any, index: any) => {
                                return (
                                  <tr>
                                    <td>
                                      {props.handleDeviceInfo(e.deviceInfo, 1)}
                                    </td>
                                    <td>{e.deviceId}</td>
                                    <td>
                                      {e.area.name}
                                      <span>{e.area.address}</span>
                                    </td>
                                    <td>{e.adCount}</td>
                                    <td>
                                      {e.aliveStatus ? (
                                        <td className="flex td-six">
                                          <button className="paid-button">
                                            <img src={facheck} alt="" />
                                            Active
                                          </button>
                                        </td>
                                      ) : (
                                        <td style={{ padding: 0 }}>
                                          <button className="pending-button">
                                            <img src={offline} alt="" />
                                            Offline
                                          </button>
                                        </td>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : null}
                      {props.currentData?.length > 0 ? (
                        <div className=" pagingation">
                          <div className="pagination-buttons">
                            <button
                              onClick={props.decrementPage}
                              disabled={props.currentPage === 1}
                              style={{ cursor: "pointer" }}
                              className="pageNationBtn"
                            >
                              <LeftOutlined />
                            </button>
                            <div className="pagnum">
                              {props.pageNumbersToShow.map((number: any) => (
                                <div
                                  key={number}
                                  onClick={() => props.setCurrentPage(number)}
                                  style={{
                                    background:
                                      props.currentPage === number
                                        ? "rgba(48, 78, 117, 0.15)"
                                        : "",
                                    color:
                                      props.currentPage === number
                                        ? "#304e75"
                                        : "",
                                  }}
                                >
                                  {number}
                                </div>
                              ))}
                            </div>
                            <button
                              style={{ cursor: "pointer" }}
                              onClick={props.incrementPage}
                              disabled={
                                props.currentPage === props.pageNumbers.length
                              }
                              className="pageNationBtn"
                            >
                              <RightOutlined />
                            </button>
                          </div>
                        </div>
                      ) : null}{" "}
                    </>
                  )}
                </div>
              )}
            </Col>
          </Row>
        ) : (
          <Spin indicator={antIcon} />
        )}
      </div>
    </>
  );
}

export default ChannelReport;
