import React, { useEffect, useRef, useState } from "react";
import channelImg from "../Assets/channel.png";
import campaignImg from "../Assets/campaign.png";
import brandImg from "../Assets/brand.png";
import atozImg from "../Assets/atoz.png";
import menuImg from "../Assets/menu-bar.png";
import recentImg from "../Assets/recent.png";
import CreateChannelScreen from "../Screens/CreateChannelScreen";
import EditChannelScreen from "../Screens/EditChannelScreen";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  LeftOutlined,
  RightOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import "../StyleSheets/ChannelDashboardStyle.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import AuthenticationModal from "./AuthenticationModal";

interface FormValues {
  title: string;
  address: string;
}
interface FormValue {
  title2: string;
  address2: string;
}
type Props = {
  token: any;
  channelDateRequest: () => Promise<void>;
  onAuthKeyPress: (item: any) => void;
  selectedChannel: any;
  addDevices: boolean;
  setAuthKey: React.Dispatch<React.SetStateAction<string>>;
  authKey: string;
  handleAddDevices: (
    name: string,
    address: string,
    id: number,
    index: any
  ) => void;
  error: boolean;
  openDevicesList: boolean;
  HandleOpneDevices: () => void;
  channelData: any;
  areaData: any;
  areaList: (item: any) => void;
  openCreateChannel: boolean;
  HandleOpenCreateChannel: () => void;
  HandleCrossSign: () => void;
  HandleSuccessSign: () => void;
  openEditChannel: boolean;
  HandlesideMenu: (index: any) => void;
  HandleEditChannel: () => void;
  HandleChannelBlock: (id: number) => void;
  HandleChannelUnBlock: (id: number) => void;
  sideMenuIndex: number;
  openSideMenu: boolean;
  setAdddevice: React.Dispatch<React.SetStateAction<boolean>>;
  addDevicesEditData: any;
  currentData: any;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  pageNumbers: number[];
  currentPage: number;
  ChannelAreaAddHandler: (name: string, address: string, id: number) => void;
  searchQuery: string;
  handleSearchQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filteredData: any;
  ChannelAreaEditHandler: (
    name: string,
    address: string,
    id: number,
    channelId: number
  ) => void;
  deviceData: any;
  incrementPage: () => void;
  decrementPage: () => void;
  pageNumbersToShow: any[];
  clickedElement: number;
  setClickedElement: React.Dispatch<React.SetStateAction<number>>;
  handleDelete: () => void;
  apiError: string;
  mainPageLoading: boolean;
  setInitialColor: React.Dispatch<React.SetStateAction<boolean>>;
  initialColor: boolean;
  onloader: boolean;
  editloader: boolean;
  handleDropDown: () => void;
  sortHandler: () => void;
  recentHandler: () => void;
  openDropdown: boolean;
  reverseList: boolean;
  tempData: any;
  authModal: boolean;
  setAuthModal: React.Dispatch<React.SetStateAction<boolean>>;

  isAdmin: boolean;
};
let value = 0;

function ChannelDashboard(props: Props) {
  const [demo, setDemo] = useState(false);

  const myElementRef: any = useRef();
  if (!props.isAdmin) {
    if (!demo) {
      if (myElementRef.current) {
        myElementRef.current.click();
        setDemo(true);
      }
    }
  }

  var titleVal = props.addDevicesEditData;

  const validate = Yup.object().shape({
    title: Yup.string().required("Please Enter Area/Title"),
    address: Yup.string().required("Please Enter Address"),
  });
  const validate2 = Yup.object().shape({
    title2: Yup.string().required("Please Enter Area/Title"),
    address2: Yup.string().required("Please Enter Address"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      address: "",
    },
    validationSchema: validate,
    onSubmit: (values: FormValues) => {
      props.ChannelAreaAddHandler(
        values.title,
        values.address,
        props.selectedChannel.id
      );
      formik.values.title = "";
      formik.values.address = "";
    },
  });

  var formik2 = useFormik({
    initialValues: {
      title2: titleVal.name,
      address2: titleVal.address,
    },
    validationSchema: validate2,
    onSubmit: (values: FormValue) => {
      props.ChannelAreaEditHandler(
        values.title2,
        values.address2,
        props.addDevicesEditData.id,
        props.selectedChannel.id
      );
    },
  });

  if (props.reverseList) {
    var channelListData = props.channelData;
  } else {
    var channelListData = props.tempData;
  }

  useEffect(() => {
    formik2.setFieldValue("title2", props.addDevicesEditData.name);
    formik2.setFieldValue("address2", props.addDevicesEditData.address);
  }, [props.addDevicesEditData]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      spin
    />
  );
  const antIcon2 = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "0px",
        width: "30px",
        marginLeft: "-40px",
      }}
      spin
    />
  );
  const navigate = useNavigate();
  return (
    <>
      <div className="d-top">
        <div className="d-row">
          <div className="cstm-col-md-6">
            <div className="d-top-left">
              <div className="channelBox">
                <img src={channelImg} alt="" />
                <div className="innerBox">
                  <p className="text">Channel</p>
                  <span>Locations - Devices</span>
                </div>
              </div>
              <div className="channelBox">
                <img src={brandImg} alt="" />
                <div className="innerBox">
                  <p className="text">Brand & Ads</p>
                </div>
              </div>
              <div className="channelBox">
                <img src={campaignImg} alt="" />
                <div className="innerBox">
                  <p className="text">Campaign</p>
                </div>
              </div>
            </div>
          </div>
          {props.isAdmin === false ? (
            <></>
          ) : (
            <>
              <div className="cstm-col-md-6">
                <div className="d-top-right">
                  <div
                    className="channelBtn cursor-pointer"
                    onClick={() => {
                      props.HandleOpenCreateChannel();
                    }}
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.7797 16.1374H12.9464V24.304C12.9464 24.6135 13.0693 24.9102 13.2881 25.129C13.5069 25.3478 13.8036 25.4707 14.113 25.4707C14.4225 25.4707 14.7192 25.3478 14.938 25.129C15.1568 24.9102 15.2797 24.6135 15.2797 24.304V16.1374H23.4464C23.7558 16.1374 24.0525 16.0145 24.2713 15.7957C24.4901 15.5769 24.613 15.2801 24.613 14.9707C24.613 14.6613 24.4901 14.3645 24.2713 14.1457C24.0525 13.927 23.7558 13.804 23.4464 13.804H15.2797V5.63737C15.2797 5.32795 15.1568 5.0312 14.938 4.81241C14.7192 4.59362 14.4225 4.4707 14.113 4.4707C13.8036 4.4707 13.5069 4.59362 13.2881 4.81241C13.0693 5.0312 12.9464 5.32795 12.9464 5.63737V13.804H4.7797C4.47028 13.804 4.17354 13.927 3.95475 14.1457C3.73595 14.3645 3.61304 14.6613 3.61304 14.9707C3.61304 15.2801 3.73595 15.5769 3.95475 15.7957C4.17354 16.0145 4.47028 16.1374 4.7797 16.1374Z"
                        fill="white"
                      />
                    </svg>
                    New Channel
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="wrapper__content">
        {!props.mainPageLoading ? (
          <div className="m-row">
            <div className="cstm-col-md-4">
              <div className="channel__left">
                <div className="channel__top">
                  <div className="ch-head">
                    <p>Channels</p>
                    {props.isAdmin === false ? (
                      <></>
                    ) : (
                      <>
                        <span>{channelListData.length} Channels</span>
                      </>
                    )}
                  </div>

                  {props.isAdmin === false ? (
                    <></>
                  ) : (
                    <>
                      {" "}
                      <div className="recent-right">
                        <div
                          className="new-channel cursor-pointer"
                          onClick={() => {
                            props.HandleOpenCreateChannel();
                          }}
                        >
                          <svg
                            width="29"
                            height="29"
                            viewBox="0 0 29 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.7411 1.89258C12.2029 1.89258 9.72167 2.64524 7.61124 4.05538C5.50081 5.46553 3.85592 7.46982 2.8846 9.81481C1.91328 12.1598 1.65913 14.7401 2.15431 17.2296C2.64949 19.719 3.87174 22.0057 5.66652 23.8004C7.46129 25.5952 9.74797 26.8175 12.2374 27.3127C14.7268 27.8078 17.3072 27.5537 19.6522 26.5824C21.9971 25.611 24.0014 23.9662 25.4116 21.8557C26.8217 19.7453 27.5744 17.2641 27.5744 14.7259C27.5704 11.3235 26.217 8.06166 23.8112 5.65581C21.4053 3.24996 18.1434 1.89659 14.7411 1.89258ZM14.7411 25.2259C12.6644 25.2259 10.6343 24.6101 8.90757 23.4563C7.18085 22.3026 5.83504 20.6627 5.04032 18.7441C4.2456 16.8255 4.03766 14.7143 4.44281 12.6775C4.84795 10.6407 5.84798 8.76974 7.31643 7.30129C8.78488 5.83284 10.6558 4.83281 12.6926 4.42767C14.7294 4.02252 16.8406 4.23046 18.7592 5.02518C20.6779 5.8199 22.3177 7.16571 23.4715 8.89242C24.6252 10.6191 25.2411 12.6492 25.2411 14.7259C25.2377 17.5096 24.1303 20.1784 22.1619 22.1468C20.1935 24.1152 17.5248 25.2225 14.7411 25.2259Z"
                              fill="#A3A3A3"
                            />
                            <path
                              d="M19.4078 13.5592H15.9078V10.0592C15.9078 9.74983 15.7849 9.45308 15.5661 9.23429C15.3473 9.01549 15.0506 8.89258 14.7412 8.89258C14.4318 8.89258 14.135 9.01549 13.9162 9.23429C13.6974 9.45308 13.5745 9.74983 13.5745 10.0592V13.5592H10.0745C9.76508 13.5592 9.46834 13.6822 9.24955 13.901C9.03075 14.1197 8.90784 14.4165 8.90784 14.7259C8.90784 15.0353 9.03075 15.3321 9.24955 15.5509C9.46834 15.7697 9.76508 15.8926 10.0745 15.8926H13.5745V19.3926C13.5745 19.702 13.6974 19.9987 13.9162 20.2175C14.135 20.4363 14.4318 20.5592 14.7412 20.5592C15.0506 20.5592 15.3473 20.4363 15.5661 20.2175C15.7849 19.9987 15.9078 19.702 15.9078 19.3926V15.8926H19.4078C19.7173 15.8926 20.014 15.7697 20.2328 15.5509C20.4516 15.3321 20.5745 15.0353 20.5745 14.7259C20.5745 14.4165 20.4516 14.1197 20.2328 13.901C20.014 13.6822 19.7173 13.5592 19.4078 13.5592Z"
                              fill="#A3A3A3"
                            />
                          </svg>
                        </div>
                        <div
                          className="recent__Activity"
                          onClick={() => {
                            props.handleDropDown();
                          }}
                        >
                          <img src={menuImg} alt="" />
                          {props.openDropdown ? (
                            <>
                              <div className="recentDropDown">
                                <p className="atoz" onClick={props.sortHandler}>
                                  <img src={atozImg} alt="" />
                                  A-Z
                                </p>
                                <p
                                  className="recent"
                                  onClick={props.recentHandler}
                                >
                                  <img src={recentImg} alt="" />
                                  Recent
                                </p>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <ul className="channer_ul side_bar">
                  {channelListData.map((e: any, index: any) => {
                    return (
                      <li
                        ref={myElementRef}
                        // className={
                        //   props.clickedElement === 0 ? "background-color" : ""
                        // }
                        onClick={() => {
                          props.areaList(e);
                          props.setClickedElement(index);
                          props.setInitialColor(false);
                          formik.values.title = "";
                          formik.values.address = "";
                        }}
                        style={{
                          backgroundColor:
                            props.clickedElement === index ? "#f9f9f9" : "",
                        }}
                      >
                        <div className="li_left">
                          <div className="letter">
                            <p>{e.name.charAt(0).toUpperCase()}</p>
                          </div>
                          <div className="m-loc">
                            <p>{e.name}</p>
                            {e.status ? (
                              <span style={{ color: "#2DB27C" }}>Active</span>
                            ) : (
                              <span style={{ color: "#FD3C4F" }}>InActive</span>
                            )}
                          </div>
                        </div>
                        <div className="li-right">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => {
                              console.warn("abc");
                              props.HandlesideMenu(index);
                            }}
                          >
                            <path
                              d="M6.47729 8.19276C6.47729 7.40259 7.12033 6.75955 7.91051 6.75955C8.70069 6.75955 9.34372 7.40259 9.34372 8.19276C9.34372 8.98294 8.70069 9.62598 7.91051 9.62598C7.12033 9.62598 6.47729 8.98294 6.47729 8.19276Z"
                              fill="#304E75"
                            />
                            <path
                              d="M6.47729 3.41542C6.47729 2.62524 7.12033 1.98221 7.91051 1.98221C8.70069 1.98221 9.34372 2.62524 9.34372 3.41542C9.34372 4.2056 8.70069 4.84863 7.91051 4.84863C7.12033 4.84863 6.47729 4.2056 6.47729 3.41542Z"
                              fill="#304E75"
                            />
                            <path
                              d="M6.47729 12.9701C6.47729 12.1799 7.12033 11.5369 7.91051 11.5369C8.70069 11.5369 9.34372 12.1799 9.34372 12.9701C9.34372 13.7603 8.70069 14.4033 7.91051 14.4033C7.12033 14.4033 6.47729 13.7603 6.47729 12.9701Z"
                              fill="#304E75"
                            />
                          </svg>
                          {props.openSideMenu &&
                          props.sideMenuIndex === index ? (
                            <>
                              <div className="right_li_dropDown">
                                <p
                                  onClick={() => {
                                    props.HandleEditChannel();
                                  }}
                                >
                                  <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_150_7606)">
                                      <path
                                        d="M13.9978 0.827843C13.6012 0.431874 13.0637 0.209473 12.5033 0.209473C11.9429 0.209473 11.4054 0.431874 11.0088 0.827843L1.52146 10.3152C1.24978 10.5853 1.03436 10.9067 0.887669 11.2606C0.740982 11.6146 0.665938 11.9941 0.666879 12.3773V13.5748C0.666879 13.7296 0.728337 13.8779 0.837733 13.9873C0.947129 14.0967 1.0955 14.1582 1.25021 14.1582H2.4478C2.83091 14.1593 3.21043 14.0843 3.56439 13.9377C3.91835 13.7912 4.23971 13.5758 4.50988 13.3042L13.9978 3.81626C14.3936 3.41971 14.6159 2.88232 14.6159 2.32205C14.6159 1.76178 14.3936 1.2244 13.9978 0.827843ZM3.68505 12.4793C3.35605 12.8062 2.91152 12.9902 2.4478 12.9915H1.83355V12.3773C1.83296 12.1474 1.87797 11.9196 1.96598 11.7073C2.054 11.4949 2.18326 11.3021 2.3463 11.14L9.54638 3.93993L10.888 5.28159L3.68505 12.4793ZM13.1724 2.99143L11.7105 4.45384L10.3689 3.11509L11.8313 1.65268C11.9194 1.56477 12.0239 1.49508 12.139 1.44758C12.254 1.40008 12.3773 1.3757 12.5017 1.37584C12.6262 1.37597 12.7494 1.40062 12.8643 1.44837C12.9792 1.49612 13.0836 1.56604 13.1715 1.65413C13.2594 1.74223 13.3291 1.84678 13.3766 1.9618C13.4241 2.07683 13.4485 2.20009 13.4483 2.32454C13.4482 2.44899 13.4236 2.5722 13.3758 2.68712C13.3281 2.80205 13.2581 2.90644 13.17 2.99434L13.1724 2.99143Z"
                                        fill="#374957"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_150_7606">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                          transform="translate(0.666748 0.158203)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  Edit
                                </p>
                                {props.selectedChannel.status ? (
                                  <p
                                    onClick={() => {
                                      props.HandleChannelBlock(
                                        props.selectedChannel.id
                                      );
                                    }}
                                  >
                                    <svg
                                      width="15"
                                      height="15"
                                      viewBox="0 0 15 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.66675 0.158203C6.28228 0.158203 4.9289 0.568746 3.77776 1.33792C2.62662 2.10709 1.72941 3.20034 1.19959 4.47942C0.669781 5.7585 0.531158 7.16597 0.801254 8.52384C1.07135 9.8817 1.73804 11.129 2.717 12.108C3.69597 13.0869 4.94325 13.7536 6.30112 14.0237C7.65899 14.2938 9.06645 14.1552 10.3455 13.6254C11.6246 13.0955 12.7179 12.1983 13.487 11.0472C14.2562 9.89605 14.6668 8.54267 14.6668 7.1582C14.6647 5.3023 13.9266 3.52299 12.6143 2.21067C11.302 0.898353 9.52265 0.16021 7.66675 0.158203V0.158203ZM7.66675 1.32487C9.01219 1.3238 10.3162 1.79022 11.3558 2.64437L3.15292 10.8472C2.45325 9.99311 2.01055 8.95786 1.87636 7.86195C1.74217 6.76605 1.922 5.65457 2.39493 4.65689C2.86785 3.65922 3.61441 2.81638 4.54771 2.2265C5.48101 1.63661 6.56266 1.32394 7.66675 1.32487ZM7.66675 12.9915C6.32131 12.9926 5.01729 12.5262 3.97775 11.672L12.1806 3.4692C12.8803 4.3233 13.323 5.35855 13.4571 6.45445C13.5913 7.55036 13.4115 8.66184 12.9386 9.65952C12.4657 10.6572 11.7191 11.5 10.7858 12.0899C9.85249 12.6798 8.77084 12.9925 7.66675 12.9915Z"
                                        fill="#374957"
                                      />
                                    </svg>
                                    Block
                                  </p>
                                ) : (
                                  <p
                                    onClick={() => {
                                      props.HandleChannelUnBlock(
                                        props.selectedChannel.id
                                      );
                                    }}
                                  >
                                    <svg
                                      width="15"
                                      height="15"
                                      viewBox="0 0 15 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.66675 0.158203C6.28228 0.158203 4.9289 0.568746 3.77776 1.33792C2.62662 2.10709 1.72941 3.20034 1.19959 4.47942C0.669781 5.7585 0.531158 7.16597 0.801254 8.52384C1.07135 9.8817 1.73804 11.129 2.717 12.108C3.69597 13.0869 4.94325 13.7536 6.30112 14.0237C7.65899 14.2938 9.06645 14.1552 10.3455 13.6254C11.6246 13.0955 12.7179 12.1983 13.487 11.0472C14.2562 9.89605 14.6668 8.54267 14.6668 7.1582C14.6647 5.3023 13.9266 3.52299 12.6143 2.21067C11.302 0.898353 9.52265 0.16021 7.66675 0.158203V0.158203ZM7.66675 1.32487C9.01219 1.3238 10.3162 1.79022 11.3558 2.64437L3.15292 10.8472C2.45325 9.99311 2.01055 8.95786 1.87636 7.86195C1.74217 6.76605 1.922 5.65457 2.39493 4.65689C2.86785 3.65922 3.61441 2.81638 4.54771 2.2265C5.48101 1.63661 6.56266 1.32394 7.66675 1.32487ZM7.66675 12.9915C6.32131 12.9926 5.01729 12.5262 3.97775 11.672L12.1806 3.4692C12.8803 4.3233 13.323 5.35855 13.4571 6.45445C13.5913 7.55036 13.4115 8.66184 12.9386 9.65952C12.4657 10.6572 11.7191 11.5 10.7858 12.0899C9.85249 12.6798 8.77084 12.9925 7.66675 12.9915Z"
                                        fill="#374957"
                                      />
                                    </svg>
                                    UnBlock
                                  </p>
                                )}
                                <p>
                                  <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_150_7614)">
                                      <path
                                        d="M12.9167 2.49154H11.1084C10.973 1.83319 10.6148 1.24165 10.0941 0.816622C9.57344 0.391589 8.92216 0.159052 8.25004 0.158203L7.08337 0.158203C6.41125 0.159052 5.75998 0.391589 5.23931 0.816622C4.71865 1.24165 4.36043 1.83319 4.22504 2.49154H2.41671C2.262 2.49154 2.11362 2.55299 2.00423 2.66239C1.89483 2.77179 1.83337 2.92016 1.83337 3.07487C1.83337 3.22958 1.89483 3.37795 2.00423 3.48735C2.11362 3.59674 2.262 3.6582 2.41671 3.6582H3.00004V11.2415C3.00097 12.0148 3.30856 12.7561 3.85534 13.3029C4.40212 13.8497 5.14344 14.1573 5.91671 14.1582H9.41671C10.19 14.1573 10.9313 13.8497 11.4781 13.3029C12.0249 12.7561 12.3324 12.0148 12.3334 11.2415V3.6582H12.9167C13.0714 3.6582 13.2198 3.59674 13.3292 3.48735C13.4386 3.37795 13.5 3.22958 13.5 3.07487C13.5 2.92016 13.4386 2.77179 13.3292 2.66239C13.2198 2.55299 13.0714 2.49154 12.9167 2.49154ZM7.08337 1.32487H8.25004C8.61187 1.32531 8.9647 1.43767 9.26015 1.64655C9.5556 1.85542 9.7792 2.15057 9.90029 2.49154H5.43312C5.55421 2.15057 5.77782 1.85542 6.07327 1.64655C6.36872 1.43767 6.72155 1.32531 7.08337 1.32487ZM11.1667 11.2415C11.1667 11.7057 10.9823 12.1508 10.6541 12.479C10.326 12.8072 9.88084 12.9915 9.41671 12.9915H5.91671C5.45258 12.9915 5.00746 12.8072 4.67927 12.479C4.35108 12.1508 4.16671 11.7057 4.16671 11.2415V3.6582H11.1667V11.2415Z"
                                        fill="#F05B5B"
                                      />
                                      <path
                                        d="M6.50008 10.6579C6.65479 10.6579 6.80316 10.5964 6.91256 10.487C7.02195 10.3776 7.08341 10.2292 7.08341 10.0745V6.57454C7.08341 6.41983 7.02195 6.27146 6.91256 6.16207C6.80316 6.05267 6.65479 5.99121 6.50008 5.99121C6.34537 5.99121 6.197 6.05267 6.0876 6.16207C5.97821 6.27146 5.91675 6.41983 5.91675 6.57454V10.0745C5.91675 10.2292 5.97821 10.3776 6.0876 10.487C6.197 10.5964 6.34537 10.6579 6.50008 10.6579Z"
                                        fill="#F05B5B"
                                      />
                                      <path
                                        d="M8.83334 10.6579C8.98805 10.6579 9.13642 10.5964 9.24582 10.487C9.35521 10.3776 9.41667 10.2292 9.41667 10.0745V6.57454C9.41667 6.41983 9.35521 6.27146 9.24582 6.16207C9.13642 6.05267 8.98805 5.99121 8.83334 5.99121C8.67863 5.99121 8.53025 6.05267 8.42086 6.16207C8.31146 6.27146 8.25 6.41983 8.25 6.57454V10.0745C8.25 10.2292 8.31146 10.3776 8.42086 10.487C8.53025 10.5964 8.67863 10.6579 8.83334 10.6579Z"
                                        fill="#F05B5B"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_150_7614">
                                        <rect
                                          width="14"
                                          height="14"
                                          fill="white"
                                          transform="translate(0.666748 0.158203)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  Delete
                                </p>
                                <p
                                  className="textred"
                                  onClick={props.handleDelete}
                                  style={{ paddingLeft: "40px" }}
                                >
                                  Cancel
                                </p>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
                {!props.isAdmin ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <div className="btmDiv">
                      <div
                        className="new-channel cursor-pointer"
                        onClick={() => {
                          props.HandleOpenCreateChannel();
                        }}
                      >
                        <svg
                          width="29"
                          height="29"
                          viewBox="0 0 29 29"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.7411 1.89258C12.2029 1.89258 9.72167 2.64524 7.61124 4.05538C5.50081 5.46553 3.85592 7.46982 2.8846 9.81481C1.91328 12.1598 1.65913 14.7401 2.15431 17.2296C2.64949 19.719 3.87174 22.0057 5.66652 23.8004C7.46129 25.5952 9.74797 26.8175 12.2374 27.3127C14.7268 27.8078 17.3072 27.5537 19.6522 26.5824C21.9971 25.611 24.0014 23.9662 25.4116 21.8557C26.8217 19.7453 27.5744 17.2641 27.5744 14.7259C27.5704 11.3235 26.217 8.06166 23.8112 5.65581C21.4053 3.24996 18.1434 1.89659 14.7411 1.89258ZM14.7411 25.2259C12.6644 25.2259 10.6343 24.6101 8.90757 23.4563C7.18085 22.3026 5.83504 20.6627 5.04032 18.7441C4.2456 16.8255 4.03766 14.7143 4.44281 12.6775C4.84795 10.6407 5.84798 8.76974 7.31643 7.30129C8.78488 5.83284 10.6558 4.83281 12.6926 4.42767C14.7294 4.02252 16.8406 4.23046 18.7592 5.02518C20.6779 5.8199 22.3177 7.16571 23.4715 8.89242C24.6252 10.6191 25.2411 12.6492 25.2411 14.7259C25.2377 17.5096 24.1303 20.1784 22.1619 22.1468C20.1935 24.1152 17.5248 25.2225 14.7411 25.2259Z"
                            fill="#A3A3A3"
                          />
                          <path
                            d="M19.4078 13.5592H15.9078V10.0592C15.9078 9.74983 15.7849 9.45308 15.5661 9.23429C15.3473 9.01549 15.0506 8.89258 14.7412 8.89258C14.4318 8.89258 14.135 9.01549 13.9162 9.23429C13.6974 9.45308 13.5745 9.74983 13.5745 10.0592V13.5592H10.0745C9.76508 13.5592 9.46834 13.6822 9.24955 13.901C9.03075 14.1197 8.90784 14.4165 8.90784 14.7259C8.90784 15.0353 9.03075 15.3321 9.24955 15.5509C9.46834 15.7697 9.76508 15.8926 10.0745 15.8926H13.5745V19.3926C13.5745 19.702 13.6974 19.9987 13.9162 20.2175C14.135 20.4363 14.4318 20.5592 14.7412 20.5592C15.0506 20.5592 15.3473 20.4363 15.5661 20.2175C15.7849 19.9987 15.9078 19.702 15.9078 19.3926V15.8926H19.4078C19.7173 15.8926 20.014 15.7697 20.2328 15.5509C20.4516 15.3321 20.5745 15.0353 20.5745 14.7259C20.5745 14.4165 20.4516 14.1197 20.2328 13.901C20.014 13.6822 19.7173 13.5592 19.4078 13.5592Z"
                            fill="#A3A3A3"
                          />
                        </svg>
                        New Channel
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="channel-cstm-col-md-8">
              <div className="cafe_side">
                <div className="cafe-top">
                  <div className="cafe-top-left">
                    <p>{props.selectedChannel.name}</p>
                    <span>
                      {props.areaData?.length}
                      <a
                        onClick={() =>
                          props.onAuthKeyPress(props.selectedChannel.key)
                        }
                      >
                        | Authentication Key
                      </a>
                      <a
                        onClick={() =>
                          navigate("/device/dashboard", {
                            state: { channelId: props.selectedChannel.id },
                          })
                        }
                      >
                        | View Devices
                      </a>
                    </span>
                  </div>
                  <div className="cafe-top-right">
                    <div className="search-box">
                      <input
                        type="text"
                        className="search-input"
                        placeholder="Search..."
                        value={props.searchQuery}
                        onChange={props.handleSearchQueryChange}
                      />
                      <svg
                        className="search-icon"
                        width="23"
                        height="23"
                        viewBox="0 0 29 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.53979 11.8167C2.53979 14.2921 3.52313 16.6661 5.27346 18.4164C7.0238 20.1667 9.39777 21.1501 11.8731 21.1501C13.9459 21.1526 15.9593 20.4582 17.5898 19.1784L22.7115 24.3117C22.8199 24.4211 22.9489 24.5079 23.0911 24.5671C23.2333 24.6263 23.3858 24.6568 23.5398 24.6568C23.6938 24.6568 23.8463 24.6263 23.9885 24.5671C24.1306 24.5079 24.2597 24.4211 24.3681 24.3117C24.4775 24.2033 24.5643 24.0742 24.6235 23.9321C24.6827 23.7899 24.7132 23.6374 24.7132 23.4834C24.7132 23.3294 24.6827 23.1769 24.6235 23.0347C24.5643 22.8926 24.4775 22.7635 24.3681 22.6551L19.2348 17.5334C20.5146 15.9029 21.209 13.8895 21.2065 11.8167C21.2065 9.34138 20.2231 6.96741 18.4728 5.21707C16.7225 3.46673 14.3485 2.4834 11.8731 2.4834C9.39777 2.4834 7.0238 3.46673 5.27346 5.21707C3.52313 6.96741 2.53979 9.34138 2.53979 11.8167ZM11.8731 4.81673C13.2576 4.81673 14.611 5.22727 15.7621 5.99644C16.9133 6.76561 17.8105 7.85886 18.3403 9.13795C18.8701 10.417 19.0087 11.8245 18.7386 13.1824C18.4685 14.5402 17.8018 15.7875 16.8229 16.7665C15.8439 17.7454 14.5966 18.4121 13.2388 18.6822C11.8809 18.9523 10.4734 18.8137 9.19434 18.2839C7.91526 17.7541 6.82201 16.8569 6.05284 15.7057C5.28367 14.5546 4.87313 13.2012 4.87313 11.8167C4.87313 9.96022 5.61063 8.17974 6.92338 6.86698C8.23614 5.55423 10.0166 4.81673 11.8731 4.81673Z"
                          fill="#A3A3A3"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="devicesTable">
                  <table style={{ position: "relative" }}>
                    <thead>
                      <tr>
                        <th>Area/Title Name</th>
                        <th>Address</th>
                        <th>Devices</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            placeholder="Area/Title"
                            name="title"
                            onChange={formik.handleChange}
                            value={formik.values.title}
                            type="text"
                            className={formik.errors.title ? "error_td" : "td"}
                          />

                          <p
                            style={{
                              fontSize: "10px",
                              lineHeight: "15px",
                              color: "#fd3c4f",
                            }}
                          >
                            {formik.errors.title}
                          </p>
                          <p
                            style={{
                              fontSize: "10px",
                              lineHeight: "15px",
                              color: "#fd3c4f",
                            }}
                          >
                            {props.apiError}
                          </p>
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="Address"
                            name="address"
                            onChange={formik.handleChange}
                            value={formik.values.address}
                          />

                          <p
                            style={{
                              fontSize: "10px",
                              lineHeight: "15px",
                              color: "#fd3c4f",
                            }}
                          >
                            {formik.errors.address}
                          </p>
                        </td>
                        <td></td>
                        <td>
                          {props.onloader ? (
                            <div className="addDiv" onClick={formik.submitForm}>
                              <PlusCircleOutlined />
                            </div>
                          ) : (
                            <Spin indicator={antIcon2} />
                          )}
                        </td>
                      </tr>
                      {/* {props.addDevices ? (
                        <tr className="Add-device">
                          <td>
                            <input
                              name="title2"
                              onChange={formik2.handleChange}
                              value={formik2.values.title2}
                              type="text"
                              placeholder="Title"
                              className={props.error ? "error_td" : "td"}
                            />
                            {props.error ? (
                              <div className="errortooltip ">
                                <p>Location already exists</p>
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>
                            <input
                              type="text"
                              placeholder="Address"
                              name="address2"
                              onChange={formik2.handleChange}
                              value={formik2.values.address2}
                            />
                          </td>
                          <td>
                            <div className="addDevices">AddDevices</div>
                          </td>
                          <td>
                            <div
                              onClick={formik2.submitForm}
                              className="svg-div"
                            >
                              <svg
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.3394 1.94141C10.1638 1.94141 8.03703 2.58654 6.22809 3.79524C4.41915 5.00394 3.00925 6.7219 2.17669 8.73189C1.34412 10.7419 1.12628 12.9536 1.55072 15.0874C1.97516 17.2212 3.02281 19.1812 4.56119 20.7196C6.09956 22.258 8.05958 23.3056 10.1934 23.73C12.3272 24.1545 14.5389 23.9366 16.5489 23.1041C18.5589 22.2715 20.2768 20.8616 21.4855 19.0527C22.6942 17.2437 23.3394 15.117 23.3394 12.9414C23.3359 10.0251 22.1759 7.22919 20.1137 5.16703C18.0516 3.10488 15.2557 1.94485 12.3394 1.94141ZM12.3394 21.9414C10.5593 21.9414 8.81927 21.4136 7.33923 20.4246C5.85919 19.4357 4.70563 18.0301 4.02444 16.3856C3.34326 14.741 3.16503 12.9314 3.51229 11.1856C3.85956 9.43976 4.71673 7.83612 5.9754 6.57744C7.23407 5.31877 8.83772 4.46161 10.5835 4.11434C12.3294 3.76707 14.139 3.9453 15.7835 4.62649C17.428 5.30768 18.8337 6.46123 19.8226 7.94127C20.8115 9.42132 21.3394 11.1614 21.3394 12.9414C21.3367 15.3275 20.3877 17.6152 18.7004 19.3024C17.0131 20.9897 14.7255 21.9388 12.3394 21.9414Z"
                                  fill="#00A389"
                                />
                                <path
                                  d="M16.6632 9.70448L11.3692 14.5575L9.04619 12.2345C8.95395 12.139 8.8436 12.0628 8.7216 12.0104C8.59959 11.958 8.46837 11.9304 8.33559 11.9292C8.20281 11.9281 8.07113 11.9534 7.94824 12.0037C7.82534 12.0539 7.71369 12.1282 7.6198 12.2221C7.5259 12.316 7.45165 12.4276 7.40137 12.5505C7.35109 12.6734 7.32579 12.8051 7.32694 12.9379C7.3281 13.0707 7.35568 13.2019 7.40809 13.3239C7.4605 13.4459 7.53668 13.5562 7.63219 13.6485L10.6322 16.6485C10.8144 16.8307 11.06 16.9356 11.3177 16.9412C11.5753 16.9468 11.8252 16.8527 12.0152 16.6785L18.0152 11.1785C18.2107 10.9992 18.3269 10.7496 18.3383 10.4846C18.3498 10.2196 18.2555 9.96094 18.0762 9.76548C17.8969 9.57002 17.6473 9.45378 17.3823 9.44234C17.1173 9.4309 16.8587 9.52519 16.6632 9.70448Z"
                                  fill="#00A389"
                                />
                              </svg>
                            </div>
                            <div
                              onClick={() => {
                                props.setAdddevice(false);
                                console.log("cross click");
                              }}
                              className="svg-div"
                            >
                              <svg
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.3396 23.9414C14.5152 23.9414 16.6419 23.2963 18.4509 22.0876C20.2598 20.8789 21.6697 19.1609 22.5023 17.1509C23.3348 15.1409 23.5527 12.9292 23.1282 10.7954C22.7038 8.66163 21.6562 6.70161 20.1178 5.16324C18.5794 3.62486 16.6194 2.57721 14.4856 2.15277C12.3518 1.72834 10.1401 1.94617 8.13008 2.77874C6.1201 3.6113 4.40213 5.0212 3.19343 6.83014C1.98474 8.63908 1.3396 10.7658 1.3396 12.9414C1.34304 15.8577 2.50307 18.6536 4.56523 20.7158C6.62738 22.7779 9.42327 23.938 12.3396 23.9414ZM12.3396 3.94141C14.1196 3.94141 15.8597 4.46925 17.3397 5.45819C18.8198 6.44712 19.9733 7.85273 20.6545 9.49726C21.3357 11.1418 21.5139 12.9514 21.1667 14.6972C20.8194 16.4431 19.9622 18.0467 18.7036 19.3054C17.4449 20.564 15.8412 21.4212 14.0954 21.7685C12.3496 22.1157 10.54 21.9375 8.89545 21.2563C7.25091 20.5751 5.84531 19.4216 4.85637 17.9415C3.86744 16.4615 3.3396 14.7214 3.3396 12.9414C3.34225 10.5553 4.29131 8.26763 5.97856 6.58037C7.66582 4.89312 9.95346 3.94406 12.3396 3.94141Z"
                                  fill="#909090"
                                />
                                <path
                                  d="M8.63239 16.6483C8.81991 16.8358 9.07422 16.9411 9.33939 16.9411C9.60455 16.9411 9.85886 16.8358 10.0464 16.6483L12.3394 14.3553L14.6324 16.6483C14.821 16.8305 15.0736 16.9313 15.3358 16.929C15.598 16.9267 15.8488 16.8216 16.0342 16.6361C16.2196 16.4507 16.3248 16.1999 16.3271 15.9377C16.3293 15.6755 16.2285 15.4229 16.0464 15.2343L13.7534 12.9413L16.0464 10.6483C16.2285 10.4597 16.3293 10.2071 16.3271 9.94492C16.3248 9.68273 16.2196 9.43191 16.0342 9.24651C15.8488 9.0611 15.598 8.95593 15.3358 8.95365C15.0736 8.95137 14.821 9.05217 14.6324 9.23433L12.3394 11.5273L10.0464 9.23433C9.85778 9.05217 9.60518 8.95137 9.34299 8.95365C9.08079 8.95593 8.82998 9.0611 8.64457 9.24651C8.45916 9.43191 8.35399 9.68273 8.35171 9.94492C8.34943 10.2071 8.45023 10.4597 8.63239 10.6483L10.9254 12.9413L8.63239 15.2343C8.44492 15.4219 8.3396 15.6762 8.3396 15.9413C8.3396 16.2065 8.44492 16.4608 8.63239 16.6483Z"
                                  fill="#909090"
                                />
                              </svg>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )} */}
                      {props.areaData?.length > 0 ? (
                        <>
                          {props.searchQuery
                            ? props.filteredData.map((e: any, index: any) => {
                                return (
                                  <>
                                    {props.addDevices &&
                                    props.addDevicesEditData.index === index ? (
                                      <tr className="Add-device">
                                        <td>
                                          <input
                                            name="title2"
                                            onChange={formik2.handleChange}
                                            value={formik2.values.title2}
                                            type="text"
                                            placeholder="Area/Title"
                                            className={
                                              props.error ? "error_td" : "td"
                                            }
                                          />
                                          <p
                                            style={{
                                              fontSize: "10px",
                                              lineHeight: "15px",
                                              color: "rgb(253, 60, 79)",
                                            }}
                                          >
                                            {formik2.errors.title2}
                                          </p>
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            placeholder="Address"
                                            name="address2"
                                            onChange={formik2.handleChange}
                                            value={formik2.values.address2}
                                          />
                                          <p
                                            style={{
                                              fontSize: "10px",
                                              lineHeight: "15px",
                                              color: "rgb(253, 60, 79)",
                                            }}
                                          >
                                            {formik2.errors.address2}
                                          </p>
                                        </td>
                                        <td></td>
                                        <td>
                                          {props.editloader === false ? (
                                            <>
                                              <div
                                                onClick={formik2.submitForm}
                                                className="svg-div"
                                              >
                                                <svg
                                                  width="25"
                                                  height="25"
                                                  viewBox="0 0 25 25"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M12.3394 1.94141C10.1638 1.94141 8.03703 2.58654 6.22809 3.79524C4.41915 5.00394 3.00925 6.7219 2.17669 8.73189C1.34412 10.7419 1.12628 12.9536 1.55072 15.0874C1.97516 17.2212 3.02281 19.1812 4.56119 20.7196C6.09956 22.258 8.05958 23.3056 10.1934 23.73C12.3272 24.1545 14.5389 23.9366 16.5489 23.1041C18.5589 22.2715 20.2768 20.8616 21.4855 19.0527C22.6942 17.2437 23.3394 15.117 23.3394 12.9414C23.3359 10.0251 22.1759 7.22919 20.1137 5.16703C18.0516 3.10488 15.2557 1.94485 12.3394 1.94141ZM12.3394 21.9414C10.5593 21.9414 8.81927 21.4136 7.33923 20.4246C5.85919 19.4357 4.70563 18.0301 4.02444 16.3856C3.34326 14.741 3.16503 12.9314 3.51229 11.1856C3.85956 9.43976 4.71673 7.83612 5.9754 6.57744C7.23407 5.31877 8.83772 4.46161 10.5835 4.11434C12.3294 3.76707 14.139 3.9453 15.7835 4.62649C17.428 5.30768 18.8337 6.46123 19.8226 7.94127C20.8115 9.42132 21.3394 11.1614 21.3394 12.9414C21.3367 15.3275 20.3877 17.6152 18.7004 19.3024C17.0131 20.9897 14.7255 21.9388 12.3394 21.9414Z"
                                                    fill="#00A389"
                                                  />
                                                  <path
                                                    d="M16.6632 9.70448L11.3692 14.5575L9.04619 12.2345C8.95395 12.139 8.8436 12.0628 8.7216 12.0104C8.59959 11.958 8.46837 11.9304 8.33559 11.9292C8.20281 11.9281 8.07113 11.9534 7.94824 12.0037C7.82534 12.0539 7.71369 12.1282 7.6198 12.2221C7.5259 12.316 7.45165 12.4276 7.40137 12.5505C7.35109 12.6734 7.32579 12.8051 7.32694 12.9379C7.3281 13.0707 7.35568 13.2019 7.40809 13.3239C7.4605 13.4459 7.53668 13.5562 7.63219 13.6485L10.6322 16.6485C10.8144 16.8307 11.06 16.9356 11.3177 16.9412C11.5753 16.9468 11.8252 16.8527 12.0152 16.6785L18.0152 11.1785C18.2107 10.9992 18.3269 10.7496 18.3383 10.4846C18.3498 10.2196 18.2555 9.96094 18.0762 9.76548C17.8969 9.57002 17.6473 9.45378 17.3823 9.44234C17.1173 9.4309 16.8587 9.52519 16.6632 9.70448Z"
                                                    fill="#00A389"
                                                  />
                                                </svg>
                                              </div>
                                              <div
                                                onClick={() => {
                                                  props.setAdddevice(false);
                                                }}
                                                className="svg-div"
                                              >
                                                <svg
                                                  width="25"
                                                  height="25"
                                                  viewBox="0 0 25 25"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M12.3396 23.9414C14.5152 23.9414 16.6419 23.2963 18.4509 22.0876C20.2598 20.8789 21.6697 19.1609 22.5023 17.1509C23.3348 15.1409 23.5527 12.9292 23.1282 10.7954C22.7038 8.66163 21.6562 6.70161 20.1178 5.16324C18.5794 3.62486 16.6194 2.57721 14.4856 2.15277C12.3518 1.72834 10.1401 1.94617 8.13008 2.77874C6.1201 3.6113 4.40213 5.0212 3.19343 6.83014C1.98474 8.63908 1.3396 10.7658 1.3396 12.9414C1.34304 15.8577 2.50307 18.6536 4.56523 20.7158C6.62738 22.7779 9.42327 23.938 12.3396 23.9414ZM12.3396 3.94141C14.1196 3.94141 15.8597 4.46925 17.3397 5.45819C18.8198 6.44712 19.9733 7.85273 20.6545 9.49726C21.3357 11.1418 21.5139 12.9514 21.1667 14.6972C20.8194 16.4431 19.9622 18.0467 18.7036 19.3054C17.4449 20.564 15.8412 21.4212 14.0954 21.7685C12.3496 22.1157 10.54 21.9375 8.89545 21.2563C7.25091 20.5751 5.84531 19.4216 4.85637 17.9415C3.86744 16.4615 3.3396 14.7214 3.3396 12.9414C3.34225 10.5553 4.29131 8.26763 5.97856 6.58037C7.66582 4.89312 9.95346 3.94406 12.3396 3.94141Z"
                                                    fill="#909090"
                                                  />
                                                  <path
                                                    d="M8.63239 16.6483C8.81991 16.8358 9.07422 16.9411 9.33939 16.9411C9.60455 16.9411 9.85886 16.8358 10.0464 16.6483L12.3394 14.3553L14.6324 16.6483C14.821 16.8305 15.0736 16.9313 15.3358 16.929C15.598 16.9267 15.8488 16.8216 16.0342 16.6361C16.2196 16.4507 16.3248 16.1999 16.3271 15.9377C16.3293 15.6755 16.2285 15.4229 16.0464 15.2343L13.7534 12.9413L16.0464 10.6483C16.2285 10.4597 16.3293 10.2071 16.3271 9.94492C16.3248 9.68273 16.2196 9.43191 16.0342 9.24651C15.8488 9.0611 15.598 8.95593 15.3358 8.95365C15.0736 8.95137 14.821 9.05217 14.6324 9.23433L12.3394 11.5273L10.0464 9.23433C9.85778 9.05217 9.60518 8.95137 9.34299 8.95365C9.08079 8.95593 8.82998 9.0611 8.64457 9.24651C8.45916 9.43191 8.35399 9.68273 8.35171 9.94492C8.34943 10.2071 8.45023 10.4597 8.63239 10.6483L10.9254 12.9413L8.63239 15.2343C8.44492 15.4219 8.3396 15.6762 8.3396 15.9413C8.3396 16.2065 8.44492 16.4608 8.63239 16.6483Z"
                                                    fill="#909090"
                                                  />
                                                </svg>
                                              </div>
                                            </>
                                          ) : (
                                            <Spin indicator={antIcon2} />
                                          )}
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr>
                                        <td> {e.name} </td>
                                        <td> {e.address}</td>
                                        <td
                                          className="totaldevices"
                                          id="t-devices"
                                          onClick={() => {
                                            props.HandleOpneDevices();
                                          }}
                                        >
                                          <a
                                            onClick={() =>
                                              navigate("/device/dashboard", {
                                                state: { AreaId: e.id },
                                              })
                                            }
                                          >
                                            {props.deviceData.reduce(
                                              (acc: any, curr: any) =>
                                                curr === e.id ? acc + 1 : acc,
                                              0
                                            )}{" "}
                                            Devices
                                          </a>

                                          {/* {props.openDevicesList ? (
                                  <ul className="td-dropDown">
                                    <li>
                                      <div className="topDevice_li">
                                        <div className="dev__top">
                                          <p> Devices</p>
                                          <span>210 Devices</span>
                                        </div>
                                        <div className="d-icon">
                                          <svg
                                            width="21"
                                            height="21"
                                            viewBox="0 0 21 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M17.1653 14.4073L10.3992 7.64123L3.63307 14.4073C3.49575 14.54 3.31183 14.6133 3.12093 14.6117C2.93003 14.61 2.74741 14.5335 2.61242 14.3985C2.47743 14.2635 2.40086 14.0809 2.3992 13.89C2.39754 13.6991 2.47093 13.5151 2.60355 13.3778L9.88441 6.09696C10.0209 5.96047 10.2061 5.88379 10.3992 5.88379C10.5922 5.88379 10.7774 5.96047 10.9139 6.09696L18.1948 13.3778C18.3274 13.5151 18.4008 13.6991 18.3991 13.89C18.3975 14.0809 18.3209 14.2635 18.1859 14.3985C18.0509 14.5335 17.8683 14.61 17.6774 14.6117C17.4865 14.6133 17.3026 14.54 17.1653 14.4073Z"
                                              fill="#B9BBBD"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <p> Device 1</p>
                                      <span>IMEI 25-999990-176148-2</span>
                                    </li>

                                    <a href="javascript:;" className="loadMore">
                                      Load More
                                      <svg
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.44726 9.94152C7.31248 9.94034 7.18343 9.88686 7.08733 9.79235L1.99642 4.70145C1.90369 4.60543 1.85237 4.47683 1.85353 4.34335C1.85469 4.20987 1.90824 4.08218 2.00262 3.98779C2.09701 3.8934 2.2247 3.83986 2.35818 3.8387C2.49166 3.83754 2.62026 3.88886 2.71628 3.98159L7.44726 8.71257L12.1782 3.98159C12.2252 3.93297 12.2814 3.89418 12.3435 3.8675C12.4056 3.84082 12.4724 3.82678 12.54 3.82619C12.6076 3.8256 12.6746 3.83848 12.7372 3.86408C12.7998 3.88968 12.8566 3.92748 12.9044 3.97528C12.9522 4.02308 12.99 4.07992 13.0156 4.14249C13.0412 4.20505 13.0541 4.27209 13.0535 4.33969C13.0529 4.40728 13.0389 4.47409 13.0122 4.5362C12.9855 4.59831 12.9467 4.65448 12.8981 4.70145L7.80719 9.79235C7.71109 9.88686 7.58204 9.94034 7.44726 9.94152Z"
                                          fill="#B6B6B6"
                                        />
                                      </svg>
                                    </a>
                                  </ul>
                                ) : (
                                  <></>
                                )} */}
                                        </td>
                                        <td className="flex">
                                          <div
                                            className="edit cursor-pointer"
                                            onClick={() => {
                                              props.handleAddDevices(
                                                e.name,
                                                e.address,
                                                e.id,
                                                index
                                              );
                                            }}
                                            style={{ marginLeft: "7px" }}
                                          >
                                            <svg
                                              width="37"
                                              height="37"
                                              viewBox="0 0 37 37"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M12.4393 25.9527H15.2393C15.3314 25.9532 15.4227 25.9356 15.508 25.9007C15.5933 25.8659 15.6709 25.8146 15.7363 25.7497L24.1363 17.3497C24.2019 17.2846 24.254 17.2072 24.2895 17.1219C24.325 17.0366 24.3433 16.9451 24.3433 16.8527C24.3433 16.7603 24.325 16.6688 24.2895 16.5835C24.254 16.4982 24.2019 16.4208 24.1363 16.3557L21.3363 13.5557C21.2712 13.4901 21.1938 13.438 21.1085 13.4025C21.0232 13.3669 20.9317 13.3486 20.8393 13.3486C20.7469 13.3486 20.6554 13.3669 20.5701 13.4025C20.4848 13.438 20.4073 13.4901 20.3423 13.5557L11.9423 21.9557C11.8774 22.0211 11.8261 22.0987 11.7912 22.184C11.7564 22.2692 11.7387 22.3606 11.7393 22.4527V25.2527C11.7393 25.4383 11.813 25.6164 11.9443 25.7477C12.0756 25.8789 12.2536 25.9527 12.4393 25.9527ZM13.1393 22.7397L20.8393 15.0397L22.6523 16.8527L14.9523 24.5527H13.1393V22.7397Z"
                                                fill="#A3A3A3"
                                              />
                                              <path
                                                d="M22.7365 12.1551C22.6712 12.0898 22.5937 12.038 22.5085 12.0027C22.4232 11.9674 22.3318 11.9492 22.2395 11.9492C22.0531 11.9492 21.8743 12.0233 21.7425 12.1551C21.6107 12.2869 21.5366 12.4657 21.5366 12.6521C21.5366 12.8385 21.6107 13.0173 21.7425 13.1491L24.5425 15.9491C24.6076 16.0147 24.685 16.0668 24.7703 16.1023C24.8556 16.1378 24.9471 16.1561 25.0395 16.1561C25.1319 16.1561 25.2234 16.1378 25.3087 16.1023C25.394 16.0668 25.4714 16.0147 25.5365 15.9491C25.6021 15.884 25.6542 15.8066 25.6897 15.7213C25.7252 15.636 25.7435 15.5445 25.7435 15.4521C25.7435 15.3597 25.7252 15.2682 25.6897 15.1829C25.6542 15.0976 25.6021 15.0202 25.5365 14.9551L22.7365 12.1551Z"
                                                fill="#A3A3A3"
                                              />
                                              <rect
                                                x="1.16277"
                                                y="1.37664"
                                                width="35.153"
                                                height="35.153"
                                                rx="10.3765"
                                                stroke="#ECEAF3"
                                                stroke-width="0.847025"
                                              />
                                            </svg>
                                          </div>
                                          {/* <div className="delete cursor-pointer">
                                          <svg
                                            width="37"
                                            height="37"
                                            viewBox="0 0 37 37"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M13.0403 14.6492V23.0492C13.0403 23.6062 13.2616 24.1403 13.6554 24.5341C14.0492 24.928 14.5834 25.1492 15.1403 25.1492H22.1403C22.6973 25.1492 23.2314 24.928 23.6253 24.5341C24.0191 24.1403 24.2403 23.6062 24.2403 23.0492V14.6492C24.426 14.6492 24.604 14.5755 24.7353 14.4442C24.8666 14.3129 24.9403 14.1349 24.9403 13.9492C24.9403 13.7636 24.8666 13.5855 24.7353 13.4542C24.604 13.323 24.426 13.2492 24.2403 13.2492H21.4403V12.5492C21.4403 11.9923 21.2191 11.4581 20.8253 11.0643C20.4314 10.6705 19.8973 10.4492 19.3403 10.4492H17.9403C17.3834 10.4492 16.8492 10.6705 16.4554 11.0643C16.0616 11.4581 15.8403 11.9923 15.8403 12.5492V13.2492H13.0403C12.8547 13.2492 12.6766 13.323 12.5454 13.4542C12.4141 13.5855 12.3403 13.7636 12.3403 13.9492C12.3403 14.1349 12.4141 14.3129 12.5454 14.4442C12.6766 14.5755 12.8547 14.6492 13.0403 14.6492ZM17.2403 12.5492C17.2403 12.3636 17.3141 12.1855 17.4454 12.0542C17.5766 11.923 17.7547 11.8492 17.9403 11.8492H19.3403C19.526 11.8492 19.704 11.923 19.8353 12.0542C19.9666 12.1855 20.0403 12.3636 20.0403 12.5492V13.2492H17.2403V12.5492ZM14.4403 14.6492H22.8403V23.0492C22.8403 23.2349 22.7666 23.4129 22.6353 23.5442C22.504 23.6755 22.326 23.7492 22.1403 23.7492H15.1403C14.9547 23.7492 14.7766 23.6755 14.6454 23.5442C14.5141 23.4129 14.4403 23.2349 14.4403 23.0492V14.6492Z"
                                              fill="#A3A3A3"
                                            />
                                            <path
                                              d="M16.5398 22.3508C16.7255 22.3508 16.9035 22.277 17.0348 22.1458C17.1661 22.0145 17.2398 21.8364 17.2398 21.6508V16.7508C17.2398 16.5651 17.1661 16.3871 17.0348 16.2558C16.9035 16.1245 16.7255 16.0508 16.5398 16.0508C16.3542 16.0508 16.1761 16.1245 16.0449 16.2558C15.9136 16.3871 15.8398 16.5651 15.8398 16.7508V21.6508C15.8398 21.8364 15.9136 22.0145 16.0449 22.1458C16.1761 22.277 16.3542 22.3508 16.5398 22.3508Z"
                                              fill="#A3A3A3"
                                            />
                                            <path
                                              d="M20.74 22.3508C20.9257 22.3508 21.1037 22.277 21.235 22.1458C21.3663 22.0145 21.44 21.8364 21.44 21.6508V16.7508C21.44 16.5651 21.3663 16.3871 21.235 16.2558C21.1037 16.1245 20.9257 16.0508 20.74 16.0508C20.5544 16.0508 20.3763 16.1245 20.2451 16.2558C20.1138 16.3871 20.04 16.5651 20.04 16.7508V21.6508C20.04 21.8364 20.1138 22.0145 20.2451 22.1458C20.3763 22.277 20.5544 22.3508 20.74 22.3508Z"
                                              fill="#A3A3A3"
                                            />
                                            <rect
                                              x="1.06365"
                                              y="0.573903"
                                              width="35.153"
                                              height="35.153"
                                              rx="10.3765"
                                              stroke="#ECEAF3"
                                              stroke-width="0.847025"
                                            />
                                          </svg>
                                        </div> */}
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                );
                              })
                            : props.currentData.map((e: any, index: any) => {
                                return (
                                  <>
                                    {props.addDevices &&
                                    props.addDevicesEditData.index === index ? (
                                      <tr className="Add-device">
                                        <td>
                                          <input
                                            name="title2"
                                            onChange={formik2.handleChange}
                                            value={formik2.values.title2}
                                            type="text"
                                            placeholder="Area/Title"
                                            // className={
                                            //   props.error ? "error_td" : "td"
                                            // }
                                          />
                                          <p
                                            style={{
                                              fontSize: "10px",
                                              lineHeight: "15px",
                                              color: "rgb(253, 60, 79)",
                                            }}
                                          >
                                            {formik2.errors.title2}
                                          </p>
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            placeholder="Address"
                                            name="address2"
                                            onChange={formik2.handleChange}
                                            value={formik2.values.address2}
                                          />
                                          <p
                                            style={{
                                              fontSize: "10px",
                                              lineHeight: "15px",
                                              color: "rgb(253, 60, 79)",
                                            }}
                                          >
                                            {formik2.errors.address2}
                                          </p>
                                        </td>

                                        <td></td>

                                        <td>
                                          {props.editloader === false ? (
                                            <>
                                              <div
                                                onClick={formik2.submitForm}
                                                className="svg-div"
                                              >
                                                <svg
                                                  width="25"
                                                  height="25"
                                                  viewBox="0 0 25 25"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M12.3394 1.94141C10.1638 1.94141 8.03703 2.58654 6.22809 3.79524C4.41915 5.00394 3.00925 6.7219 2.17669 8.73189C1.34412 10.7419 1.12628 12.9536 1.55072 15.0874C1.97516 17.2212 3.02281 19.1812 4.56119 20.7196C6.09956 22.258 8.05958 23.3056 10.1934 23.73C12.3272 24.1545 14.5389 23.9366 16.5489 23.1041C18.5589 22.2715 20.2768 20.8616 21.4855 19.0527C22.6942 17.2437 23.3394 15.117 23.3394 12.9414C23.3359 10.0251 22.1759 7.22919 20.1137 5.16703C18.0516 3.10488 15.2557 1.94485 12.3394 1.94141ZM12.3394 21.9414C10.5593 21.9414 8.81927 21.4136 7.33923 20.4246C5.85919 19.4357 4.70563 18.0301 4.02444 16.3856C3.34326 14.741 3.16503 12.9314 3.51229 11.1856C3.85956 9.43976 4.71673 7.83612 5.9754 6.57744C7.23407 5.31877 8.83772 4.46161 10.5835 4.11434C12.3294 3.76707 14.139 3.9453 15.7835 4.62649C17.428 5.30768 18.8337 6.46123 19.8226 7.94127C20.8115 9.42132 21.3394 11.1614 21.3394 12.9414C21.3367 15.3275 20.3877 17.6152 18.7004 19.3024C17.0131 20.9897 14.7255 21.9388 12.3394 21.9414Z"
                                                    fill="#00A389"
                                                  />
                                                  <path
                                                    d="M16.6632 9.70448L11.3692 14.5575L9.04619 12.2345C8.95395 12.139 8.8436 12.0628 8.7216 12.0104C8.59959 11.958 8.46837 11.9304 8.33559 11.9292C8.20281 11.9281 8.07113 11.9534 7.94824 12.0037C7.82534 12.0539 7.71369 12.1282 7.6198 12.2221C7.5259 12.316 7.45165 12.4276 7.40137 12.5505C7.35109 12.6734 7.32579 12.8051 7.32694 12.9379C7.3281 13.0707 7.35568 13.2019 7.40809 13.3239C7.4605 13.4459 7.53668 13.5562 7.63219 13.6485L10.6322 16.6485C10.8144 16.8307 11.06 16.9356 11.3177 16.9412C11.5753 16.9468 11.8252 16.8527 12.0152 16.6785L18.0152 11.1785C18.2107 10.9992 18.3269 10.7496 18.3383 10.4846C18.3498 10.2196 18.2555 9.96094 18.0762 9.76548C17.8969 9.57002 17.6473 9.45378 17.3823 9.44234C17.1173 9.4309 16.8587 9.52519 16.6632 9.70448Z"
                                                    fill="#00A389"
                                                  />
                                                </svg>
                                              </div>
                                              <div
                                                onClick={() => {
                                                  props.setAdddevice(false);
                                                }}
                                                className="svg-div"
                                              >
                                                <svg
                                                  width="25"
                                                  height="25"
                                                  viewBox="0 0 25 25"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M12.3396 23.9414C14.5152 23.9414 16.6419 23.2963 18.4509 22.0876C20.2598 20.8789 21.6697 19.1609 22.5023 17.1509C23.3348 15.1409 23.5527 12.9292 23.1282 10.7954C22.7038 8.66163 21.6562 6.70161 20.1178 5.16324C18.5794 3.62486 16.6194 2.57721 14.4856 2.15277C12.3518 1.72834 10.1401 1.94617 8.13008 2.77874C6.1201 3.6113 4.40213 5.0212 3.19343 6.83014C1.98474 8.63908 1.3396 10.7658 1.3396 12.9414C1.34304 15.8577 2.50307 18.6536 4.56523 20.7158C6.62738 22.7779 9.42327 23.938 12.3396 23.9414ZM12.3396 3.94141C14.1196 3.94141 15.8597 4.46925 17.3397 5.45819C18.8198 6.44712 19.9733 7.85273 20.6545 9.49726C21.3357 11.1418 21.5139 12.9514 21.1667 14.6972C20.8194 16.4431 19.9622 18.0467 18.7036 19.3054C17.4449 20.564 15.8412 21.4212 14.0954 21.7685C12.3496 22.1157 10.54 21.9375 8.89545 21.2563C7.25091 20.5751 5.84531 19.4216 4.85637 17.9415C3.86744 16.4615 3.3396 14.7214 3.3396 12.9414C3.34225 10.5553 4.29131 8.26763 5.97856 6.58037C7.66582 4.89312 9.95346 3.94406 12.3396 3.94141Z"
                                                    fill="#909090"
                                                  />
                                                  <path
                                                    d="M8.63239 16.6483C8.81991 16.8358 9.07422 16.9411 9.33939 16.9411C9.60455 16.9411 9.85886 16.8358 10.0464 16.6483L12.3394 14.3553L14.6324 16.6483C14.821 16.8305 15.0736 16.9313 15.3358 16.929C15.598 16.9267 15.8488 16.8216 16.0342 16.6361C16.2196 16.4507 16.3248 16.1999 16.3271 15.9377C16.3293 15.6755 16.2285 15.4229 16.0464 15.2343L13.7534 12.9413L16.0464 10.6483C16.2285 10.4597 16.3293 10.2071 16.3271 9.94492C16.3248 9.68273 16.2196 9.43191 16.0342 9.24651C15.8488 9.0611 15.598 8.95593 15.3358 8.95365C15.0736 8.95137 14.821 9.05217 14.6324 9.23433L12.3394 11.5273L10.0464 9.23433C9.85778 9.05217 9.60518 8.95137 9.34299 8.95365C9.08079 8.95593 8.82998 9.0611 8.64457 9.24651C8.45916 9.43191 8.35399 9.68273 8.35171 9.94492C8.34943 10.2071 8.45023 10.4597 8.63239 10.6483L10.9254 12.9413L8.63239 15.2343C8.44492 15.4219 8.3396 15.6762 8.3396 15.9413C8.3396 16.2065 8.44492 16.4608 8.63239 16.6483Z"
                                                    fill="#909090"
                                                  />
                                                </svg>
                                              </div>
                                            </>
                                          ) : (
                                            <Spin indicator={antIcon2} />
                                          )}
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr>
                                        <td> {e.name} </td>
                                        <td> {e.address}</td>
                                        <td
                                          className="totaldevices"
                                          id="t-devices"
                                          onClick={() => {
                                            props.HandleOpneDevices();
                                          }}
                                        >
                                          <a
                                            onClick={() =>
                                              navigate("/device/dashboard", {
                                                state: { AreaId: e.id },
                                              })
                                            }
                                          >
                                            {props.deviceData.reduce(
                                              (acc: any, curr: any) =>
                                                curr === e.id ? acc + 1 : acc,
                                              0
                                            )}{" "}
                                            Devices
                                          </a>
                                          {/* <Link to="/device/dashboard">
                                        {props.deviceData.reduce((acc:any, curr:any) => curr === e.id ? acc + 1 : acc, 0)}
                                           {" "}Devices
                                        </Link> */}

                                          {/* {props.openDevicesList ? (
                                <ul className="td-dropDown">
                                  <li>
                                    <div className="topDevice_li">
                                      <div className="dev__top">
                                        <p> Devices</p>
                                        <span>210 Devices</span>
                                      </div>
                                      <div className="d-icon">
                                        <svg
                                          width="21"
                                          height="21"
                                          viewBox="0 0 21 21"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M17.1653 14.4073L10.3992 7.64123L3.63307 14.4073C3.49575 14.54 3.31183 14.6133 3.12093 14.6117C2.93003 14.61 2.74741 14.5335 2.61242 14.3985C2.47743 14.2635 2.40086 14.0809 2.3992 13.89C2.39754 13.6991 2.47093 13.5151 2.60355 13.3778L9.88441 6.09696C10.0209 5.96047 10.2061 5.88379 10.3992 5.88379C10.5922 5.88379 10.7774 5.96047 10.9139 6.09696L18.1948 13.3778C18.3274 13.5151 18.4008 13.6991 18.3991 13.89C18.3975 14.0809 18.3209 14.2635 18.1859 14.3985C18.0509 14.5335 17.8683 14.61 17.6774 14.6117C17.4865 14.6133 17.3026 14.54 17.1653 14.4073Z"
                                            fill="#B9BBBD"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <p> Device 1</p>
                                    <span>IMEI 25-999990-176148-2</span>
                                  </li>

                                  <a href="javascript:;" className="loadMore">
                                    Load More
                                    <svg
                                      width="15"
                                      height="15"
                                      viewBox="0 0 15 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.44726 9.94152C7.31248 9.94034 7.18343 9.88686 7.08733 9.79235L1.99642 4.70145C1.90369 4.60543 1.85237 4.47683 1.85353 4.34335C1.85469 4.20987 1.90824 4.08218 2.00262 3.98779C2.09701 3.8934 2.2247 3.83986 2.35818 3.8387C2.49166 3.83754 2.62026 3.88886 2.71628 3.98159L7.44726 8.71257L12.1782 3.98159C12.2252 3.93297 12.2814 3.89418 12.3435 3.8675C12.4056 3.84082 12.4724 3.82678 12.54 3.82619C12.6076 3.8256 12.6746 3.83848 12.7372 3.86408C12.7998 3.88968 12.8566 3.92748 12.9044 3.97528C12.9522 4.02308 12.99 4.07992 13.0156 4.14249C13.0412 4.20505 13.0541 4.27209 13.0535 4.33969C13.0529 4.40728 13.0389 4.47409 13.0122 4.5362C12.9855 4.59831 12.9467 4.65448 12.8981 4.70145L7.80719 9.79235C7.71109 9.88686 7.58204 9.94034 7.44726 9.94152Z"
                                        fill="#B6B6B6"
                                      />
                                    </svg>
                                  </a>
                                </ul>
                              ) : (
                                <></>
                              )} */}
                                        </td>
                                        <td className="flex">
                                          <div
                                            className="edit cursor-pointer "
                                            onClick={() => {
                                              props.handleAddDevices(
                                                e.name,
                                                e.address,
                                                e.id,
                                                index
                                              );
                                            }}
                                            style={{ marginLeft: "7px" }}
                                          >
                                            <svg
                                              width="37"
                                              height="37"
                                              viewBox="0 0 37 37"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M12.4393 25.9527H15.2393C15.3314 25.9532 15.4227 25.9356 15.508 25.9007C15.5933 25.8659 15.6709 25.8146 15.7363 25.7497L24.1363 17.3497C24.2019 17.2846 24.254 17.2072 24.2895 17.1219C24.325 17.0366 24.3433 16.9451 24.3433 16.8527C24.3433 16.7603 24.325 16.6688 24.2895 16.5835C24.254 16.4982 24.2019 16.4208 24.1363 16.3557L21.3363 13.5557C21.2712 13.4901 21.1938 13.438 21.1085 13.4025C21.0232 13.3669 20.9317 13.3486 20.8393 13.3486C20.7469 13.3486 20.6554 13.3669 20.5701 13.4025C20.4848 13.438 20.4073 13.4901 20.3423 13.5557L11.9423 21.9557C11.8774 22.0211 11.8261 22.0987 11.7912 22.184C11.7564 22.2692 11.7387 22.3606 11.7393 22.4527V25.2527C11.7393 25.4383 11.813 25.6164 11.9443 25.7477C12.0756 25.8789 12.2536 25.9527 12.4393 25.9527ZM13.1393 22.7397L20.8393 15.0397L22.6523 16.8527L14.9523 24.5527H13.1393V22.7397Z"
                                                fill="#A3A3A3"
                                              />
                                              <path
                                                d="M22.7365 12.1551C22.6712 12.0898 22.5937 12.038 22.5085 12.0027C22.4232 11.9674 22.3318 11.9492 22.2395 11.9492C22.0531 11.9492 21.8743 12.0233 21.7425 12.1551C21.6107 12.2869 21.5366 12.4657 21.5366 12.6521C21.5366 12.8385 21.6107 13.0173 21.7425 13.1491L24.5425 15.9491C24.6076 16.0147 24.685 16.0668 24.7703 16.1023C24.8556 16.1378 24.9471 16.1561 25.0395 16.1561C25.1319 16.1561 25.2234 16.1378 25.3087 16.1023C25.394 16.0668 25.4714 16.0147 25.5365 15.9491C25.6021 15.884 25.6542 15.8066 25.6897 15.7213C25.7252 15.636 25.7435 15.5445 25.7435 15.4521C25.7435 15.3597 25.7252 15.2682 25.6897 15.1829C25.6542 15.0976 25.6021 15.0202 25.5365 14.9551L22.7365 12.1551Z"
                                                fill="#A3A3A3"
                                              />
                                              <rect
                                                x="1.16277"
                                                y="1.37664"
                                                width="35.153"
                                                height="35.153"
                                                rx="10.3765"
                                                stroke="#ECEAF3"
                                                stroke-width="0.847025"
                                              />
                                            </svg>
                                          </div>
                                          {/* <div className="delete cursor-pointer">
                                          <svg
                                            width="37"
                                            height="37"
                                            viewBox="0 0 37 37"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M13.0403 14.6492V23.0492C13.0403 23.6062 13.2616 24.1403 13.6554 24.5341C14.0492 24.928 14.5834 25.1492 15.1403 25.1492H22.1403C22.6973 25.1492 23.2314 24.928 23.6253 24.5341C24.0191 24.1403 24.2403 23.6062 24.2403 23.0492V14.6492C24.426 14.6492 24.604 14.5755 24.7353 14.4442C24.8666 14.3129 24.9403 14.1349 24.9403 13.9492C24.9403 13.7636 24.8666 13.5855 24.7353 13.4542C24.604 13.323 24.426 13.2492 24.2403 13.2492H21.4403V12.5492C21.4403 11.9923 21.2191 11.4581 20.8253 11.0643C20.4314 10.6705 19.8973 10.4492 19.3403 10.4492H17.9403C17.3834 10.4492 16.8492 10.6705 16.4554 11.0643C16.0616 11.4581 15.8403 11.9923 15.8403 12.5492V13.2492H13.0403C12.8547 13.2492 12.6766 13.323 12.5454 13.4542C12.4141 13.5855 12.3403 13.7636 12.3403 13.9492C12.3403 14.1349 12.4141 14.3129 12.5454 14.4442C12.6766 14.5755 12.8547 14.6492 13.0403 14.6492ZM17.2403 12.5492C17.2403 12.3636 17.3141 12.1855 17.4454 12.0542C17.5766 11.923 17.7547 11.8492 17.9403 11.8492H19.3403C19.526 11.8492 19.704 11.923 19.8353 12.0542C19.9666 12.1855 20.0403 12.3636 20.0403 12.5492V13.2492H17.2403V12.5492ZM14.4403 14.6492H22.8403V23.0492C22.8403 23.2349 22.7666 23.4129 22.6353 23.5442C22.504 23.6755 22.326 23.7492 22.1403 23.7492H15.1403C14.9547 23.7492 14.7766 23.6755 14.6454 23.5442C14.5141 23.4129 14.4403 23.2349 14.4403 23.0492V14.6492Z"
                                              fill="#A3A3A3"
                                            />
                                            <path
                                              d="M16.5398 22.3508C16.7255 22.3508 16.9035 22.277 17.0348 22.1458C17.1661 22.0145 17.2398 21.8364 17.2398 21.6508V16.7508C17.2398 16.5651 17.1661 16.3871 17.0348 16.2558C16.9035 16.1245 16.7255 16.0508 16.5398 16.0508C16.3542 16.0508 16.1761 16.1245 16.0449 16.2558C15.9136 16.3871 15.8398 16.5651 15.8398 16.7508V21.6508C15.8398 21.8364 15.9136 22.0145 16.0449 22.1458C16.1761 22.277 16.3542 22.3508 16.5398 22.3508Z"
                                              fill="#A3A3A3"
                                            />
                                            <path
                                              d="M20.74 22.3508C20.9257 22.3508 21.1037 22.277 21.235 22.1458C21.3663 22.0145 21.44 21.8364 21.44 21.6508V16.7508C21.44 16.5651 21.3663 16.3871 21.235 16.2558C21.1037 16.1245 20.9257 16.0508 20.74 16.0508C20.5544 16.0508 20.3763 16.1245 20.2451 16.2558C20.1138 16.3871 20.04 16.5651 20.04 16.7508V21.6508C20.04 21.8364 20.1138 22.0145 20.2451 22.1458C20.3763 22.277 20.5544 22.3508 20.74 22.3508Z"
                                              fill="#A3A3A3"
                                            />
                                            <rect
                                              x="1.06365"
                                              y="0.573903"
                                              width="35.153"
                                              height="35.153"
                                              rx="10.3765"
                                              stroke="#ECEAF3"
                                              stroke-width="0.847025"
                                            />
                                          </svg>
                                        </div> */}
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                );
                              })}
                        </>
                      ) : (
                        <div
                          style={{
                            position: "absolute",
                            textAlign: "center",
                            marginTop: "12px",
                            float: "left",
                            width: "100%",
                          }}
                        >
                          No Record Found
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className=" pagingation">
                  <div className="pagination-buttons">
                    <button
                      onClick={props.decrementPage}
                      disabled={props.currentPage === 1}
                      style={{ cursor: "pointer" }}
                      className="pageNationBtn"
                    >
                      <LeftOutlined />
                    </button>
                    <div className="pagnum">
                      {props.pageNumbersToShow.map((number) => (
                        <div
                          key={number}
                          onClick={() => props.setCurrentPage(number)}
                          style={{
                            background:
                              props.currentPage === number
                                ? "rgba(48, 78, 117, 0.15)"
                                : "",
                            color:
                              props.currentPage === number ? "#304e75" : "",
                          }}
                        >
                          {number}
                        </div>
                      ))}
                    </div>
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={props.incrementPage}
                      disabled={props.currentPage === props.pageNumbers.length}
                      className="pageNationBtn"
                    >
                      <RightOutlined />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Spin indicator={antIcon} />
        )}
      </div>
      {props.openCreateChannel ? (
        <CreateChannelScreen
          handleCrossSign={props.HandleCrossSign}
          HandleSuccessSign={props.HandleSuccessSign}
          token={props.token}
        />
      ) : (
        <></>
      )}
      {props.openEditChannel ? (
        <EditChannelScreen
          token={props.token}
          handleCrossSign={props.HandleCrossSign}
          HandleSuccessSign={props.HandleSuccessSign}
          selectedChannel={props.selectedChannel}
        />
      ) : (
        <></>
      )}

      {props.authModal ? (
        <AuthenticationModal
          setAuthKey={props.setAuthKey}
          token={props.token}
          authKey={props.authKey}
          setAuthModal={props.setAuthModal}
          channelId={props.selectedChannel.id}
          channelDateRequest={props.channelDateRequest}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default ChannelDashboard;
