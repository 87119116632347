import React, { useContext, useEffect, useState } from "react";
import CampaignReport from "../Components/CampaignReport";
import { fetchGetPWKey } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import Swal from "sweetalert2";
import UserContext from "../Utils/User_Context";

type Props = {
  token: any;
};

function CampaignReportScreen(props: Props) {
  const [clickedElement, setClickedElement] = useState<number>(-1);
  const [brandData, setBrandData] = useState<any>([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [tempData, setTempData] = useState<any>([]);
  const [reverseList, setReverseList] = useState(true);
  const [campaignStatsData, setCampaignStatsData] = useState<any>("");
  const [fromdate, setFromdate] = useState<any>("");
  const [todate, setTodate] = useState<any>("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [showTalbleView, setShowTalbleView] = useState(false);
  const { onHandleAdList, adMainList, isAdmin } = useContext(UserContext);

  let id = localStorage.getItem("id");

  useEffect(() => {
    if (props.token) {
      brandDateRequest();
    }
  }, [props.token]);

  const brandDateRequest = async () => {
    setIsLoader(true);
    let endPoint;
    if (!isAdmin) {
      endPoint = `${ApiNames.brandList}?channelId=${id}`;
    } else {
      endPoint = ApiNames.brandList;
    }

    try {
      const result = await fetchGetPWKey(endPoint, props.token.jwttoken);
      if (result.status === 0) {
        setIsLoader(false);
        let temp = result.response;
        setBrandData(temp.reverse());
        setSelectedBrand(temp[clickedElement]);
      } else {
        setIsLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.response,
        });
      }
    } catch (error) {
      setIsLoader(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };

  const sortHandler = () => {
    if (brandData) {
      if (reverseList === true) {
        setReverseList(false);
        let temp = [...brandData];
        temp?.sort((a: any, b: any) => a.name.localeCompare(b.name));
        setClickedElement(-1);
        setTempData(temp);
      } else {
        let temp = [...tempData];

        temp?.reverse();
        setClickedElement(-1);
        setTempData(temp);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No Data Found..!",
      });
    }
  };
  const recentHandler = () => {
    setTempData([]);
    if (brandData) {
      setReverseList(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No Data Found..!",
      });
    }
  };
  const handleDropDown = () => {
    if (openDropdown === true) {
      setOpenDropdown(false);
    } else {
      setOpenDropdown(true);
    }
  };

  const campaignStats = async (ApiName: any) => {
    let endPoint;
    if (!isAdmin) {
      endPoint = `${ApiName}&channelId=${id}`;
    } else {
      endPoint = ApiName;
    }
    try {
      const result = await fetchGetPWKey(endPoint, props.token.jwttoken);
      if (result.status === 0) {
        console.log("campaignStats==>", result.response);
        setCampaignStatsData(result.response);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
      console.log(error);
    }
  };

  const onDateHandler = (FDate: any, TDate: any) => {
    if (props.token) {
      if (campaignStatsData && clickedElement !== -1) {
        console.log("In initial with id");
        const ApiName =
          ApiNames.statsCampaign +
          "?fromDate=" +
          FDate +
          "&toDate=" +
          TDate +
          "&brandId=" +
          brandData[clickedElement]?.id;
        campaignStats(ApiName);
      } else {
        console.log("In initial");
        campaignStats(
          ApiNames.statsCampaign +
            "?fromDate=" +
            FDate +
            "&toDate=" +
            TDate +
            "&brandId="
        );
      }
      setFromdate(FDate);
      setTodate(TDate);
    }
  };

  const onClickedElement = (index: number) => {
    setClickedElement(index);
    setSelectedBrand(brandData[index]);

    if (index === -1) {
      const ApiName =
        ApiNames.statsCampaign +
        "?fromDate=" +
        fromdate +
        "&toDate=" +
        todate +
        "&brandId=";
      campaignStats(ApiName);
    } else {
      const ApiName =
        ApiNames.statsCampaign +
        "?fromDate=" +
        fromdate +
        "&toDate=" +
        todate +
        "&brandId=" +
        brandData[index].id;
      campaignStats(ApiName);
    }
  };

  return (
    <>
      <CampaignReport
        brandData={brandData}
        clickedElement={clickedElement}
        sortHandler={sortHandler}
        recentHandler={recentHandler}
        handleDropDown={handleDropDown}
        openDropdown={openDropdown}
        reverseList={reverseList}
        tempData={tempData}
        isLoader={isLoader}
        onClickedElement={onClickedElement}
        onDateHandler={onDateHandler}
        selectedBrand={selectedBrand}
        campaignStatsData={campaignStatsData}
        setShowTalbleView={setShowTalbleView}
        showTalbleView={showTalbleView}
      />
    </>
  );
}

export default CampaignReportScreen;
