import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../StyleSheets/CreateAddStyle.css";
import channelImg from "../Assets/channel.png";
import campaignImg from "../Assets/campaign.png";
import brandImg from "../Assets/brand.png";
import dolarimg from "../Assets/dolarImg.png";
import sadIcone from "../Assets/sadIcon.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import AddTimeSlot from "./AddTimeSlot";
import quest from "../Assets/image_2023_05_12T07_03_55_844Z.png";
import AddTimeSlot2 from "./AddTimeSlot2";

interface props {
  handleOptionChange: (option: any) => void;
  handleSubOptionChange: (otion: any) => void;
  handleBrandOptionChange: (otion: any) => void;
  handleCategoriesOptions: (otion: any) => void;
  handleTenureOptions: (otion: any) => void;
  handleFormikValues: (title: string, amount: string, date: any) => void;
  handleFile: (e: any) => void;
  selectedOption: any;
  selectedSubOption: any;
  PlacesData: string[];
  options: object[];
  imgData: any;
  selectedBrandOption: any;
  brandOptions: any;
  channelOptions: any;
  channelSubOptions: any;
  categoriesOptions: any;
  tenureOptions: any;
  selectedTenureOptions: any;
  selectedCategoriesOptions: any;
  imgVal: string;
  imgUpload: any;
  isloader: boolean;

  erroramount: boolean;
  errorareaId: boolean;
  errorbannerBase64: boolean;
  errorbrandId: boolean;
  errorcategory: boolean;
  errorstartDate: boolean;
  errortenure: boolean;
  errortitle: boolean;
  errorlandscapeBannerBase64: boolean;

  setErroramount: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorareaId: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorbannerBase64: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorbrandId: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorcategory: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorstartDate: React.Dispatch<React.SetStateAction<boolean>>;
  setErrortenure: React.Dispatch<React.SetStateAction<boolean>>;
  setErrortitle: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorlandscapeBannerBase64: React.Dispatch<React.SetStateAction<boolean>>;
  handleFile2: (e: any) => void;
  imgUpload2: any;
  imgVal2: string;
  setOpenTimeSlot: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenTimeSlot2: React.Dispatch<React.SetStateAction<boolean>>;
  openTimeSlot: boolean;
  openTimeSlot2: boolean;
  state: any;
  hideBrandInput: boolean;
}

interface FormValues {
  title: string;
  Amount: string;
  date: any;
}

function CreateAdd(props: props) {
  const validate = Yup.object({
    title: Yup.string().required("Please Enter Title"),
    Amount: Yup.string().required("Please Enter Charges"),
    date: Yup.string().required("Please Enter Date-Time"),
  });
  const formik = useFormik({
    initialValues: {
      title: "",
      Amount: "",
      date: new Date(),
    },

    onSubmit: (values: FormValues) => {
      props.handleFormikValues(values.title, values.Amount, values.date);
    },
  });
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "30px",
      }}
    />
  );

  return (
    <>
      <div className="d-top">
        <div className="d-row">
          <div className="cstm-col-md-6">
            <div className="d-top-left">
              <div className="channelBox">
                <img src={channelImg} alt="" />
                <div className="innerBox">
                  <p className="text">Channel</p>
                  <span>Locations - Devices</span>
                </div>
              </div>
              <div className="channelBox">
                <img src={brandImg} alt="" />
                <div className="innerBox">
                  <p className="text">Brand & Ads</p>
                </div>
              </div>
              <div className="channelBox">
                <img src={campaignImg} alt="" />
                <div className="innerBox">
                  <p className="text">Campaign</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cstm-col-md-6">
            <div className="d-top-right">
              {props.isloader === false ? (
                <>
                  <div className="channelBtn" onClick={formik.submitForm}>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.7797 16.1374H12.9464V24.304C12.9464 24.6135 13.0693 24.9102 13.2881 25.129C13.5069 25.3478 13.8036 25.4707 14.113 25.4707C14.4225 25.4707 14.7192 25.3478 14.938 25.129C15.1568 24.9102 15.2797 24.6135 15.2797 24.304V16.1374H23.4464C23.7558 16.1374 24.0525 16.0145 24.2713 15.7957C24.4901 15.5769 24.613 15.2801 24.613 14.9707C24.613 14.6613 24.4901 14.3645 24.2713 14.1457C24.0525 13.927 23.7558 13.804 23.4464 13.804H15.2797V5.63737C15.2797 5.32795 15.1568 5.0312 14.938 4.81241C14.7192 4.59362 14.4225 4.4707 14.113 4.4707C13.8036 4.4707 13.5069 4.59362 13.2881 4.81241C13.0693 5.0312 12.9464 5.32795 12.9464 5.63737V13.804H4.7797C4.47028 13.804 4.17354 13.927 3.95475 14.1457C3.73595 14.3645 3.61304 14.6613 3.61304 14.9707C3.61304 15.2801 3.73595 15.5769 3.95475 15.7957C4.17354 16.0145 4.47028 16.1374 4.7797 16.1374Z"
                        fill="white"
                      />
                    </svg>
                    Create
                  </div>

                  <Link
                    to="/brand/brandandadddashboard"
                    style={{ display: "contents" }}
                  >
                    <div
                      className="channelBtn"
                      style={{ backgroundColor: "#472a2a6b" }}
                    >
                      Cancel
                    </div>
                  </Link>
                </>
              ) : (
                <Spin indicator={antIcon} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div id="page-wrapper">
        <div className="wrapper__content">
          <div className="m-row">
            <div className="custom-col-8">
              <div className="adeditWraper">
                <p className="editAdd">Create Ad</p>
                <div className="htmlForm__group">
                  <div className="flex-container">
                    <div className="flex-item">
                      <div className="InputGroup">
                        <div className="selectwrapper">
                          {props.hideBrandInput ? (
                            <></>
                          ) : (
                            <>
                              <div className="Ad-From-Group">
                                <label className="Ad-Add-Lable">Brand</label>
                                <Select
                                  className="select"
                                  options={props.brandOptions}
                                  value={
                                    props.state
                                      ? props.state
                                      : props.selectedBrandOption
                                  }
                                  onChange={(e) => {
                                    props.handleBrandOptionChange(e);
                                    props.setErrorbrandId(false);
                                  }}
                                />
                                {props.errorbrandId ? (
                                  <p className="error">Please Select Brand</p>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="InputGroup">
                        <div className="Ad-From-Group">
                          <label className="Ad-Add-Lable">Title </label>
                          <input
                            type="text"
                            name="title"
                            onChange={(e) => {
                              formik.handleChange(e);
                              props.setErrortitle(false);
                            }}
                            value={formik.values.title}
                            placeholder="Title..."
                          />
                          {props.errortitle ? (
                            <p className="error">Please Enter Title</p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="InputGroup">
                        <div className="selectwrapper">
                          <div className="Ad-From-Group">
                            <label className="Ad-Add-Lable">
                              Channel/Segment
                            </label>
                            <Select
                              className="adOptionContainer"
                              options={props.channelOptions}
                              value={props.selectedOption}
                              onChange={(e) => {
                                props.handleOptionChange(e);
                              }}
                            />

                            {props.selectedOption && (
                              <>
                                <label className="Ad-Add-Lable">
                                  Channel Area
                                </label>
                                <Select
                                  className="adOptionContainer"
                                  options={props.channelSubOptions}
                                  value={props.selectedSubOption}
                                  onChange={(e) => {
                                    props.handleSubOptionChange(e);
                                    props.setErrorareaId(false);
                                  }}
                                />
                              </>
                            )}
                            {props.errorareaId ? (
                              <p className="error">
                                Please Select Channel and Area
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-item">
                      <div className="InputGroup">
                        <div className="selectwrapper">
                          <div className="Ad-From-Group">
                            <label className="Ad-Add-Lable">
                              Category{" "}
                              <img
                                src={quest}
                                alt=""
                                className="quest_img"
                                onClick={() => {
                                  props.setOpenTimeSlot(true);
                                }}
                                style={{ cursor: "pointer" }}
                              ></img>
                            </label>
                            <Select
                              className="adOptionContainer"
                              options={props.categoriesOptions}
                              value={props.selectedCategoriesOptions}
                              onChange={(e) => {
                                props.handleCategoriesOptions(e);
                                props.setErrorcategory(false);
                              }}
                            />
                            {props.errorcategory ? (
                              <p className="error">Please Select Category</p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="InputGroup">
                        <div className="selectwrapper">
                          <div className="Ad-From-Group">
                            <label className="Ad-Add-Lable">
                              Tenure{" "}
                              <img
                                src={quest}
                                alt=""
                                className="quest_img"
                                onClick={() => {
                                  props.setOpenTimeSlot2(true);
                                }}
                                style={{ cursor: "pointer" }}
                              ></img>
                            </label>
                            <Select
                              className="adOptionContainer"
                              options={props.tenureOptions}
                              value={props.selectedTenureOptions}
                              onChange={(e) => {
                                props.handleTenureOptions(e);
                                props.setErrortenure(false);
                              }}
                            />
                          </div>
                          {props.errortenure ? (
                            <p className="error">Please Select Tenure</p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      <div className="InputGroup">
                        <div className="Ad-From-Group">
                          <label className="Ad-Add-Lable">Start Date</label>
                          <DatePicker
                            selected={formik.values.date}
                            className="form-control flatpickrDateTimeInput Ad-Form-Control input css-13cymwt-control"
                            onChange={(date: any) =>
                              formik.setFieldValue("date", date)
                            }
                            showTimeInput={false}
                            dateFormat="yyyy-MMMM-d"
                            minDate={new Date()}
                          />
                          {props.errorstartDate ? (
                            <p className="error">Please Select Date</p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="InputGroup">
                        <div className="Ad-From-Group">
                          <label className="Ad-Add-Lable">
                            Amount to Charge
                          </label>
                          <div className="inutField">
                            <input
                              type="number"
                              name="Amount"
                              className="Ad-Form-Control"
                              onChange={(e) => {
                                formik.handleChange(e);
                                props.setErroramount(false);
                              }}
                              value={formik.values.Amount}
                            />

                            <img src={dolarimg} alt="" className="dolarimg" />
                          </div>
                          {props.erroramount ? (
                            <p className="error">Please Add Amount</p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {/* <div className="InputGroup">
                        <label htmlFor="" className="tS-label">
                          Time Slots
                        </label>
                        <div className="timeSlot">
                          <table className="ts-table">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Am</th>
                                <th>Pm</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                className={
                                  props.selectedCategoriesOptions.value ===
                                  "SILVER"
                                    ? "timeSlotStyle"
                                    : "timeSlotClearStyle"
                                }
                              >
                                <td>Silver</td>
                                <td>1-5</td>
                                <td>11-12</td>
                              </tr>
                              <tr
                                className={
                                  props.selectedCategoriesOptions.value ===
                                  "PLATINUM"
                                    ? "timeSlotStyle"
                                    : "timeSlotClearStyle"
                                }
                              >
                                <td>Platinum</td>
                                <td>6-10</td>
                                <td>3-6</td>
                              </tr>
                              <tr
                                className={
                                  props.selectedCategoriesOptions.value ===
                                  "GOLD"
                                    ? "timeSlotStyle"
                                    : "timeSlotClearStyle"
                                }
                              >
                                <td>Gold</td>
                                <td>11-12</td>
                                <td>1-2 | 7-10</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div> */}
                    </div>

                    <div className="flex-item">
                      <p className="mb">
                        <img src={sadIcone} alt="" />
                        Ad Landscape Image
                      </p>
                      <form
                        action="#"
                        className="dropzone"
                        id="dropzonehtmlForm"
                      >
                        <div className="fallback">
                          <input
                            className="Ad-Form-Control"
                            type="file"
                            accept=".webp"
                            value={props.imgVal}
                            onChange={(e) => {
                              props.handleFile(e);
                              props.setErrorbannerBase64(false);
                            }}
                          />
                          {props.imgUpload ? (
                            <img src={props.imgUpload} alt="" />
                          ) : (
                            <></>
                          )}
                        </div>
                      </form>
                      {props.errorbannerBase64 ? (
                        <p className="error">Please Select Landscape Image</p>
                      ) : (
                        <></>
                      )}

                      <>
                        <p className="mb">
                          <img src={sadIcone} alt="" />
                          Ad Portrait Image
                        </p>
                        <form
                          action="#"
                          className="dropzone"
                          id="dropzonehtmlForm"
                        >
                          <div className="fallback">
                            <input
                              className="Ad-Form-Control"
                              type="file"
                              accept=".webp"
                              value={props.imgVal2}
                              onChange={(e) => {
                                props.handleFile2(e);
                                props.setErrorlandscapeBannerBase64(false);
                              }}
                            />
                            {props.imgUpload2 ? (
                              <img src={props.imgUpload2} alt="" />
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                        {props.errorlandscapeBannerBase64 ? (
                          <p className="error">Please Select Portrait Image</p>
                        ) : (
                          <></>
                        )}
                      </>
                    </div>
                  </div>
                </div>
                <form onSubmit={formik.handleSubmit} className="Ad-Form m-row">
                  {/* <div className="custom-col-4">
                 
                  </div> */}

                  {/* <div className="custom-col-4">
                    <div className="Ad-From-Group">
                      <label
                        className="Ad-Add-Lable"
                        style={{ fontWeight: "bolder", fontSize: "14px" }}
                      >
                        QR Information :
                      </label>
                    </div>
                    <div className="Ad-From-Group"> */}
                  {/* <label className="Ad-Add-Lable">QR Information </label> */}
                  {/* </div> */}

                  {/* Note */}
                  {/* <div className="Ad-From-Group">
                      <label
                        htmlFor="smsText"
                        className="Ad-Add-Lable"
                        style={{ fontWeight: "bolder", fontSize: "14px" }}
                      >
                        Note*
                      </label>
                      <div>
                        Hours for {props.selectedCategoriesOptions.value}
                      </div>
                      <div className="table">
                        <table>
                          <thead>
                            <tr>
                              <th
                                colSpan={1}
                                rowSpan={1}
                                className="text-center"
                              >
                                AM
                              </th>
                              <th
                                colSpan={1}
                                rowSpan={1}
                                className="text-center"
                              >
                                PM
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.selectedCategoriesOptions.value ===
                            "PLATINUM" ? (
                              <>
                                {data.Platinum?.map(
                                  (data: any, index: number) => (
                                    <tr className="odd">
                                      <td className="text-center">{data.AM}</td>

                                      <td className="text-center">{data.PM}</td>
                                    </tr>
                                  )
                                )}
                              </>
                            ) : props.selectedCategoriesOptions.value ===
                              "GOLD" ? (
                              <>
                                {data.Gold?.map((data: any, index: number) => (
                                  <tr className="odd">
                                    <td className="text-center">{data.AM}</td>

                                    <td className="text-center">{data.PM}</td>
                                  </tr>
                                ))}
                              </>
                            ) : props.selectedCategoriesOptions.value ===
                              "SILVER" ? (
                              <>
                                {data.Silver?.map(
                                  (data: any, index: number) => (
                                    <tr className="odd">
                                      <td className="text-center">{data.AM}</td>

                                      <td className="text-center">{data.PM}</td>
                                    </tr>
                                  )
                                )}
                              </>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </div> */}
                  {/* Note */}
                  {/* {formvalues && props.selectedOption&&props.selectedSubOption&&props.imgData? */}
                  {/* <button type="submit" className="Ad-Btn">
                    SUBMIT
                  </button> */}
                  {/* :
                  <div className="Ad-Btn" style={{textAlign:"center",opacity:0.6}}>SUBMIT</div>} */}
                  {/* </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* <div id="custom-modal">
          <div id="m-modal-content">
            <div className="modalTop">
              <h2>Create Channel</h2>
              <span className="close">&times;</span>
            </div>

            <div className="htmlForminput newBrand">
              <div className="inputGroup">
                <label htmlFor="channelname">Title</label>
                <div className="innerInput">
                  <input type="password" placeholder="Tesla" />
                </div>
                <form action="#" className="dropzone" id="dropzonehtmlForm">
                  <div className="fallback">
                    <input name="file" type="file" multiple />
                  </div>
                </form>
              </div>
            </div>

            <div className="modalBtn">
              <button className="cancelbtn">cancel</button>
              <button className="createbtn">Create</button>
            </div>
          </div>
        </div> */}

        {props.openTimeSlot ? (
          <AddTimeSlot setOpenTimeSlot={props.setOpenTimeSlot} />
        ) : (
          <></>
        )}
        {props.openTimeSlot2 ? (
          <AddTimeSlot2 setOpenTimeSlot={props.setOpenTimeSlot2} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default CreateAdd;
