import React from "react";
import { Link, useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import channelImg from "../Assets/channel.png";
import campaignImg from "../Assets/campaign.png";
import brandImg from "../Assets/brand.png";
import brandDot from "../Assets/brandsdots.png";
import addBrandImg from "../Assets/addBrand.png";
import pencilImg from "../Assets/pencil.png";
import atozImg from "../Assets/atoz.png";
import menuImg from "../Assets/menu-bar.png";
import recentImg from "../Assets/recent.png";
import { LeftOutlined, RightOutlined, PauseOutlined } from "@ant-design/icons";
import CreateBrandScreen from "../Screens/CreateBrandScreen";
import EditBrandScreen from "../Screens/EditBrandScreen";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import push from "../Assets/p-v-icon.png";

import play from "../Assets/v-icon.png";

import "../StyleSheets/BrandAndDashboardScreen.css";

type Props = {
  createBrand: boolean;
  setCreateBrand: React.Dispatch<React.SetStateAction<boolean>>;
  CancellBtn: () => void;
  successBtnHandler: () => void;
  onAllPressed: () => void;
  HandleOpenBrandMenu: (index: any) => void;
  openBrandDropdown: boolean;
  openEditBrand: boolean;
  selectedPushPlayIndex: number;
  setOpenEditBrand: React.Dispatch<React.SetStateAction<boolean>>;
  brandData: any;
  sideMenuIndex: number;
  filteredData: any;
  mainList: any;
  nodataFound: boolean;
  token: any;
  handelSelectedBrand: (value: any) => void;
  seletedBrand: object;
  onListItemPressed: (id: number, data: string) => void;
  date: string;
  incrementPage: () => void;
  decrementPage: () => void;
  pageNumbersToShow: any[];
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  pageNumbers: number[];
  currentPage: number;
  currentData: any;
  clickedElement: number;
  setClickedElement: React.Dispatch<React.SetStateAction<number>>;
  sortHandler: () => void;
  recentHandler: () => void;
  handleDropDown: () => void;
  openDropdown: boolean;
  searchQuery: string;
  handleSearchQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchData: any;
  searchQuery2: string;
  loader: boolean;
  handleSearchQueryChange2: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  searchData2: any;
  incrementPage2: () => void;
  decrementPage2: () => void;
  pageNumbersToShow2: any[];
  setCurrentPage2: React.Dispatch<React.SetStateAction<number>>;
  pageNumbers2: number[];
  currentPage2: number;
  currentData2: any;
  handleValue: (e: any) => void;
  brandsDetail: any;
  reverseList: boolean;
  tempData: any;
  isloader: boolean;
  onPushHandler: (id: number, index: number) => Promise<void>;
  onPlayHandler: (id: number, index: number) => Promise<void>;
  isAdmin: boolean;
};

function BrandAndAddDashboard(props: Props) {
  const navigate = useNavigate();

  if (props.reverseList) {
    var brandDatalist = props.brandData;
  } else {
    var brandDatalist = props.tempData;
  }
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      spin
    />
  );

  return (
    <>
      <div className="d-top">
        <div className="d-row">
          <div className="cstm-col-md-6">
            <div className="d-top-left">
              <div className="channelBox">
                <img src={channelImg} alt="" />
                <div className="innerBox">
                  <p className="text">Channel</p>
                  <span>Locations - Devices</span>
                </div>
              </div>
              <div className="channelBox">
                <img src={brandImg} alt="" />
                <div className="innerBox">
                  <p className="text">Brand & Ads</p>
                </div>
              </div>
              <div className="channelBox">
                <img src={campaignImg} alt="" />
                <div className="innerBox">
                  <p className="text">Campaign</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cstm-col-md-6">
            <div className="d-top-right">
              {props.clickedElement === -1 ? (
                <a
                  onClick={() => navigate("/brand/createad")}
                  style={{ display: "contents" }}
                >
                  <div className="channelBtn cursor-pointer" onClick={() => {}}>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.7797 16.1374H12.9464V24.304C12.9464 24.6135 13.0693 24.9102 13.2881 25.129C13.5069 25.3478 13.8036 25.4707 14.113 25.4707C14.4225 25.4707 14.7192 25.3478 14.938 25.129C15.1568 24.9102 15.2797 24.6135 15.2797 24.304V16.1374H23.4464C23.7558 16.1374 24.0525 16.0145 24.2713 15.7957C24.4901 15.5769 24.613 15.2801 24.613 14.9707C24.613 14.6613 24.4901 14.3645 24.2713 14.1457C24.0525 13.927 23.7558 13.804 23.4464 13.804H15.2797V5.63737C15.2797 5.32795 15.1568 5.0312 14.938 4.81241C14.7192 4.59362 14.4225 4.4707 14.113 4.4707C13.8036 4.4707 13.5069 4.59362 13.2881 4.81241C13.0693 5.0312 12.9464 5.32795 12.9464 5.63737V13.804H4.7797C4.47028 13.804 4.17354 13.927 3.95475 14.1457C3.73595 14.3645 3.61304 14.6613 3.61304 14.9707C3.61304 15.2801 3.73595 15.5769 3.95475 15.7957C4.17354 16.0145 4.47028 16.1374 4.7797 16.1374Z"
                        fill="white"
                      />
                    </svg>
                    New Ad
                  </div>
                </a>
              ) : (
                <a
                  onClick={() =>
                    navigate("/brand/createad", {
                      state: {
                        BrandData: {
                          label: props.brandsDetail.name,
                          value: props.brandsDetail.id,
                        },
                      },
                    })
                  }
                  style={{ display: "contents" }}
                >
                  <div className="channelBtn cursor-pointer" onClick={() => {}}>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.7797 16.1374H12.9464V24.304C12.9464 24.6135 13.0693 24.9102 13.2881 25.129C13.5069 25.3478 13.8036 25.4707 14.113 25.4707C14.4225 25.4707 14.7192 25.3478 14.938 25.129C15.1568 24.9102 15.2797 24.6135 15.2797 24.304V16.1374H23.4464C23.7558 16.1374 24.0525 16.0145 24.2713 15.7957C24.4901 15.5769 24.613 15.2801 24.613 14.9707C24.613 14.6613 24.4901 14.3645 24.2713 14.1457C24.0525 13.927 23.7558 13.804 23.4464 13.804H15.2797V5.63737C15.2797 5.32795 15.1568 5.0312 14.938 4.81241C14.7192 4.59362 14.4225 4.4707 14.113 4.4707C13.8036 4.4707 13.5069 4.59362 13.2881 4.81241C13.0693 5.0312 12.9464 5.32795 12.9464 5.63737V13.804H4.7797C4.47028 13.804 4.17354 13.927 3.95475 14.1457C3.73595 14.3645 3.61304 14.6613 3.61304 14.9707C3.61304 15.2801 3.73595 15.5769 3.95475 15.7957C4.17354 16.0145 4.47028 16.1374 4.7797 16.1374Z"
                        fill="white"
                      />
                    </svg>
                    New Ad
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper__content">
        {props.isloader === false ? (
          <>
            <div className="m-row">
              <div className="custom-col-4">
                <div className="channel__left">
                  <div className="channel__top">
                    <div className="ch-head">
                      <p>Brands</p>
                      <span>{props.brandData.length} Brands</span>
                    </div>
                    <div className="recent-right">
                      <div
                        className="new-channel"
                        onClick={() => {
                          props.setCreateBrand(true);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={addBrandImg} alt="" />
                      </div>
                      <div
                        className="recent__Activity"
                        onClick={() => {
                          props.handleDropDown();
                        }}
                      >
                        <img src={menuImg} alt="" />
                        {props.openDropdown ? (
                          <>
                            <div className="recentDropDown">
                              <p className="atoz" onClick={props.sortHandler}>
                                <img src={atozImg} alt="" />
                                A-Z
                              </p>
                              <p
                                className="recent"
                                onClick={props.recentHandler}
                              >
                                <img src={recentImg} alt="" />
                                Recent
                              </p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <ul className="channer_ul b-ad-dash">
                    {props.mainList.length > 0 ? (
                      <li
                        onClick={() => {
                          props.onAllPressed();
                          props.setClickedElement(-1);
                        }}
                        style={{
                          backgroundColor:
                            props.clickedElement === -1 ? "#f9f9f9" : "",
                        }}
                      >
                        <div className="li_left">
                          <div className="letter">
                            <p>A</p>
                          </div>
                          <div className="m-loc">
                            <p>All</p>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <></>
                    )}

                    {brandDatalist.map((e: any, index: any) => {
                      return (
                        <li
                          onClick={() => {
                            props.onListItemPressed(e.id, e.dateCreated);
                            props.setClickedElement(index);
                            props.handleValue(e);
                          }}
                          style={{
                            backgroundColor:
                              props.clickedElement === index ? "#f9f9f9" : "",
                          }}
                        >
                          <div className="li_left">
                            <div className="brandImg">
                              <img
                                className="brandImgs"
                                src={e.iconUrl + "?time=" + new Date()}
                                alt=""
                              />
                            </div>
                            <div className="m-loc">
                              <p>{e.name}</p>
                              {e.status ? (
                                <span>Enabled</span>
                              ) : (
                                <span>Disabled</span>
                              )}
                            </div>
                          </div>
                          <div className="li-right" id="li__right">
                            <img
                              src={brandDot}
                              alt=""
                              onClick={() => {
                                props.HandleOpenBrandMenu(index);
                              }}
                            />

                            {props.openBrandDropdown &&
                            props.sideMenuIndex === index ? (
                              <div className="brandDropDown">
                                <p
                                  onClick={() => {
                                    props.setOpenEditBrand(true);
                                    props.handelSelectedBrand(e);
                                  }}
                                >
                                  Edit
                                  <img src={pencilImg} alt="" />
                                </p>
                                <p className="createdon">
                                  <span>Created on</span>
                                  <span>{props.date}</span>
                                </p>
                                <p
                                  onClick={() => {
                                    props.CancellBtn();
                                  }}
                                  style={{ color: "red" }}
                                >
                                  Cancel
                                </p>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>

                  <div className="alibtmdiv">
                    <div
                      className="new-channel"
                      onClick={() => {
                        props.setCreateBrand(true);
                      }}
                    >
                      <img src={addBrandImg} alt="" />
                      New Brand
                    </div>
                  </div>
                </div>
              </div>
              <div className="custom-col-brandAndAdd">
                <div style={{ marginLeft: "10px" }} className="Add-header">
                  <p className="campaign">
                    ADs
                    <span className="b-date" style={{ display: "block" }}>
                      {props.mainList.length} Ads
                    </span>
                  </p>

                  {props.date ? <p className="b-date">{props.date}</p> : null}

                  <div className="innerInput">
                    {props.clickedElement === -1 ? (
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={props.searchQuery}
                        onChange={props.handleSearchQueryChange}
                        style={{
                          width: "",
                          borderRadius: "15px",
                          outline: "none",
                        }}
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={props.searchQuery2}
                        onChange={props.handleSearchQueryChange2}
                        style={{
                          width: "",
                          borderRadius: "15px",
                          outline: "none",
                        }}
                      />
                    )}
                  </div>
                </div>

                {props.mainList.length > 0 &&
                props.filteredData.length == 0 &&
                props.nodataFound === false ? (
                  <>
                    {props.searchQuery ? (
                      <>
                        {props.searchData.map((e: any, index: number) => {
                          return (
                            <div className="brandAddWraper">
                              <div className="img-grid">
                                <div>
                                  <img
                                    className="addImg"
                                    src={e.portraitBannerUrl}
                                    alt="mobile"
                                  />
                                </div>
                                <div>
                                  <img
                                    className="addImg"
                                    src={e.landscapeBannerUrl}
                                    alt="mobile"
                                    style={{ height: "50px" }}
                                  />
                                </div>
                              </div>
                              <div className="addCol">
                                <div className="test-add">
                                  <span>Ad Title</span>
                                  <p>{e.title}</p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>Channel</span>
                                    <p>{e.channel.name}</p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Category</span>
                                    <p>{e.category}</p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>Area </span>
                                    <p>{e.areas[0].name}</p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Tenure</span>
                                    <p>{e.tenure}</p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>Start Date</span>
                                    <p
                                      style={{
                                        width: "max-content",
                                        maxWidth: "70px",
                                      }}
                                    >
                                      {e.startDate}
                                    </p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Amount</span>
                                    <p>$ {e.amount}</p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>ImpressionCount</span>
                                    <p>{e.totalImpressionCount}</p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Status</span>
                                    <div className="camp-col-btn">
                                      {!props.loader ||
                                      props.selectedPushPlayIndex != index ? (
                                        e.status === "SCHEDULED" ? (
                                          <>
                                            <button className="not-started-wbg">
                                              SCHEDULED
                                            </button>
                                            <img
                                              src={push}
                                              onClick={() =>
                                                props.onPushHandler(e.id, index)
                                              }
                                              style={{
                                                objectFit: "cover",
                                                cursor: "pointer",
                                                marginTop: "0px",
                                                width: "18px",
                                                height: "18px",
                                              }}
                                              alt=""
                                              className="v-img"
                                            />
                                          </>
                                        ) : e.status === "COMPLETED" ? (
                                          <>
                                            <button className="paid-button-wbg">
                                              {/* <img src={facheck} alt="" /> */}
                                              Completed
                                            </button>
                                          </>
                                        ) : e.status === "RUNNING" ? (
                                          <>
                                            <button className="prog-button-wbg">
                                              RUNNING
                                            </button>
                                            <img
                                              src={push}
                                              onClick={() =>
                                                props.onPushHandler(e.id, index)
                                              }
                                              alt=""
                                              className="v-img"
                                              style={{
                                                cursor: "pointer",
                                                marginTop: "0px",
                                                width: "18px",
                                                height: "18px",
                                              }}
                                            />
                                          </>
                                        ) : e.status === "PAUSED" ? (
                                          <>
                                            <button
                                              className="prog-button-wbg "
                                              style={{ color: "red" }}
                                            >
                                              PAUSED
                                            </button>
                                            <img
                                              src={play}
                                              onClick={() =>
                                                props.onPlayHandler(e.id, index)
                                              }
                                              alt=""
                                              className="v-img"
                                              style={{
                                                cursor: "pointer",
                                                marginTop: "0px",
                                                width: "18px",
                                                height: "18px",
                                              }}
                                            />
                                          </>
                                        ) : null
                                      ) : (
                                        <div
                                          className="spinner "
                                          style={{
                                            marginLeft: "90px",
                                            marginTop: "15px",
                                          }}
                                        ></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="qr-col"
                                  style={{ marginTop: "10px" }}
                                >
                                  <p style={{ paddingLeft: "12px" }}>QR Code</p>
                                  <QRCode
                                    value={`SMSTO:${e.qr.to}:${e.qr.text}`}
                                    includeMargin={true}
                                    size={70}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {props.currentData.map((e: any, index: number) => {
                          return (
                            <div className="brandAddWraper">
                              <div className="img-grid">
                                <div>
                                  <img
                                    className="addImg"
                                    src={e.portraitBannerUrl}
                                    alt="mobile"
                                  />
                                </div>
                                <div>
                                  <img
                                    className="addImg"
                                    src={e.landscapeBannerUrl}
                                    alt="mobile"
                                    style={{ height: "50px" }}
                                  />
                                </div>
                              </div>
                              <div className="addCol">
                                <div className="test-add">
                                  <span>Ad Title</span>
                                  <p>{e.title}</p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>Channel</span>
                                    <p>{e.channel.name}</p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Category</span>
                                    <p>{e.category}</p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>Area </span>
                                    <p>{e.areas[0].name}</p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Tenure</span>
                                    <p>{e.tenure}</p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>Start Date</span>
                                    <p
                                      style={{
                                        width: "max-content",
                                        maxWidth: "70px",
                                      }}
                                    >
                                      {e.startDate}
                                    </p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Amount</span>
                                    <p>$ {e.amount}</p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>ImpressionCount</span>
                                    <p>{e.totalImpressionCount}</p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Status</span>
                                    <div className="camp-col-btn">
                                      {!props.loader ||
                                      props.selectedPushPlayIndex != index ? (
                                        e.status === "SCHEDULED" ? (
                                          <>
                                            <button className="not-started-wbg">
                                              SCHEDULED
                                            </button>
                                            <img
                                              src={push}
                                              onClick={() =>
                                                props.onPushHandler(e.id, index)
                                              }
                                              style={{
                                                objectFit: "cover",
                                                cursor: "pointer",
                                                marginTop: "0px",
                                                width: "18px",
                                                height: "18px",
                                              }}
                                              alt=""
                                              className="v-img"
                                            />
                                          </>
                                        ) : e.status === "COMPLETED" ? (
                                          <>
                                            <button className="paid-button-wbg">
                                              {/* <img src={facheck} alt="" /> */}
                                              Completed
                                            </button>
                                          </>
                                        ) : e.status === "RUNNING" ? (
                                          <>
                                            <button className="prog-button-wbg">
                                              RUNNING
                                            </button>
                                            <img
                                              src={push}
                                              onClick={() =>
                                                props.onPushHandler(e.id, index)
                                              }
                                              alt=""
                                              className="v-img"
                                              style={{
                                                cursor: "pointer",
                                                marginTop: "0px",
                                                width: "18px",
                                                height: "18px",
                                              }}
                                            />
                                          </>
                                        ) : e.status === "PAUSED" ? (
                                          <>
                                            <button
                                              className="prog-button-wbg "
                                              style={{ color: "red" }}
                                            >
                                              PAUSED
                                            </button>
                                            <img
                                              src={play}
                                              onClick={() =>
                                                props.onPlayHandler(e.id, index)
                                              }
                                              alt=""
                                              className="v-img"
                                              style={{
                                                cursor: "pointer",
                                                marginTop: "0px",
                                                width: "18px",
                                                height: "18px",
                                              }}
                                            />
                                          </>
                                        ) : null
                                      ) : (
                                        <div
                                          className="spinner "
                                          style={{
                                            marginLeft: "90px",
                                            marginTop: "15px",
                                          }}
                                        ></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="qr-col"
                                  style={{ marginTop: "10px" }}
                                >
                                  <p style={{ paddingLeft: "12px" }}>QR Code</p>
                                  <QRCode
                                    value={`SMSTO:${e.qr.to}:${e.qr.text}`}
                                    includeMargin={true}
                                    size={70}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}

                    <div className=" pagingation b-a-dash">
                      <div className="pagination-buttons">
                        <button
                          onClick={props.decrementPage}
                          disabled={props.currentPage === 1}
                          style={{ cursor: "pointer" }}
                          className="pageNationBtn"
                        >
                          <LeftOutlined />
                        </button>
                        <div className="pagnum">
                          {props.pageNumbersToShow.map((number) => (
                            <div
                              key={number}
                              onClick={() => props.setCurrentPage(number)}
                              style={{
                                background:
                                  props.currentPage === number
                                    ? "rgba(48, 78, 117, 0.15)"
                                    : "",
                                color:
                                  props.currentPage === number ? "#304e75" : "",
                              }}
                            >
                              {number}
                            </div>
                          ))}
                        </div>
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={props.incrementPage}
                          disabled={
                            props.currentPage === props.pageNumbers.length
                          }
                          className="pageNationBtn"
                        >
                          <RightOutlined />
                        </button>
                      </div>
                    </div>
                  </>
                ) : props.filteredData.length > 0 &&
                  props.nodataFound === false ? (
                  <>
                    {props.searchQuery2 ? (
                      <>
                        {props.searchData2.map((e: any, index: number) => {
                          return (
                            <div className="brandAddWraper">
                              <div className="img-grid">
                                <div>
                                  <img
                                    className="addImg"
                                    src={e.portraitBannerUrl}
                                    alt="mobile"
                                  />
                                </div>
                                <div>
                                  <img
                                    className="addImg"
                                    src={e.landscapeBannerUrl}
                                    alt="mobile"
                                    style={{ height: "50px" }}
                                  />
                                </div>
                              </div>
                              <div className="addCol">
                                <div className="test-add">
                                  <span>Ad Title</span>
                                  <p>{e.title}</p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>Channel</span>
                                    <p>{e.channel.name}</p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Category</span>
                                    <p>{e.category}</p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>Area </span>
                                    <p>{e.areas[0].name}</p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Tenure</span>
                                    <p>{e.tenure}</p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>Start Date</span>
                                    <p
                                      style={{
                                        width: "max-content",
                                        maxWidth: "70px",
                                      }}
                                    >
                                      {e.startDate}
                                    </p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Amount</span>
                                    <p>$ {e.amount}</p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>ImpressionCount</span>
                                    <p>{e.totalImpressionCount}</p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Status</span>
                                    <div className="camp-col-btn">
                                      {!props.loader ||
                                      props.selectedPushPlayIndex != index ? (
                                        e.status === "SCHEDULED" ? (
                                          <>
                                            <button className="not-started-wbg">
                                              SCHEDULED
                                            </button>
                                            <img
                                              src={push}
                                              onClick={() =>
                                                props.onPushHandler(e.id, index)
                                              }
                                              style={{
                                                objectFit: "cover",
                                                cursor: "pointer",
                                                marginTop: "0px",
                                                width: "18px",
                                                height: "18px",
                                              }}
                                              alt=""
                                              className="v-img"
                                            />
                                          </>
                                        ) : e.status === "COMPLETED" ? (
                                          <>
                                            <button className="paid-button-wbg">
                                              {/* <img src={facheck} alt="" /> */}
                                              Completed
                                            </button>
                                          </>
                                        ) : e.status === "RUNNING" ? (
                                          <>
                                            <button className="prog-button-wbg">
                                              RUNNING
                                            </button>
                                            <img
                                              src={push}
                                              onClick={() =>
                                                props.onPushHandler(e.id, index)
                                              }
                                              alt=""
                                              className="v-img"
                                              style={{
                                                cursor: "pointer",
                                                marginTop: "0px",
                                                width: "18px",
                                                height: "18px",
                                              }}
                                            />
                                          </>
                                        ) : e.status === "PAUSED" ? (
                                          <>
                                            <button
                                              className="prog-button-wbg "
                                              style={{ color: "red" }}
                                            >
                                              PAUSED
                                            </button>
                                            <img
                                              src={play}
                                              onClick={() =>
                                                props.onPlayHandler(e.id, index)
                                              }
                                              alt=""
                                              className="v-img"
                                              style={{
                                                cursor: "pointer",
                                                marginTop: "0px",
                                                width: "18px",
                                                height: "18px",
                                              }}
                                            />
                                          </>
                                        ) : null
                                      ) : (
                                        <div
                                          className="spinner "
                                          style={{
                                            marginLeft: "90px",
                                            marginTop: "15px",
                                          }}
                                        ></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="qr-col"
                                  style={{ marginTop: "10px" }}
                                >
                                  <p style={{ paddingLeft: "12px" }}>QR Code</p>
                                  <QRCode
                                    value={`SMSTO:${e.qr.to}:${e.qr.text}`}
                                    includeMargin={true}
                                    size={70}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {props.currentData2.map((e: any, index: number) => {
                          return (
                            <div className="brandAddWraper">
                              <div className="img-grid">
                                <div>
                                  <img
                                    className="addImg"
                                    src={e.portraitBannerUrl}
                                    alt="mobile"
                                  />
                                </div>
                                <div>
                                  <img
                                    className="addImg"
                                    src={e.landscapeBannerUrl}
                                    alt="mobile"
                                    style={{ height: "50px" }}
                                  />
                                </div>
                              </div>
                              <div className="addCol">
                                <div className="test-add">
                                  <span>Ad Title</span>
                                  <p>{e.title}</p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>Channel</span>
                                    <p>{e.channel.name}</p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Category</span>
                                    <p>{e.category}</p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>Area </span>
                                    <p>{e.areas[0].name}</p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Tenure</span>
                                    <p>{e.tenure}</p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>Start Date</span>
                                    <p
                                      style={{
                                        width: "max-content",
                                        maxWidth: "70px",
                                      }}
                                    >
                                      {e.startDate}
                                    </p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Amount</span>
                                    <p>$ {e.amount}</p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="addDesc">
                                    <span>ImpressionCount</span>
                                    <p>{e.totalImpressionCount}</p>
                                  </div>
                                  <div className="addDesc">
                                    <span>Status</span>
                                    <div className="camp-col-btn">
                                      {!props.loader ||
                                      props.selectedPushPlayIndex != index ? (
                                        e.status === "SCHEDULED" ? (
                                          <>
                                            <button className="not-started-wbg">
                                              SCHEDULED
                                            </button>
                                            <img
                                              src={push}
                                              onClick={() =>
                                                props.onPushHandler(e.id, index)
                                              }
                                              style={{
                                                objectFit: "cover",
                                                cursor: "pointer",
                                                marginTop: "0px",
                                                width: "18px",
                                                height: "18px",
                                              }}
                                              alt=""
                                              className="v-img"
                                            />
                                          </>
                                        ) : e.status === "COMPLETED" ? (
                                          <>
                                            <button className="paid-button-wbg">
                                              {/* <img src={facheck} alt="" /> */}
                                              Completed
                                            </button>
                                          </>
                                        ) : e.status === "RUNNING" ? (
                                          <>
                                            <button className="prog-button-wbg">
                                              RUNNING
                                            </button>
                                            <img
                                              src={push}
                                              onClick={() =>
                                                props.onPushHandler(e.id, index)
                                              }
                                              alt=""
                                              className="v-img"
                                              style={{
                                                cursor: "pointer",
                                                marginTop: "0px",
                                                width: "18px",
                                                height: "18px",
                                              }}
                                            />
                                          </>
                                        ) : e.status === "PAUSED" ? (
                                          <>
                                            <button
                                              className="prog-button-wbg "
                                              style={{ color: "red" }}
                                            >
                                              PAUSED
                                            </button>
                                            <img
                                              src={play}
                                              onClick={() =>
                                                props.onPlayHandler(e.id, index)
                                              }
                                              alt=""
                                              className="v-img"
                                              style={{
                                                cursor: "pointer",
                                                marginTop: "0px",
                                                width: "18px",
                                                height: "18px",
                                              }}
                                            />
                                          </>
                                        ) : null
                                      ) : (
                                        <div
                                          className="spinner "
                                          style={{
                                            marginLeft: "90px",
                                            marginTop: "15px",
                                          }}
                                        ></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="qr-col"
                                  style={{ marginTop: "10px" }}
                                >
                                  <p style={{ paddingLeft: "12px" }}>QR Code</p>
                                  <QRCode
                                    value={`SMSTO:${e.qr.to}:${e.qr.text}`}
                                    includeMargin={true}
                                    size={70}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}

                    <div className=" pagingation b-a-dash">
                      <div className="pagination-buttons">
                        <button
                          onClick={props.decrementPage2}
                          disabled={props.currentPage2 === 1}
                          style={{ cursor: "pointer" }}
                          className="pageNationBtn"
                        >
                          <LeftOutlined />
                        </button>
                        <div className="pagnum">
                          {props.pageNumbersToShow2.map((number2) => (
                            <div
                              key={number2}
                              onClick={() => props.setCurrentPage2(number2)}
                              style={{
                                background:
                                  props.currentPage2 === number2
                                    ? "rgba(48, 78, 117, 0.15)"
                                    : "",
                                color:
                                  props.currentPage2 === number2
                                    ? "#304e75"
                                    : "",
                              }}
                            >
                              {number2}
                            </div>
                          ))}
                        </div>
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={props.incrementPage2}
                          disabled={
                            props.currentPage2 === props.pageNumbers2.length
                          }
                          className="pageNationBtn"
                        >
                          <RightOutlined />
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {props.nodataFound && brandDatalist.length > 0 ? (
                      <div className="example">
                        <p>To Create New Ad Please Click Button</p>
                        <div
                          className="channelBtn cursor-pointer"
                          style={{ padding: "2px 0" }}
                        >
                          <a
                            onClick={() =>
                              navigate("/brand/createad", {
                                state: {
                                  BrandData: {
                                    label: props.brandsDetail.name,
                                    value: props.brandsDetail.id,
                                  },
                                },
                              })
                            }
                            style={{ display: "contents" }}
                          >
                            <div
                              className="channelBtn cursor-pointer"
                              onClick={() => {}}
                            >
                              <svg
                                width="17"
                                height="17"
                                viewBox="0 0 29 29"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.7797 16.1374H12.9464V24.304C12.9464 24.6135 13.0693 24.9102 13.2881 25.129C13.5069 25.3478 13.8036 25.4707 14.113 25.4707C14.4225 25.4707 14.7192 25.3478 14.938 25.129C15.1568 24.9102 15.2797 24.6135 15.2797 24.304V16.1374H23.4464C23.7558 16.1374 24.0525 16.0145 24.2713 15.7957C24.4901 15.5769 24.613 15.2801 24.613 14.9707C24.613 14.6613 24.4901 14.3645 24.2713 14.1457C24.0525 13.927 23.7558 13.804 23.4464 13.804H15.2797V5.63737C15.2797 5.32795 15.1568 5.0312 14.938 4.81241C14.7192 4.59362 14.4225 4.4707 14.113 4.4707C13.8036 4.4707 13.5069 4.59362 13.2881 4.81241C13.0693 5.0312 12.9464 5.32795 12.9464 5.63737V13.804H4.7797C4.47028 13.804 4.17354 13.927 3.95475 14.1457C3.73595 14.3645 3.61304 14.6613 3.61304 14.9707C3.61304 15.2801 3.73595 15.5769 3.95475 15.7957C4.17354 16.0145 4.47028 16.1374 4.7797 16.1374Z"
                                  fill="white"
                                />
                              </svg>
                              New Ad
                            </div>
                          </a>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <Spin indicator={antIcon} />
        )}

        {props.createBrand ? (
          <CreateBrandScreen
            CancellBtn={props.CancellBtn}
            successBtnHandler={props.successBtnHandler}
            token={props.token}
          />
        ) : (
          <></>
        )}
        {props.openEditBrand ? (
          <EditBrandScreen
            CancellBtn={props.CancellBtn}
            successBtnHandler={props.successBtnHandler}
            token={props.token}
            seletedBrand={props.seletedBrand}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default BrandAndAddDashboard;
