import React, { useState } from "react";
import DashboardChangePassword from "../Components/DashboardChangePassword";
import ApiNames from "../Constants/ApiNames";
import { fetchPosts } from "../Utils/FetchApis";
import Swal from "sweetalert2";

type Props = {
  token: any;
  handleLogoutClick: () => void;
};
interface FormValues {
  currentpassword: string;
  newpassword: string;
  confirmpasword: string;
}

const DashboardChangePasswordScreen = (props: Props) => {
  const [isLoader,setIsLoader]=useState(false)
  const [formvalues, setFormValue] = useState<FormValues>({
    currentpassword: "",
    newpassword: "",
    confirmpasword: "",
  });
  const [response, setResponse] = useState("");
  const changePassword = async (currentPassword: any, confirmpasword: any) => {
    setIsLoader(true)
    try {
      const data = {
        userId: 1,
        currentPassword: currentPassword,
        newPassword: confirmpasword,
      };
      const result = await fetchPosts(
        ApiNames.changePassword,
        data,
        props.token.jwttoken
      );
      if (result.response === "Success") {
        setResponse(result.response);
        setIsLoader(false)
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Password Changed Successfuly",
          showConfirmButton: false,
          timer: 1500,
        });
        props.handleLogoutClick();
      }
    } catch (error) {
      console.log(error)
      setIsLoader(false)
    }
  };


  return (
    <DashboardChangePassword
      formvalues={formvalues}
      setFormValue={setFormValue}
      changePassword={changePassword}
      response={response}
      isLoader={isLoader}
    />
  );
};

export default DashboardChangePasswordScreen;
