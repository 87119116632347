import React from "react";
import channelImg from "../Assets/channel.png";
import campaignImg from "../Assets/campaign.png";
import brandImg from "../Assets/brand.png";
import AddBrand from "../Assets/addBrand.png";
import CreateCampaignScreem from "../Screens/CreateCampaignScreem";
import facheck from "../Assets/fa-check.png";
import "../StyleSheets/CampaginDashBoardStyle.css";
import edit from "../Assets/b-edit.png";
import EditCampaginScreen from "../Screens/EditCampaginScreen";
import atozImg from "../Assets/atoz.png";
import menuImg from "../Assets/menu-bar.png";
import recentImg from "../Assets/recent.png";
import notStarted from "../Assets/not-started.png";
import push from "../Assets/p-v-icon.png";
import inProgress from "../Assets/prog-btn.png";
import play from "../Assets/v-icon.png";
import {
  LeftOutlined,
  LoadingOutlined,
  PauseOutlined,
  RightOutlined,
} from "@ant-design/icons";

import "../StyleSheets/CampaginDashBoardStyle.css";
import { Spin } from "antd";
import { index } from "d3-array";
import { useNavigate } from "react-router-dom";

type Props = {
  setOpenCreateChannel: React.Dispatch<React.SetStateAction<boolean>>;
  openCreateChannel: boolean;
  CancellBtn: () => void;
  successBtnHandler: () => void;
  onAllPressed: () => void;
  HandleOpenBrandMenu: (index: any) => void;
  openBrandDropdown: boolean;
  openEditBrand: boolean;
  setOpenEditBrand: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCampaign: React.Dispatch<React.SetStateAction<{}>>;
  selectedCampaign: {};
  brandData: any;
  sideMenuIndex: number;
  filteredData: any;
  mainList: any;
  nodataFound: boolean;
  token: any;
  handelSelectedBrand: (value: any) => void;
  seletedBrand: object;
  onListItemPressed: (id: number, data: string) => void;
  date: string;
  incrementPage: () => void;
  decrementPage: () => void;
  pageNumbersToShow: any[];
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  pageNumbers: number[];
  currentPage: number;
  currentData: any;
  clickedElement: number;
  setClickedElement: React.Dispatch<React.SetStateAction<number>>;
  sortHandler: () => void;
  recentHandler: () => void;
  handleDropDown: () => void;
  openDropdown: boolean;
  openEditChannel: boolean;
  setOpenEditChannel: React.Dispatch<React.SetStateAction<boolean>>;
  onPushHandler: (id: number, index: number) => Promise<void>;
  onPlayHandler: (id: number, index: number) => Promise<void>;
  currentData2: any;
  incrementPage2: () => void;
  decrementPage2: () => void;
  pageNumbersToShow2: any[];
  setCurrentPage2: React.Dispatch<React.SetStateAction<number>>;
  pageNumbers2: number[];
  currentPage2: number;
  loader: boolean;
  selectedPushPlayIndex: number;
  reverseList: boolean;
  tempData: any;
  isLoader: boolean;
  handleDataChange: (e: any) => string;
  selectedBrandOption: {};
  setSelectedBrandOption: React.Dispatch<React.SetStateAction<{}>>;
  brandsDetail: any;
  handleValue: (e: any) => void;
};

function CampaignDashboardNew(props: Props) {
  if (props.reverseList) {
    var brandDatalist = props.brandData;
  } else {
    var brandDatalist = props.tempData;
  }

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      spin
    />
  );
  return (
    <>
      <div className="d-top">
        <div className="d-row">
          <div className="cstm-col-md-6">
            <div className="d-top-left">
              <div className="channelBox">
                <img src={channelImg} alt="" />
                <div className="innerBox">
                  <p className="text">Channel</p>
                  <span>Locations - Devices</span>
                </div>
              </div>
              <div className="channelBox">
                <img src={brandImg} alt="" />
                <div className="innerBox">
                  <p className="text">Brand & Ads</p>
                </div>
              </div>
              <div className="channelBox">
                <img src={campaignImg} alt="" />
                <div className="innerBox">
                  <p className="text">Campaign</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cstm-col-md-6">
            <div className="d-top-right">
              {props.clickedElement === -1 ? (
                <div
                  className="channelBtn cursor-pointer"
                  onClick={() => {
                    props.setOpenCreateChannel(true);
                    props.setSelectedBrandOption("");
                  }}
                >
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.7797 16.1374H12.9464V24.304C12.9464 24.6135 13.0693 24.9102 13.2881 25.129C13.5069 25.3478 13.8036 25.4707 14.113 25.4707C14.4225 25.4707 14.7192 25.3478 14.938 25.129C15.1568 24.9102 15.2797 24.6135 15.2797 24.304V16.1374H23.4464C23.7558 16.1374 24.0525 16.0145 24.2713 15.7957C24.4901 15.5769 24.613 15.2801 24.613 14.9707C24.613 14.6613 24.4901 14.3645 24.2713 14.1457C24.0525 13.927 23.7558 13.804 23.4464 13.804H15.2797V5.63737C15.2797 5.32795 15.1568 5.0312 14.938 4.81241C14.7192 4.59362 14.4225 4.4707 14.113 4.4707C13.8036 4.4707 13.5069 4.59362 13.2881 4.81241C13.0693 5.0312 12.9464 5.32795 12.9464 5.63737V13.804H4.7797C4.47028 13.804 4.17354 13.927 3.95475 14.1457C3.73595 14.3645 3.61304 14.6613 3.61304 14.9707C3.61304 15.2801 3.73595 15.5769 3.95475 15.7957C4.17354 16.0145 4.47028 16.1374 4.7797 16.1374Z"
                      fill="white"
                    />
                  </svg>
                  New Campaigns
                </div>
              ) : (
                <div
                  className="channelBtn cursor-pointer"
                  onClick={() => {
                    props.setOpenCreateChannel(true);
                    props.setSelectedBrandOption({
                      label: props.brandsDetail.name,
                      value: props.brandsDetail.id,
                    });
                  }}
                >
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.7797 16.1374H12.9464V24.304C12.9464 24.6135 13.0693 24.9102 13.2881 25.129C13.5069 25.3478 13.8036 25.4707 14.113 25.4707C14.4225 25.4707 14.7192 25.3478 14.938 25.129C15.1568 24.9102 15.2797 24.6135 15.2797 24.304V16.1374H23.4464C23.7558 16.1374 24.0525 16.0145 24.2713 15.7957C24.4901 15.5769 24.613 15.2801 24.613 14.9707C24.613 14.6613 24.4901 14.3645 24.2713 14.1457C24.0525 13.927 23.7558 13.804 23.4464 13.804H15.2797V5.63737C15.2797 5.32795 15.1568 5.0312 14.938 4.81241C14.7192 4.59362 14.4225 4.4707 14.113 4.4707C13.8036 4.4707 13.5069 4.59362 13.2881 4.81241C13.0693 5.0312 12.9464 5.32795 12.9464 5.63737V13.804H4.7797C4.47028 13.804 4.17354 13.927 3.95475 14.1457C3.73595 14.3645 3.61304 14.6613 3.61304 14.9707C3.61304 15.2801 3.73595 15.5769 3.95475 15.7957C4.17354 16.0145 4.47028 16.1374 4.7797 16.1374Z"
                      fill="white"
                    />
                  </svg>
                  New Campaigns
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper__content">
        {!props.isLoader ? (
          <>
            {props.brandData.length > 0 ? (
              <>
                <div className="m-row" style={{ marginBottom: "80px" }}>
                  <div className="camp-custom-col-4">
                    <div className="channel__left">
                      <div className="channel__top">
                        <div className="ch-head">
                          <p>Brands</p>
                          <span>{props.brandData.length} Brands</span>
                        </div>
                        <div className="recent-right">
                          {props.clickedElement === -1 ? (
                            <div
                              className="new-channel"
                              onClick={() => {
                                props.setOpenCreateChannel(true);
                                props.setSelectedBrandOption("");
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img src={AddBrand} alt="" />
                            </div>
                          ) : (
                            <div
                              className="new-channel"
                              onClick={() => {
                                props.setOpenCreateChannel(true);
                                props.setSelectedBrandOption({
                                  label: props.brandsDetail.name,
                                  value: props.brandsDetail.id,
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img src={AddBrand} alt="" />
                            </div>
                          )}
                          <div
                            className="recent__Activity"
                            onClick={() => {
                              props.handleDropDown();
                            }}
                          >
                            <img src={menuImg} alt="" />
                            {props.openDropdown ? (
                              <>
                                <div className="recentDropDown">
                                  <p
                                    className="atoz"
                                    onClick={props.sortHandler}
                                  >
                                    <img src={atozImg} alt="" />
                                    A-Z
                                  </p>
                                  <p
                                    className="recent"
                                    onClick={props.recentHandler}
                                  >
                                    <img src={recentImg} alt="" />
                                    Recent
                                  </p>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>

                      <ul
                        className="channer_ul"
                        style={{ height: "592px", overflowY: "scroll" }}
                      >
                        {props.mainList.length > 0 ? (
                          <li
                            onClick={() => {
                              props.onAllPressed();
                              props.setClickedElement(-1);
                            }}
                            style={{
                              backgroundColor:
                                props.clickedElement === -1 ? "#f9f9f9" : "",
                            }}
                          >
                            <div className="li_left">
                              <div className="letter">
                                <p>A</p>
                              </div>
                              <div className="m-loc">
                                <p>All</p>
                              </div>
                            </div>
                          </li>
                        ) : (
                          <></>
                        )}

                        {brandDatalist.map((e: any, index: any) => {
                          return (
                            <li
                              onClick={() => {
                                props.onListItemPressed(e.id, e.dateCreated);
                                props.setClickedElement(index);
                                props.handleValue(e);
                              }}
                              style={{
                                backgroundColor:
                                  props.clickedElement === index
                                    ? "#f9f9f9"
                                    : "",
                              }}
                            >
                              <div className="li_left">
                                <div className="brandImg">
                                  <img
                                    className="brandImgs"
                                    src={e.iconUrl + "?time=" + new Date()}
                                    alt=""
                                  />
                                </div>
                                <div className="m-loc">
                                  <p>{e.name}</p>
                                  {e.status ? (
                                    <span>Enabled</span>
                                  ) : (
                                    <span>Disabled</span>
                                  )}
                                </div>
                              </div>
                              {/* <div className="li-right" id="li__right">
                            <img
                              src={brandDot}
                              alt=""
                              onClick={() => {
                                props.HandleOpenBrandMenu(index);
                              }}
                            />

                            {props.openBrandDropdown &&
                            props.sideMenuIndex === index ? (
                              <div className="brandDropDown">
                                <p
                                  onClick={() => {
                                    props.setOpenEditBrand(true);
                                    props.handelSelectedBrand(e);
                                  }}
                                >
                                  Edit
                                  <img src={pencilImg} alt="" />
                                </p>
                                <p className="createdon">
                                  <span>Created on</span>
                                  <span>{props.date}</span>
                                </p>
                                <p
                                  onClick={() => {
                                    props.CancellBtn();
                                  }}
                                  style={{ color: "red" }}
                                >
                                  Cancel
                                </p>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div> */}
                            </li>
                          );
                        })}
                      </ul>
                      {props.clickedElement === -1 ? (
                        <div className="alibtmdiv">
                          <div
                            className="new-channel"
                            onClick={() => {
                              props.setOpenCreateChannel(true);
                              props.setSelectedBrandOption("");
                            }}
                          >
                            <img src={AddBrand} alt="" />
                            New Campaigns
                          </div>
                        </div>
                      ) : (
                        <div className="alibtmdiv">
                          <div
                            className="new-channel"
                            onClick={() => {
                              props.setOpenCreateChannel(true);
                              props.setSelectedBrandOption({
                                label: props.brandsDetail.name,
                                value: props.brandsDetail.id,
                              });
                            }}
                          >
                            <img src={AddBrand} alt="" />
                            New Campaigns
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="camp-col-8">
                    <p className="campaign">Campaigns</p>
                    {props.date ? <p className="b-date">{props.date}</p> : null}
                    {props.mainList.length > 0 &&
                    props.filteredData.length == 0 &&
                    props.nodataFound === false ? (
                      <>
                        {props.currentData.map((e: any, index: number) => {
                          const brand = brandDatalist.find(
                            (b: any) => b.id === e.brandId
                          );
                          return (
                            <>
                              <div className="campaignWraper">
                                <div className="campCol test bdr-right">
                                  <p className="cam-title">Title</p>
                                  <p className="cam-desc">{e.title}</p>
                                  <p className="cam-title m-top">Status</p>

                                  <div className="camp-col-btn">
                                    {!props.loader ||
                                    props.selectedPushPlayIndex != index ? (
                                      e.status === "NOT_STARTED" ? (
                                        <>
                                          <button className="not-started">
                                            <img src={notStarted} alt="" />
                                            Not Started
                                          </button>
                                          <img
                                            src={push}
                                            onClick={() =>
                                              props.onPushHandler(e.id, index)
                                            }
                                            style={{
                                              objectFit: "cover",
                                              cursor: "pointer",
                                            }}
                                            alt=""
                                            className="v-img"
                                          />
                                        </>
                                      ) : e.status === "COMPLETED" ? (
                                        <>
                                          <button className="paid-button">
                                            <img src={facheck} alt="" />
                                            Completed
                                          </button>
                                          <div className="v-img"></div>
                                        </>
                                      ) : e.status === "IN_PROGRESS" ? (
                                        <>
                                          <button className="prog-button">
                                            <img src={inProgress} alt="" />
                                            In Progress
                                          </button>
                                          <img
                                            src={push}
                                            onClick={() =>
                                              props.onPushHandler(e.id, index)
                                            }
                                            alt=""
                                            className="v-img"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </>
                                      ) : e.status === "STOPPED" ? (
                                        <>
                                          <button
                                            className="prog-button"
                                            style={{ color: "red" }}
                                          >
                                            <PauseOutlined />
                                            Stopped
                                          </button>
                                          <img
                                            src={play}
                                            onClick={() =>
                                              props.onPlayHandler(e.id, index)
                                            }
                                            alt=""
                                            className="v-img"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </>
                                      ) : null
                                    ) : (
                                      <div
                                        className="spinner "
                                        style={{
                                          marginLeft: "90px",
                                          marginTop: "15px",
                                        }}
                                      ></div>
                                    )}
                                  </div>
                                </div>
                                <div className="campCol">
                                  <div className="inner-camp-col">
                                    <p className="cam-title">Subscribers</p>
                                    <p className="cam-desc">
                                      {e.subscriberCount}
                                    </p>
                                  </div>

                                  <div className="inner-camp-col">
                                    <p className="cam-title">SMS Sent</p>
                                    <p className="cam-desc">{e.successCount}</p>
                                  </div>
                                </div>
                                <div className="campCol ">
                                  <div className="inner-camp-col">
                                    <p className="cam-title">Un-subscribers</p>
                                    <p className="cam-desc">{e.unsubCount}</p>
                                  </div>

                                  <div className="inner-camp-col">
                                    <p className="cam-title">Start Date</p>
                                    <p className="cam-desc">
                                      {props.handleDataChange(e.startDatetime)}
                                    </p>
                                  </div>
                                </div>
                                <div className="campCol border-left">
                                  {/* <p className="cam-title">Short Code</p>
                              <p className="cam-desc">1234</p> */}

                                  <div className="inner-camp-col">
                                    <p className="cam-title">Sms Text</p>
                                    <p className="cam-desc">{e.smsText}</p>
                                  </div>
                                  <div className="inner-camp-col">
                                    <p className="cam-title">Brand</p>
                                    <div
                                      className="brandDisplay"
                                      style={{ marginTop: "10px" }}
                                    >
                                      <div className="brandImg-c ">
                                        <img
                                          src={brand?.iconUrl}
                                          alt=""
                                          style={{ width: "100%" }}
                                          className="brandImg-i"
                                        />
                                      </div>
                                      <p className="cam-desc">{brand?.name}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="campCol">
                                  <img
                                    src={edit}
                                    alt=""
                                    onClick={() => {
                                      props.setOpenEditChannel(true);
                                      props.setSelectedCampaign(e);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      position: "absolute",
                                      right: 0,
                                    }}
                                  />
                                  <img src="img/c-dots.png" alt="" />
                                </div>
                              </div>
                            </>
                          );
                        })}
                        <div className=" pagingation c-a-dash">
                          <div className="pagination-buttons">
                            <button
                              onClick={props.decrementPage}
                              disabled={props.currentPage === 1}
                              style={{ cursor: "pointer" }}
                              className="pageNationBtn"
                            >
                              <LeftOutlined />
                            </button>
                            <div className="pagnum">
                              {props.pageNumbersToShow.map((number) => (
                                <div
                                  key={number}
                                  onClick={() => props.setCurrentPage(number)}
                                  style={{
                                    background:
                                      props.currentPage === number
                                        ? "rgba(48, 78, 117, 0.15)"
                                        : "",
                                    color:
                                      props.currentPage === number
                                        ? "#304e75"
                                        : "",
                                  }}
                                >
                                  {number}
                                </div>
                              ))}
                            </div>
                            <button
                              style={{ cursor: "pointer" }}
                              onClick={props.incrementPage}
                              disabled={
                                props.currentPage === props.pageNumbers.length
                              }
                              className="pageNationBtn"
                            >
                              <RightOutlined />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : props.filteredData.length > 0 &&
                      props.nodataFound === false ? (
                      <>
                        {props.currentData2.map((e: any, index: number) => {
                          const brand2 = brandDatalist.find(
                            (b: any) => b.id === e.brandId
                          );
                          return (
                            <>
                              <div className="campaignWraper">
                                <div className="campCol test bdr-right">
                                  <p className="cam-title">Title</p>
                                  <p className="cam-desc">{e.title}</p>
                                  <p className="cam-title m-top">Status</p>

                                  <div className="camp-col-btn">
                                    {!props.loader ||
                                    props.selectedPushPlayIndex != index ? (
                                      e.status === "NOT_STARTED" ? (
                                        <>
                                          <button className="not-started">
                                            <img src={notStarted} alt="" />
                                            Not Started
                                          </button>
                                          <img
                                            src={push}
                                            onClick={() =>
                                              props.onPushHandler(e.id, index)
                                            }
                                            style={{
                                              objectFit: "cover",
                                              cursor: "pointer",
                                            }}
                                            alt=""
                                            className="v-img"
                                          />
                                        </>
                                      ) : e.status === "COMPLETED" ? (
                                        <>
                                          <button className="paid-button">
                                            <img src={facheck} alt="" />
                                            Completed
                                          </button>
                                          <div className="v-img"></div>
                                        </>
                                      ) : e.status === "IN_PROGRESS" ? (
                                        <>
                                          <button className="prog-button">
                                            <img src={inProgress} alt="" />
                                            In Progress
                                          </button>
                                          <img
                                            src={push}
                                            onClick={() =>
                                              props.onPushHandler(e.id, index)
                                            }
                                            alt=""
                                            className="v-img"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </>
                                      ) : e.status === "STOPPED" ? (
                                        <>
                                          <button
                                            className="prog-button"
                                            style={{ color: "red" }}
                                          >
                                            <PauseOutlined />
                                            Stopped
                                          </button>
                                          <img
                                            src={play}
                                            onClick={() =>
                                              props.onPlayHandler(e.id, index)
                                            }
                                            alt=""
                                            className="v-img"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </>
                                      ) : null
                                    ) : (
                                      <div
                                        className="spinner "
                                        style={{
                                          marginLeft: "90px",
                                          marginTop: "15px",
                                        }}
                                      ></div>
                                    )}
                                  </div>
                                </div>
                                <div className="campCol">
                                  <div className="inner-camp-col">
                                    <p className="cam-title">Subscribers</p>
                                    <p className="cam-desc">
                                      {e.subscriberCount}
                                    </p>
                                  </div>
                                  <div className="inner-camp-col">
                                    <p className="cam-title">SMS Sent</p>
                                    <p className="cam-desc">{e.successCount}</p>
                                  </div>
                                </div>
                                <div className="campCol ">
                                  <div className="inner-camp-col">
                                    <p className="cam-title">Un-subscribers</p>
                                    <p className="cam-desc">{e.unsubCount}</p>
                                  </div>

                                  <div className="inner-camp-col">
                                    <p className="cam-title">Start Date</p>
                                    <p className="cam-desc">
                                      {props.handleDataChange(e.startDatetime)}
                                    </p>
                                  </div>
                                </div>
                                <div className="campCol border-left">
                                  {/* <p className="cam-title">Short Code</p>
                              <p className="cam-desc">1234</p> */}

                                  <div className="inner-camp-col">
                                    <p className="cam-title">Sms Text</p>
                                    <p className="cam-desc">{e.smsText}</p>
                                    <div className="inner-camp-col">
                                      <p className="cam-title">Brand</p>
                                      <div className="brandDisplay">
                                        <div className="brandImg-c ">
                                          <img
                                            src={brand2?.iconUrl}
                                            alt=""
                                            className="brandImg-i "
                                          />
                                        </div>
                                        <p className="cam-desc">
                                          {brand2?.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="campCol">
                                  <img
                                    src={edit}
                                    alt=""
                                    onClick={() => {
                                      props.setOpenEditChannel(true);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      position: "absolute",
                                      right: 0,
                                    }}
                                  />
                                  <img src="img/c-dots.png" alt="" />
                                </div>
                              </div>
                            </>
                          );
                        })}
                        <div className=" pagingation c-a-dash">
                          <div className="pagination-buttons">
                            <button
                              onClick={props.decrementPage2}
                              disabled={props.currentPage2 === 1}
                              style={{ cursor: "pointer" }}
                              className="pageNationBtn"
                            >
                              <LeftOutlined />
                            </button>
                            <div className="pagnum">
                              {props.pageNumbersToShow2.map((number2) => (
                                <div
                                  key={number2}
                                  onClick={() => props.setCurrentPage2(number2)}
                                  style={{
                                    background:
                                      props.currentPage2 === number2
                                        ? "rgba(48, 78, 117, 0.15)"
                                        : "",
                                    color:
                                      props.currentPage2 === number2
                                        ? "#304e75"
                                        : "",
                                  }}
                                >
                                  {number2}
                                </div>
                              ))}
                            </div>
                            <button
                              style={{ cursor: "pointer" }}
                              onClick={props.incrementPage2}
                              disabled={
                                props.currentPage2 === props.pageNumbers2.length
                              }
                              className="pageNationBtn"
                            >
                              <RightOutlined />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {props.nodataFound ? (
                          <div className="example">
                            <p>To Create New Campaigns Please Click Button</p>
                            {props.clickedElement === -1 ? (
                              <div
                                className="channelBtn cursor-pointer"
                                onClick={() => {
                                  props.setOpenCreateChannel(true);
                                  props.setSelectedBrandOption("");
                                }}
                              >
                                <svg
                                  width="17"
                                  height="17"
                                  viewBox="0 0 29 29"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4.7797 16.1374H12.9464V24.304C12.9464 24.6135 13.0693 24.9102 13.2881 25.129C13.5069 25.3478 13.8036 25.4707 14.113 25.4707C14.4225 25.4707 14.7192 25.3478 14.938 25.129C15.1568 24.9102 15.2797 24.6135 15.2797 24.304V16.1374H23.4464C23.7558 16.1374 24.0525 16.0145 24.2713 15.7957C24.4901 15.5769 24.613 15.2801 24.613 14.9707C24.613 14.6613 24.4901 14.3645 24.2713 14.1457C24.0525 13.927 23.7558 13.804 23.4464 13.804H15.2797V5.63737C15.2797 5.32795 15.1568 5.0312 14.938 4.81241C14.7192 4.59362 14.4225 4.4707 14.113 4.4707C13.8036 4.4707 13.5069 4.59362 13.2881 4.81241C13.0693 5.0312 12.9464 5.32795 12.9464 5.63737V13.804H4.7797C4.47028 13.804 4.17354 13.927 3.95475 14.1457C3.73595 14.3645 3.61304 14.6613 3.61304 14.9707C3.61304 15.2801 3.73595 15.5769 3.95475 15.7957C4.17354 16.0145 4.47028 16.1374 4.7797 16.1374Z"
                                    fill="white"
                                  />
                                </svg>
                                New Campaigns
                              </div>
                            ) : (
                              <div
                                className="channelBtn cursor-pointer"
                                onClick={() => {
                                  props.setOpenCreateChannel(true);
                                  props.setSelectedBrandOption({
                                    label: props.brandsDetail.name,
                                    value: props.brandsDetail.id,
                                  });
                                }}
                              >
                                <svg
                                  width="17"
                                  height="17"
                                  viewBox="0 0 29 29"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4.7797 16.1374H12.9464V24.304C12.9464 24.6135 13.0693 24.9102 13.2881 25.129C13.5069 25.3478 13.8036 25.4707 14.113 25.4707C14.4225 25.4707 14.7192 25.3478 14.938 25.129C15.1568 24.9102 15.2797 24.6135 15.2797 24.304V16.1374H23.4464C23.7558 16.1374 24.0525 16.0145 24.2713 15.7957C24.4901 15.5769 24.613 15.2801 24.613 14.9707C24.613 14.6613 24.4901 14.3645 24.2713 14.1457C24.0525 13.927 23.7558 13.804 23.4464 13.804H15.2797V5.63737C15.2797 5.32795 15.1568 5.0312 14.938 4.81241C14.7192 4.59362 14.4225 4.4707 14.113 4.4707C13.8036 4.4707 13.5069 4.59362 13.2881 4.81241C13.0693 5.0312 12.9464 5.32795 12.9464 5.63737V13.804H4.7797C4.47028 13.804 4.17354 13.927 3.95475 14.1457C3.73595 14.3645 3.61304 14.6613 3.61304 14.9707C3.61304 15.2801 3.73595 15.5769 3.95475 15.7957C4.17354 16.0145 4.47028 16.1374 4.7797 16.1374Z"
                                    fill="white"
                                  />
                                </svg>
                                New Campaigns
                              </div>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div style={{ textAlign: "center" }}>No Record Found</div>
            )}
          </>
        ) : (
          <Spin indicator={antIcon} />
        )}

        {props.openCreateChannel ? (
          <CreateCampaignScreem
            successBtnHandler={props.successBtnHandler}
            setOpenCreateChannel={props.setOpenCreateChannel}
            token={props.token}
            selectedBrandOption={props.selectedBrandOption}
          />
        ) : (
          <></>
        )}
        {props.openEditChannel ? (
          <EditCampaginScreen
            selectedCampaign={props.selectedCampaign}
            setOpenEditChannel={props.setOpenEditChannel}
            token={props.token}
            successBtnHandler={props.successBtnHandler}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default CampaignDashboardNew;
