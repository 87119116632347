import React, { useContext, useEffect, useState } from "react";
import CampaignDashboardNew from "../Components/CampaignDashboardNew";
import { fetchGetPWKey } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import Swal from "sweetalert2";
import UserContext from "../Utils/User_Context";

type Props = {
  token: any;
};

function CampaignDashboardScreenNew(props: Props) {
  const [openCreateChannel, setOpenCreateChannel] = useState(false);
  const [openEditChannel, setOpenEditChannel] = useState(false);
  const [createBrand, setCreateBrand] = useState(false);
  const [clickedElement, setClickedElement] = useState<number>(0);
  const [openBrandDropdown, setOpenBrandDropdown] = useState(false);
  const [openEditBrand, setOpenEditBrand] = useState(false);
  const [brandData, setBrandData] = useState<any>([]);
  const [sideMenuIndex, setSideMenuIndex] = useState(0);
  const [mainList, setMainList] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [nodataFound, setNoDataFound] = useState(false);
  const [SuccessRefresh, setSuccessRefresh] = useState(false);
  const [seletedBrand, setSelectedBrand] = useState({});
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [selectedBrandOption, setSelectedBrandOption] = useState({});
  const [date, setDate] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(3);
  const [currentPage2, setCurrentPage2] = useState<number>(1);
  const [itemsPerPage2, setItemsPerPage2] = useState<number>(3);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedPushPlayIndex, setSelectedPushPlayIndex] = useState(0);
  const [tempData, setTempData] = useState<any>([]);
  const [brandsDetail, setBrandDetail] = useState<any>([]);
  const [reverseList, setReverseList] = useState(true);

  const { onHandleAdList, adMainList, isAdmin } = useContext(UserContext);
  let id = localStorage.getItem("id");

  const incrementPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const decrementPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const incrementPage2 = () => {
    setCurrentPage2(currentPage2 + 1);
  };
  const decrementPage2 = () => {
    setCurrentPage2(currentPage2 - 1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const indexOfLastItem2 = currentPage2 * itemsPerPage2;
  const indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage2;
  const currentData = mainList.slice(indexOfFirstItem, indexOfLastItem);
  const currentData2 = filteredData.slice(indexOfFirstItem2, indexOfLastItem2);
  const pageNumbers: number[] = [];
  const pageNumbers2: number[] = [];
  for (let i = 1; i <= Math.ceil(mainList.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  for (let j = 1; j <= Math.ceil(filteredData.length / itemsPerPage2); j++) {
    pageNumbers2.push(j);
  }
  const getPageNumbersToShow = () => {
    const pages = [...pageNumbers];
    const index = pages.indexOf(currentPage);
    const start = index;
    const end = index + 2;
    return pages
      .slice(start, end)
      .filter((page) => page >= 1 && page <= pageNumbers.length);
  };
  const getPageNumbersToShow2 = () => {
    const pages2 = [...pageNumbers2];
    const index2 = pages2.indexOf(currentPage2);
    const start2 = index2;
    const end2 = index2 + 2;
    return pages2
      .slice(start2, end2)
      .filter((page2) => page2 >= 1 && page2 <= pageNumbers2.length);
  };
  const pageNumbersToShow = getPageNumbersToShow();
  const pageNumbersToShow2 = getPageNumbersToShow2();

  const HandleOpenBrandMenu = (index: any) => {
    setOpenBrandDropdown(true);
    setSideMenuIndex(index);
  };
  const CancellBtn = () => {
    setCreateBrand(false);
    setOpenBrandDropdown(false);
    setOpenEditBrand(false);
  };
  const successBtnHandler = () => {
    setSuccessRefresh(!SuccessRefresh);
  };

  useEffect(() => {
    if (props.token) {
      brandDateRequest();
      getCampaign();
    }
  }, [props.token, SuccessRefresh]);

  const brandDateRequest = async () => {
    setIsLoader(true);
    let endPoint;
    if (!isAdmin) {
      endPoint = `${ApiNames.brandList}?channelId=${id}`;
    } else {
      endPoint = ApiNames.brandList;
    }
    try {
      const result = await fetchGetPWKey(endPoint, props.token.jwttoken);
      if (result.status === 0) {
        setIsLoader(false);
        let temp = result.response;
        setBrandData(temp.reverse());
      } else {
        setIsLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.response,
        });
      }
    } catch (error) {
      setIsLoader(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };

  const getCampaign = async () => {
    setIsLoader(true);
    let endPoint;
    if (!isAdmin) {
      endPoint = `${ApiNames.campaignList}?channelId=${id}`;
    } else {
      endPoint = ApiNames.campaignList;
    }
    try {
      let response = await fetchGetPWKey(endPoint, props.token.jwttoken);
      if (response.status === 0) {
        setIsLoader(false);
        let res = response.response;
        if (res.length > 0) {
          setClickedElement(-1);
        }
        setMainList(res.reverse());
        if (response.response)
          if (response.response.length === 0) {
            setNoDataFound(true);
          } else {
            setNoDataFound(false);
          }
      } else {
        setIsLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.response,
        });
      }
    } catch (error) {
      setIsLoader(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };
  const onListItemPressed = (id: number, date: string) => {
    setDate(date);
    try {
      let temp = [];
      for (let i = 0; i < mainList.length; i++) {
        if (mainList[i].brandId === id) {
          temp.push(mainList[i]);
        }
      }
      if (temp.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
      setFilteredData(temp);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };
  const onAllPressed = () => {
    setFilteredData([]);
    setNoDataFound(false);
    setDate("");
  };
  const handelSelectedBrand = (value: any) => {
    setSelectedBrand(value);
  };

  const sortHandler = () => {
    if (brandData) {
      // brandData.sort(function (a: any, b: any) {
      //   if (a.name < b.name) {
      //     return -1;
      //   }
      //   if (a.name > b.name) {
      //     return 1;
      //   }
      //   return 0;
      // });
      if (reverseList === true) {
        setReverseList(false);
        let temp = [...brandData];
        temp?.sort((a: any, b: any) => a.name.localeCompare(b.name));

        onAllPressed();
        setClickedElement(-1);
        setTempData(temp);
      } else {
        let temp = [...tempData];

        temp?.reverse();
        onAllPressed();
        setClickedElement(-1);
        setTempData(temp);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No Data Found..!",
      });
    }
  };
  const recentHandler = () => {
    setTempData([]);
    if (brandData) {
      // brandData.sort(function (a: any, b: any) {
      //   return (
      //     moment(b.dateCreated, "dddd, MMMM Do YYYY") -
      //     moment(a.dateCreated, "dddd, MMMM Do YYYY")
      //   );
      // });
      // let temp = [...brandData];
      // onAllPressed();
      // setClickedElement(-1);
      // setTempData(temp.reverse());

      setReverseList(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No Data Found..!",
      });
    }
  };
  const handleDropDown = () => {
    if (openDropdown === true) {
      setOpenDropdown(false);
    } else {
      setOpenDropdown(true);
    }
  };

  const onPushHandler = async (id: number, index: number) => {
    setSelectedPushPlayIndex(index);
    setLoader(true);
    try {
      const result = await fetchGetPWKey(
        ApiNames.campaignStop + "?id=" + id,
        props.token.jwttoken
      );
      if (result.status === 0) {
        successBtnHandler();
        setLoader(false);
      } else if (result.status === 1) {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.response,
        });
      }
    } catch (error) {
      setLoader(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };
  const onPlayHandler = async (id: number, index: number) => {
    setSelectedPushPlayIndex(index);
    setLoader(true);
    try {
      const result = await fetchGetPWKey(
        ApiNames.campaignStart + "?id=" + id,
        props.token.jwttoken
      );
      if (result.status === 0) {
        successBtnHandler();
        setLoader(false);
      } else if (result.status === 1) {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.response,
        });
      }
    } catch (error) {
      setLoader(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };
  const handleDataChange = (e: any) => {
    const date = new Date(e);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate: string = date.toLocaleDateString(undefined, options);
    return formattedDate;
  };
  const handleValue = (e: any) => {
    setBrandDetail(e);
  };

  return (
    <>
      <CampaignDashboardNew
        onPushHandler={onPushHandler}
        onPlayHandler={onPlayHandler}
        setSelectedCampaign={setSelectedCampaign}
        selectedCampaign={selectedCampaign}
        setOpenCreateChannel={setOpenCreateChannel}
        openCreateChannel={openCreateChannel}
        openEditChannel={openEditChannel}
        setOpenEditChannel={setOpenEditChannel}
        CancellBtn={CancellBtn}
        HandleOpenBrandMenu={HandleOpenBrandMenu}
        openBrandDropdown={openBrandDropdown}
        openEditBrand={openEditBrand}
        setOpenEditBrand={setOpenEditBrand}
        brandData={brandData}
        sideMenuIndex={sideMenuIndex}
        onAllPressed={onAllPressed}
        onListItemPressed={onListItemPressed}
        filteredData={filteredData}
        mainList={mainList}
        setClickedElement={setClickedElement}
        nodataFound={nodataFound}
        token={props.token}
        handelSelectedBrand={handelSelectedBrand}
        seletedBrand={seletedBrand}
        date={date}
        successBtnHandler={successBtnHandler}
        pageNumbers={pageNumbers}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        incrementPage={incrementPage}
        decrementPage={decrementPage}
        pageNumbersToShow={pageNumbersToShow}
        currentData={currentData}
        clickedElement={clickedElement}
        sortHandler={sortHandler}
        recentHandler={recentHandler}
        handleDropDown={handleDropDown}
        openDropdown={openDropdown}
        currentData2={currentData2}
        pageNumbers2={pageNumbers2}
        setCurrentPage2={setCurrentPage2}
        currentPage2={currentPage2}
        incrementPage2={incrementPage2}
        decrementPage2={decrementPage2}
        pageNumbersToShow2={pageNumbersToShow2}
        loader={loader}
        selectedPushPlayIndex={selectedPushPlayIndex}
        reverseList={reverseList}
        tempData={tempData}
        isLoader={isLoader}
        handleDataChange={handleDataChange}
        selectedBrandOption={selectedBrandOption}
        setSelectedBrandOption={setSelectedBrandOption}
        brandsDetail={brandsDetail}
        handleValue={handleValue}
      />
    </>
  );
}

export default CampaignDashboardScreenNew;
