import React from "react";
import { useFormik } from "formik";
import { Spin } from "antd";
import Select from "react-select";
import { LoadingOutlined } from "@ant-design/icons";

type Props = {
  CancellBtn: () => void;
  handleFile: (e: any) => void;
  brandAddHandler: (e: any) => void;
  imgData: any;
  title: string;
  imgVal: string;
  loading: boolean;
  imgUpload: any;
  nameError: boolean;
  imageError: boolean;
  setImageError: React.Dispatch<React.SetStateAction<boolean>>;
  setNameError: React.Dispatch<React.SetStateAction<boolean>>;
  channelOptions: any;
  handleOptionChange: (option: Option | null) => void;
  selectedOption: any;
  isAdmin: boolean;
};
interface Option {
  label: string;
  value: string;
  children?: Option[];
}

interface FormValues {
  title: string;
}

function CreateBrand(props: Props) {
  const formik = useFormik({
    initialValues: {
      title: "",
    },

    onSubmit: (values: FormValues) => {
      props.brandAddHandler(values.title);
    },
  });
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 28,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "0px",
      }}
      spin
    />
  );
  console.log("Channel Option" + props.channelOptions);
  return (
    <>
      <div id="custom-modal">
        <div id="m-modal-content">
          <div className="modalTop">
            <h2>Create Brand</h2>
            <span
              className="close"
              onClick={() => {
                props.CancellBtn();
              }}
            >
              &times;
            </span>
          </div>

          <div className="forminput newBrand">
            <div className="inputGroup">
              <label htmlFor="channelname">Title</label>
              <div className="innerInput">
                <input
                  type="text"
                  name="title"
                  placeholder="Title"
                  className="form-control"
                  onChange={(e) => {
                    props.setNameError(false);
                    formik.handleChange(e);
                  }}
                  value={formik.values.title}
                />
              </div>

              {props.nameError ? (
                <p className="error">Please Add Channel Title</p>
              ) : (
                <></>
              )}
              {!props.isAdmin ? (
                <></>
              ) : (
                <>
                  {" "}
                  <label className="Ad-Add-Lable">Channel/Segment</label>
                  <div className="innerInput">
                    <Select
                      className="adOptionContainer"
                      options={props.channelOptions}
                      value={props.selectedOption}
                      onChange={(e) => {
                        props.handleOptionChange(e);
                      }}
                    />
                  </div>
                </>
              )}

              <form action="#" className="dropzone" id="dropzoneForm">
                <div className="fallback">
                  <input
                    className="form-control"
                    type="file"
                    accept=".webp"
                    value={props.imgVal}
                    onChange={(e) => {
                      props.setImageError(false);
                      props.handleFile(e);
                    }}
                  />
                </div>
              </form>
              {props.imageError ? (
                <p className="error">Image is missing</p>
              ) : (
                <></>
              )}
              {props.imgUpload ? (
                <div className="img">
                  <img src={props.imgUpload} alt="" style={{ marginLeft: 0 }} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="modalBtn">
            <button
              className="cancelbtn"
              onClick={() => {
                props.CancellBtn();
              }}
            >
              Cancel
            </button>
            {}
            {props.loading ? (
              <button className="createbtn" onClick={formik.submitForm}>
                <Spin indicator={antIcon} />
              </button>
            ) : (
              <button className="createbtn" onClick={formik.submitForm}>
                Create
              </button>
            )}
          </div>
        </div>
      </div>
      <div id="overlay"></div>
    </>
  );
}

export default CreateBrand;
