import React, { useState } from "react";
import CreateChannel from "../Components/CreateChannel";
import { fetchPosts } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import Swal from "sweetalert2";

type Props = {
  handleCrossSign: () => void;
  HandleSuccessSign: () => void;
  token: any;
};

function CreateChannelScreen(props: Props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCreate = async (
    name: string,
    username: string,
    email: string,
    password: string,
    LongCode:string
  ) => {
    try {
      setLoading(true);
      const data = {
        name: name,
        username: username,
        email: email,
        password: password,
        mobileNumber: LongCode
      };
      const result = await fetchPosts(
        ApiNames.channelcreate,
        data,
        props.token.jwttoken
      );
      if (result.status === 0) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Channel Created",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        props.HandleSuccessSign();
      } else if (result.status === 1) {
        setLoading(false);
        setErrorMessage(result.response);
      }
    } catch (error) {
      setErrorMessage("Something Went Wrong...!");
      setLoading(false);
    }
  };

  return (
    <>
      <CreateChannel
        handleCrossSign={props.handleCrossSign}
        handleCreate={handleCreate}
        errorMessage={errorMessage}
        loading={loading}
      />
    </>
  );
}

export default CreateChannelScreen;
