import channelImg from "../../Assets/channel.png";
import campaignImg from "../../Assets/campaign.png";
import brandImg from "../../Assets/brand.png";

const SectionTop = () => {
  return (
    <>
      <div className="d-top">
        <div className="d-row">
          <div className="cstm-col-md-6">
            <div className="d-top-left">
              <div className="channelBox">
                <img src={channelImg} alt="" />
                <div className="innerBox">
                  <p className="text">Channel</p>
                  <span>Locations - Devices</span>
                </div>
              </div>
              <div className="channelBox">
                <img src={brandImg} alt="" />
                <div className="innerBox">
                  <p className="text">Brand & Ads</p>
                </div>
              </div>
              <div className="channelBox">
                <img src={campaignImg} alt="" />
                <div className="innerBox">
                  <p className="text">Campaign</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cstm-col-md-6">
            <div className="d-top-right"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionTop;
