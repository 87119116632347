import React, { useState } from "react";
import EditChannel from "../Components/EditChannel";
import { fetchPosts } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import Swal from "sweetalert2";

type Props = {
  handleCrossSign: () => void;
  HandleSuccessSign: () => void;
  selectedChannel: any;
  token: any;
};

function EditChannelScreen(props: Props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEdit = async (
    name: string,
    username: string,
    email: string,
    id: number,
    LongCode:string
  ) => {
    try {
      setLoading(true);
      const data = {
        channelId: id,
        name: name,
        username: username,
        email: email,
        mobileNumber: LongCode
      };
      const result = await fetchPosts(
        ApiNames.channeledit,
        data,
        props.token.jwttoken
      );
      if (result.status === 0) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Channel Edited",
          showConfirmButton: false,
          timer: 1500,
        });
        props.HandleSuccessSign();
        setLoading(false);
      } else if (result.status === 1) {
        setErrorMessage(result.response);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage("Something Went Wrong..!");
    }
  };
  return (
    <>
      <EditChannel
        errorMessage={errorMessage}
        handleEdit={handleEdit}
        handleCrossSign={props.handleCrossSign}
        selectedChannel={props.selectedChannel}
        loading={loading}
      />
    </>
  );
}

export default EditChannelScreen;
