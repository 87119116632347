import { Col, Row, Spin } from "antd";
import React, { PureComponent } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "../StyleSheets/ReportCommonStyle.css";
import channelImg from "../Assets/channel.png";
import campaignImg from "../Assets/campaign.png";
import brandImg from "../Assets/brand.png";
import AddBrand from "../Assets/addBrand.png";
import menuImg from "../Assets/menu-bar.png";
import recentImg from "../Assets/recent.png";
import atozImg from "../Assets/atoz.png";
import analyticsGreen from "../Assets/analytics-green.png";
import analyticsDefault from "../Assets/analytics-default.png";
import analyticsOrange from "../Assets/analytics-orange.png";
import analyticsRed from "../Assets/analytics-red.png";
import inProgress from "../Assets/prog-btn.png";
import notStarted from "../Assets/not-started.png";
import push from "../Assets/p-v-icon.png";
import facheck from "../Assets/fa-check.png";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePickerComponent from "./DatePickerComponent";
import SectionTop from "./common/SectionTop";
import ChannelReportTable from "./ChannelReportTable";

type Props = {
  brandData: any;
  clickedElement: number;
  sortHandler: () => void;
  recentHandler: () => void;
  handleDropDown: () => void;
  openDropdown: boolean;
  reverseList: boolean;
  tempData: any;
  isLoader: boolean;
  onClickedElement: (index: number) => void;
  onDateHandler: (FDate: any, TDate: any) => void;
  selectedBrand: any;
  campaignStatsData: any;
  setShowTalbleView: React.Dispatch<React.SetStateAction<boolean>>;
  showTalbleView: boolean;
};

const CampaignReport = (props: Props) => {
  if (props.reverseList) {
    var brandDatalist = props.brandData;
  } else {
    var brandDatalist = props.tempData;
  }
  const data = [
    {
      name: "Jan",
      $: 2400,
    },
    {
      name: "Feb",
      $: 1398,
    },
    {
      name: "Mar",
      $: 9800,
    },
    {
      name: "Apr",
      $: 3908,
    },
    {
      name: "May",
      $: 4800,
    },
    {
      name: "June",
      $: 3800,
    },
    {
      name: "July",
      $: 4300,
    },
    {
      name: "Aug",
      $: 4300,
    },
    {
      name: "Sep",
      $: 4300,
    },
    {
      name: "Oct",
      $: 4300,
    },
    {
      name: "Nov",
      $: 4300,
    },
    {
      name: "Dec",
      $: 4300,
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      spin
    />
  );
  return (
    <>
      <SectionTop />
      <div className="wrapper__content">
        {!props.isLoader ? (
          <>
            {props.brandData.length > 0 ? (
              <>
                <Row style={{ margin: "20px" }}>
                  <Col span={6}>
                    {" "}
                    <div className="channel__left" style={{ height: "100%" }}>
                      <div className="channel__top">
                        <div className="ch-head">
                          <p>Brands</p>
                          <span>{props.brandData.length} Brands</span>
                        </div>
                        <div className="recent-right">
                          <div
                            className="recent__Activity"
                            onClick={() => {
                              props.handleDropDown();
                            }}
                          >
                            <img src={menuImg} alt="" />
                            {props.openDropdown ? (
                              <>
                                <div className="recentDropDown">
                                  <p
                                    className="atoz"
                                    onClick={props.sortHandler}
                                  >
                                    <img src={atozImg} alt="" />
                                    A-Z
                                  </p>
                                  <p
                                    className="recent"
                                    onClick={props.recentHandler}
                                  >
                                    <img src={recentImg} alt="" />
                                    Recent
                                  </p>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>

                      <ul
                        className="channer_ul"
                        style={{ height: "677px", overflowY: "auto" }}
                      >
                        {props.brandData.length > 0 ? (
                          <li
                            onClick={() => {
                              props.onClickedElement(-1);
                            }}
                            style={{
                              backgroundColor:
                                props.clickedElement === -1 ? "#f9f9f9" : "",
                            }}
                          >
                            <div className="li_left">
                              <div className="letter">
                                <p>O</p>
                              </div>
                              <div className="m-loc">
                                <p>OverView</p>
                              </div>
                            </div>
                          </li>
                        ) : (
                          <></>
                        )}

                        {brandDatalist.map((e: any, index: any) => {
                          return (
                            <li
                              onClick={() => {
                                props.onClickedElement(index);
                              }}
                              style={{
                                backgroundColor:
                                  props.clickedElement === index
                                    ? "#f9f9f9"
                                    : "",
                              }}
                            >
                              <div className="li_left">
                                <div className="brandImg">
                                  <img
                                    className="brandImgs"
                                    src={e.iconUrl + "?time=" + new Date()}
                                    alt=""
                                  />
                                </div>
                                <div className="m-loc">
                                  <p>{e.name}</p>
                                  {e.status ? (
                                    <span>Enabled</span>
                                  ) : (
                                    <span>Disabled</span>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </Col>
                  <Col span={18}>
                    {props.clickedElement === -1 ? (
                      <div
                        className="cafe_side "
                        style={{ marginLeft: "15px", height: "800px" }}
                      >
                        <div className="cafe-top">
                          <div className="cafe-top-left">
                            <p>Campaigns Report</p>
                          </div>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={() => {
                                  if (props.showTalbleView === false) {
                                    props.setShowTalbleView(true);
                                  } else {
                                    props.setShowTalbleView(false);
                                  }
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                            <DatePickerComponent
                              onDateHandler={props.onDateHandler}
                            />
                          </div>
                        </div>
                        {props.showTalbleView ? (
                          <>
                            <ChannelReportTable
                              data={props.campaignStatsData}
                            />
                          </>
                        ) : (
                          <>
                            {" "}
                            <Row style={{ justifyContent: "space-between" }}>
                              <Col span={6} style={{ maxWidth: "24%" }}>
                                {" "}
                                <div className="digitalBox ">
                                  <div className="app-count">
                                    <p className="noa">
                                      {props.campaignStatsData.campaignCount}
                                    </p>
                                    <img
                                      src={analyticsGreen}
                                      className="waveImg"
                                      alt=""
                                    />
                                    <p className="application">
                                      Total Campaign
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col span={6} style={{ maxWidth: "24%" }}>
                                {" "}
                                <div className="digitalBox ">
                                  <div className="app-count">
                                    <p className="noa">
                                      {props.campaignStatsData.subscriberCount}
                                    </p>
                                    <img
                                      src={analyticsDefault}
                                      className="waveImg"
                                      alt=""
                                    />
                                    <p className="application">
                                      Total Subscriber
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col span={6} style={{ maxWidth: "24%" }}>
                                {" "}
                                <div className="digitalBox ">
                                  <div className="app-count">
                                    <p className="noa">
                                      {props.campaignStatsData.unsubCount}
                                    </p>
                                    <img
                                      src={analyticsOrange}
                                      className="waveImg"
                                      alt=""
                                    />
                                    <p className="application">
                                      Total UnSubscriber
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col span={6} style={{ maxWidth: "24%" }}>
                                {" "}
                                <div className="digitalBox ">
                                  <div className="app-count">
                                    <p className="noa">
                                      {props.campaignStatsData.smsSentCount}
                                    </p>
                                    <img
                                      src={analyticsRed}
                                      className="waveImg"
                                      alt=""
                                    />
                                    <p className="application">
                                      Total Sms Sent
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div className="subscribers-detail">
                              <div className="completed-side">
                                <button className="d-btn completed-button">
                                  <img src={facheck} alt="" />
                                  Completed
                                </button>
                                <p className="total-numbers">
                                  {
                                    props.campaignStatsData
                                      .completedCampaignCount
                                  }
                                </p>
                              </div>
                              <div className=" completed-side">
                                <button className="d-btn progress-button">
                                  <img src={inProgress} alt="" />
                                  In Progress
                                </button>
                                <p className="total-numbers">
                                  {
                                    props.campaignStatsData
                                      .inProgressCampaignCount
                                  }
                                </p>
                              </div>
                              <div className="completed-side">
                                <button className="d-btn stopped-button">
                                  <img src={push} alt="" />
                                  Stopped
                                </button>
                                <p className="total-numbers">
                                  {props.campaignStatsData.stoppedCampaignCount}
                                </p>
                              </div>
                              <div className="completed-side">
                                <button className="d-btn not-stoped">
                                  <img src={notStarted} alt="" />
                                  Not Started
                                </button>
                                <p className="total-numbers">
                                  {
                                    props.campaignStatsData
                                      .notStartedCampaignCount
                                  }
                                </p>
                              </div>
                            </div>
                            {props.campaignStatsData?.campaignSmsSentGraphData
                              ?.length > 0 ? (
                              <ResponsiveContainer
                                className="LineChartContainer"
                                height="50%"
                              >
                                <LineChart
                                  width={500}
                                  height={300}
                                  data={
                                    props.campaignStatsData
                                      .campaignSmsSentGraphData
                                  }
                                  margin={{
                                    top: 25,
                                    right: 30,
                                    left: 10,
                                    bottom: 15,
                                  }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="monthYear" />
                                  <YAxis />
                                  <Tooltip />
                                  <Legend />
                                  <Line
                                    type="monotone"
                                    dataKey="smsSentCount"
                                    stroke="#8884d8"
                                    activeDot={{ r: 8 }}
                                  />
                                </LineChart>
                              </ResponsiveContainer>
                            ) : null}
                          </>
                        )}
                      </div>
                    ) : (
                      <div
                        className="cafe_side "
                        style={{ marginLeft: "15px", height: "800px" }}
                      >
                        <div className="cafe-top">
                          <div
                            className="cafe-top-left"
                            style={{ display: "flex" }}
                          >
                            <p>Campaigns Report</p>
                            <div className="cafeTopPara">|</div>
                            <div className="cafeTopPara">
                              {props.selectedBrand.name}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={() => {
                                  if (props.showTalbleView === false) {
                                    props.setShowTalbleView(true);
                                  } else {
                                    props.setShowTalbleView(false);
                                  }
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                            <DatePickerComponent
                              onDateHandler={props.onDateHandler}
                            />
                          </div>
                        </div>
                        {props.showTalbleView ? (
                          <>
                            {" "}
                            <ChannelReportTable
                              data={props.campaignStatsData}
                            />
                          </>
                        ) : (
                          <>
                            {" "}
                            <Row style={{ justifyContent: "space-between" }}>
                              <Col span={6} style={{ maxWidth: "24%" }}>
                                {" "}
                                <div className="digitalBox ">
                                  <div className="app-count">
                                    <p className="noa">
                                      {props.campaignStatsData.campaignCount}
                                    </p>
                                    <img
                                      src={analyticsGreen}
                                      className="waveImg"
                                      alt=""
                                    />
                                    <p className="application">
                                      Total Campaign
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col span={6} style={{ maxWidth: "24%" }}>
                                {" "}
                                <div className="digitalBox ">
                                  <div className="app-count">
                                    <p className="noa">
                                      {props.campaignStatsData.subscriberCount}
                                    </p>
                                    <img
                                      src={analyticsDefault}
                                      className="waveImg"
                                      alt=""
                                    />
                                    <p className="application">
                                      Total Subscriber
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col span={6} style={{ maxWidth: "24%" }}>
                                {" "}
                                <div className="digitalBox ">
                                  <div className="app-count">
                                    <p className="noa">
                                      {props.campaignStatsData.unsubCount}
                                    </p>
                                    <img
                                      src={analyticsOrange}
                                      className="waveImg"
                                      alt=""
                                    />
                                    <p className="application">
                                      Total UnSubscriber
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col span={6} style={{ maxWidth: "24%" }}>
                                {" "}
                                <div className="digitalBox ">
                                  <div className="app-count">
                                    <p className="noa">
                                      {props.campaignStatsData.smsSentCount}
                                    </p>
                                    <img
                                      src={analyticsRed}
                                      className="waveImg"
                                      alt=""
                                    />
                                    <p className="application">
                                      Total Sms Sent
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div className="subscribers-detail">
                              <div className="completed-side">
                                <button className="d-btn completed-button">
                                  <img src={facheck} alt="" />
                                  Completed
                                </button>
                                <p className="total-numbers">
                                  {
                                    props.campaignStatsData
                                      .completedCampaignCount
                                  }
                                </p>
                              </div>
                              <div className=" completed-side">
                                <button className="d-btn progress-button">
                                  <img src={inProgress} alt="" />
                                  In Progress
                                </button>
                                <p className="total-numbers">
                                  {
                                    props.campaignStatsData
                                      .inProgressCampaignCount
                                  }
                                </p>
                              </div>
                              <div className="completed-side">
                                <button className="d-btn stopped-button">
                                  <img src={push} alt="" />
                                  Stopped
                                </button>
                                <p className="total-numbers">
                                  {props.campaignStatsData.stoppedCampaignCount}
                                </p>
                              </div>
                              <div className="completed-side">
                                <button className="d-btn not-stoped">
                                  <img src={notStarted} alt="" />
                                  Not Started
                                </button>
                                <p className="total-numbers">
                                  {
                                    props.campaignStatsData
                                      .notStartedCampaignCount
                                  }
                                </p>
                              </div>
                            </div>
                            {props.campaignStatsData?.campaignSmsSentGraphData
                              ?.length > 0 ? (
                              <ResponsiveContainer
                                className="LineChartContainer"
                                height="50%"
                              >
                                <LineChart
                                  width={500}
                                  height={300}
                                  data={
                                    props.campaignStatsData
                                      .campaignSmsSentGraphData
                                  }
                                  margin={{
                                    top: 25,
                                    right: 30,
                                    left: 10,
                                    bottom: 15,
                                  }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="monthYear" />
                                  <YAxis />
                                  <Tooltip />
                                  <Legend />
                                  <Line
                                    type="monotone"
                                    dataKey="smsSentCount"
                                    stroke="#8884d8"
                                    activeDot={{ r: 8 }}
                                  />
                                </LineChart>
                              </ResponsiveContainer>
                            ) : null}
                          </>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <div style={{ textAlign: "center" }}>No Record Found</div>
            )}
          </>
        ) : (
          <Spin indicator={antIcon} />
        )}
      </div>
    </>
  );
};

export default CampaignReport;
