import React from "react";
import "../StyleSheets/AddTimeSlot.css";

type Props = {
  setOpenTimeSlot: React.Dispatch<React.SetStateAction<boolean>>;
};

function AddTimeSlot(props: Props) {
  return (
    <>
      <div id="Ad_time_slot">
        <div id="timeslotmodal">
          <div className="ts-m-modalTop">
            <h2>Ad Time Slots</h2>
          </div>

          <div className="silverSlot">
            <p className="greytext">Silver</p>

            <div className="s-no-slots">
              <span className="am-pm">AM</span>
              <span className="silver-color">1</span>
              <span className="silver-color">2</span>
              <span className="silver-color">3</span>
              <span className="silver-color">4</span>
              <span className="silver-color">5</span>
              <span className="silver-color">6</span>
              <span className="silver-color">7</span>
              <span >8</span>
              <span>9</span>
              <span>10</span>
              <span>11</span>
              <span>12</span>
            </div>
            <div className="s-no-slots">
              <span className="am-pm">PM</span>
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <span>5</span>
              <span>6</span>
              <span>7</span>
              <span>8</span>
              <span>9</span>
              <span>10</span>
              <span >11</span>
              <span className="silver-color">12</span>
            </div>
          </div>
          <div className="platinumSlot">
            <p className="greytext">Platinum</p>

            <div className="s-no-slots">
              <span className="am-pm">AM</span>
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <span>5</span>
              <span className="">6</span>
              <span className="">7</span>
              <span className="Platinum-color">8</span>
              <span className="Platinum-color">9</span>
              <span className="Platinum-color">10</span>
              <span className="Platinum-color">11</span>
              <span className="Platinum-color">12</span>
            </div>
            <div className="s-no-slots">
              <span className="am-pm">PM</span>
              <span className="Platinum-color">1</span>
              <span className="Platinum-color">2</span>
              <span className="Platinum-color">3</span>
              <span className="Platinum-color">4</span>
              <span className="Platinum-color">5</span>
              <span >6</span>
              <span>7</span>
              <span>8</span>
              <span>9</span>
              <span>10</span>
              <span>11</span>
              <span>12</span>
            </div>
          </div>
          <div className="goldSlot">
            <p className="greytext">Gold</p>

            <div className="s-no-slots">
              <span className="am-pm">AM</span>
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <span>5</span>
              <span>6</span>
              <span>7</span>
              <span>8</span>
              <span>9</span>
              <span>10</span>
              <span >11</span>
              <span >12</span>
            </div>
            <div className="s-no-slots">
              <span className="am-pm">PM</span>
              <span >1</span>
              <span >2</span>
              <span>3</span>
              <span>4</span>
              <span>5</span>
              <span className="golden-color">6</span>
              <span className="golden-color">7</span>
              <span className="golden-color">8</span>
              <span className="golden-color">9</span>
              <span className="golden-color">10</span>
              <span className="golden-color">11</span>
              <span >12</span>
            </div>
          </div>

          <button
            className="close__btn"
            onClick={() => {
              props.setOpenTimeSlot(false);
            }}
          >
            Close
          </button>
        </div>
      </div>

      <div id="ts-m-overlay"></div>
    </>
  );
}

export default AddTimeSlot;
