import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchGetPWKey } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import { fetchPosts } from "../Utils/FetchApis";
import CreateAdd from "../Components/CreateAdd";
import Swal from "sweetalert2";
import moment from "moment";
import UserContext from "../Utils/User_Context";

type Props = {
  token: any;
};
interface Option {
  label: string;
  value: string;
  children?: Option[];
}
const PlacesData = ["metro", "Cafe", "Buss Station", "Sign Board"];
const options: Option[] = [
  {
    label: "Resturant",
    value: "resturant",
    children: [
      { label: "Pizza HUT/G8 Markaz", value: "Pizza HUT/G8 Markaz" },
      { label: "Subway/Giga Mall", value: "SubWay/Giga Mall" },
    ],
  },
  {
    label: "BUS",
    value: "BUS",
    children: [
      { label: "Metro/Islamabad", value: "Metro/Islamabad" },
      { label: "Metro/Lahore", value: "Metro/Lahore" },
    ],
  },
];
const AdAddScreen = (props: Props) => {
  const navigation = useNavigate();
  const { state } = useLocation();
  const { onHandleAdList, adMainList, isAdmin } = useContext(UserContext);

  useEffect(() => {
    let id = localStorage.getItem("id");
    let name = localStorage.getItem("username");
    let email = localStorage.getItem("email");
    if (id === null && name === null && email === null) {
      navigation("/login");
    }
  }, []);

  let role = localStorage.getItem("role");
  let id = localStorage.getItem("id");

  useEffect(() => {
    if (state) {
      setStateBrand(state.BrandData);
      setSelectedBrandOption(state.BrandData);
    }

    if (props.token) {
      if (role === "CHANNEL") {
        getBrandListHandler(undefined);
        getChannelListHandler();
        getCategoriesAndTenuresHandler();
      } else {
        setHideBrandInput(true);
        getChannelListHandler();
        getCategoriesAndTenuresHandler();
      }
    }
  }, [props.token]);

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [selectedSubOption, setSelectedSubOption] = useState<Option | null>(
    null
  );

  const [selectedCategoriesOptions, setSelectedCategoriesOptions] =
    useState<any>([]);
  const [selectedTenureOptions, setSelectedTenureOptions] = useState<any>([]);
  const [selectedBrandOption, setSelectedBrandOption] = useState<Option | null>(
    null
  );
  const [brandOptions, setBrandOptions] = useState<any>([]);
  const [channelOptions, setChannelOptions] = useState<any>([]);
  const [channelSubOptions, setChannelSubOptions] = useState<any>([]);
  const [categoriesOptions, setCategoriesOptions] = useState<any>([]);
  const [tenureOptions, setTenureOptions] = useState<any>([]);
  const [imgUpload, setImgUpload] = useState<any>("");
  const [imgData, setImgData] = useState<any>("");
  const [stateBrand, setStateBrand] = useState<any>("");
  const [imgVal, setImgVal] = useState("");
  const [isloader, setIsLoader] = useState(false);
  const [imgVal2, setImgVal2] = useState("");
  const [imgUpload2, setImgUpload2] = useState<any>("");
  const [imgData2, setImgData2] = useState<any>("");
  const [openTimeSlot, setOpenTimeSlot] = useState(false);
  const [openTimeSlot2, setOpenTimeSlot2] = useState(false);
  const [errortitle, setErrortitle] = useState(false);
  const [erroramount, setErroramount] = useState(false);
  const [errorareaId, setErrorareaId] = useState(false);
  const [errorbannerBase64, setErrorbannerBase64] = useState(false);
  const [errorbrandId, setErrorbrandId] = useState(false);
  const [errorcategory, setErrorcategory] = useState(false);
  const [errorstartDate, setErrorstartDate] = useState(false);
  const [errortenure, setErrortenure] = useState(false);
  const [errorlandscapeBannerBase64, setErrorlandscapeBannerBase64] =
    useState(false);
  const [hideBrandInput, setHideBrandInput] = useState(false);

  const handleFile = (e: any) => {
    let extention = e.target.files[0].name.toLowerCase().endsWith(".webp");

    if (extention) {
      setImgVal(e.target.value);

      try {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let val = reader.result as string;
          let arr = val?.split(",");
          let buffer = arr[1];
          setImgData(buffer);
          setImgUpload(reader.result);
        };
      } catch (error) {
        console.log("handleFile error", error);
      }
    } else {
      setImgVal("");
      alert("Image is not in valid format..!");
    }
  };
  const handleFile2 = (e: any) => {
    let extention = e.target.files[0].name.toLowerCase().endsWith(".webp");

    if (extention) {
      setImgVal2(e.target.value);

      try {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let val = reader.result as string;
          let arr = val?.split(",");
          let buffer = arr[1];
          setImgData2(buffer);
          setImgUpload2(reader.result);
        };
      } catch (error) {
        console.log("handleFile error", error);
      }
    } else {
      setImgVal2("");
      alert("Image is not in valid format..!");
    }
  };

  const handleOptionChange = (option: Option | null) => {
    setSelectedOption(option);
    setSelectedSubOption(null);
    setHideBrandInput(false);
    getBrandListHandler(option?.value);
    getChannelAreaListHandler(option?.value);
  };
  const handleBrandOptionChange = (option: Option | null) => {
    setStateBrand("");

    setSelectedBrandOption(option);
  };

  const handleSubOptionChange = (option: Option | null) => {
    setSelectedSubOption(option);
  };

  const handleCategoriesOptions = (option: Option | null) => {
    setSelectedCategoriesOptions(option);
  };
  const handleTenureOptions = (option: Option | null) => {
    setSelectedTenureOptions(option);
  };

  const handleFormikValues = (
    title: string | null,
    amount: string | null,
    date: any
  ) => {
    creatAddHandler(title, date, amount);
  };

  const getBrandListHandler = async (channelId: any) => {
    let array = [];
    let endPoint;
    if (role === "CHANNEL") {
      endPoint = `${ApiNames.brandList}?channelId=${id}`;
    } else {
      endPoint = `${ApiNames.brandList}?channelId=${channelId}`;
    }

    try {
      const result = await fetchGetPWKey(endPoint, props.token.jwttoken);
      if (result.status === 0) {
        let data = result.response;
        for (let i = 0; i < data.length; i++) {
          array.push({ label: data[i].name, value: data[i].id });
        }
        setBrandOptions(array);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.response,
        });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getChannelListHandler = async () => {
    let array = [];

    let endpoint;
    if (role === "CHANNEL") {
      endpoint = `${ApiNames.channelView}/byId?id=${id}`;
    } else {
      endpoint = ApiNames.channelView;
    }

    try {
      const result = await fetchGetPWKey(endpoint, props.token.jwttoken);
      if (result.status === 0) {
        let data = result.response;
        if (role === "CHANNEL") {
          array.push({ label: data.fullName, value: data.id });
        } else {
          for (let i = 0; i < data.length; i++) {
            array.push({ label: data[i].name, value: data[i].id });
          }
        }

        setChannelOptions(array);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.response,
        });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getChannelAreaListHandler = async (id: string | undefined) => {
    let array = [];
    try {
      const result = await fetchGetPWKey(
        ApiNames.channelareas + "?channelId=" + id,
        props.token.jwttoken
      );
      if (result.status === 0) {
        let data = result.response;
        for (let i = 0; i < data.length; i++) {
          array.push({ label: data[i].name, value: data[i].id });
        }
        setChannelSubOptions(array);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.response,
        });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getCategoriesAndTenuresHandler = async () => {
    let Carray = [];
    let Tarray = [];
    try {
      const result = await fetchGetPWKey(
        ApiNames.adCategoriesAndTenures,
        props.token.jwttoken
      );
      if (result.status === 0) {
        let categoriesData = result.response.categories;
        let tenuresData = result.response.tenures;
        for (let i = 0; i < categoriesData.length; i++) {
          Carray.push({
            label: categoriesData[i].text,
            value: categoriesData[i].val,
          });
        }
        for (let i = 0; i < tenuresData.length; i++) {
          Tarray.push({
            label: tenuresData[i].text,
            value: tenuresData[i].val,
          });
        }
        setCategoriesOptions(Carray);
        setTenureOptions(Tarray);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.response,
        });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const creatAddHandler = async (t: any, d: any, a: any) => {
    setIsLoader(true);
    const formattedDate = moment(d).format("YYYY-MM-DD");
    const data = {
      brandId: selectedBrandOption?.value,
      areaId: selectedSubOption?.value,
      title: t,
      tenure: selectedTenureOptions.value,
      category: selectedCategoriesOptions.value,
      landscapeBannerBase64: imgData,
      portraitBannerBase64: imgData2,
      amount: a,
      startDate: formattedDate.toString(),
    };

    if (
      data.amount &&
      data.areaId &&
      data.landscapeBannerBase64 &&
      data.brandId &&
      data.category &&
      data.startDate &&
      data.tenure &&
      data.title &&
      data.portraitBannerBase64
    ) {
      try {
        let response = await fetchPosts(
          ApiNames.adCreate,
          data,
          props.token.jwttoken
        );

        if (response.status === 0) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Ad Created successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          navigation("/brand/brandandadddashboard");
        } else if (response.status === 1) {
          setIsLoader(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.response,
          });
        } else {
          setIsLoader(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      } catch (err) {
        setIsLoader(false);
        console.log(" failed: " + err);
      }
    } else {
      setIsLoader(false);
      if (data.amount) {
        setErroramount(false);
      } else {
        setErroramount(true);
      }
      if (data.areaId) {
        setErrorareaId(false);
      } else {
        setErrorareaId(true);
      }
      if (data.landscapeBannerBase64) {
        setErrorbannerBase64(false);
      } else {
        setErrorbannerBase64(true);
      }

      if (data.brandId) {
        setErrorbrandId(false);
      } else {
        setErrorbrandId(true);
      }

      if (data.category) {
        setErrorcategory(false);
      } else {
        setErrorcategory(true);
      }
      if (data.startDate) {
        setErrorstartDate(false);
      } else {
        setErrorstartDate(true);
      }
      if (data.tenure) {
        setErrortenure(false);
      } else {
        setErrortenure(true);
      }
      if (data.title) {
        setErrortitle(false);
      } else {
        setErrortitle(true);
      }
      if (data.landscapeBannerBase64) {
        setErrorlandscapeBannerBase64(false);
      } else {
        setErrorlandscapeBannerBase64(true);
      }
    }
  };

  return (
    <CreateAdd
      handleOptionChange={handleOptionChange}
      handleSubOptionChange={handleSubOptionChange}
      handleCategoriesOptions={handleCategoriesOptions}
      handleTenureOptions={handleTenureOptions}
      selectedOption={selectedOption}
      selectedSubOption={selectedSubOption}
      PlacesData={PlacesData}
      options={options}
      handleFile={handleFile}
      imgData={imgData}
      handleBrandOptionChange={handleBrandOptionChange}
      selectedBrandOption={selectedBrandOption}
      brandOptions={brandOptions}
      channelOptions={channelOptions}
      channelSubOptions={channelSubOptions}
      categoriesOptions={categoriesOptions}
      tenureOptions={tenureOptions}
      selectedCategoriesOptions={selectedCategoriesOptions}
      selectedTenureOptions={selectedTenureOptions}
      handleFormikValues={handleFormikValues}
      imgVal={imgVal}
      imgUpload={imgUpload}
      isloader={isloader}
      handleFile2={handleFile2}
      imgUpload2={imgUpload2}
      imgVal2={imgVal2}
      errortitle={errortitle}
      erroramount={erroramount}
      errorareaId={errorareaId}
      errorbannerBase64={errorbannerBase64}
      errorbrandId={errorbrandId}
      errorcategory={errorcategory}
      errorstartDate={errorstartDate}
      errortenure={errortenure}
      errorlandscapeBannerBase64={errorlandscapeBannerBase64}
      setErrortitle={setErrortitle}
      setErroramount={setErroramount}
      setErrorareaId={setErrorareaId}
      setErrorbannerBase64={setErrorbannerBase64}
      setErrorbrandId={setErrorbrandId}
      setErrorcategory={setErrorcategory}
      setErrorstartDate={setErrorstartDate}
      setErrortenure={setErrortenure}
      setErrorlandscapeBannerBase64={setErrorlandscapeBannerBase64}
      setOpenTimeSlot={setOpenTimeSlot}
      openTimeSlot={openTimeSlot}
      openTimeSlot2={openTimeSlot2}
      setOpenTimeSlot2={setOpenTimeSlot2}
      state={stateBrand}
      hideBrandInput={hideBrandInput}
    />
  );
};

export default AdAddScreen;
