import React from "react";
import "../StyleSheets/AddTimeSlot.css";

type Props = {
  setOpenTimeSlot: React.Dispatch<React.SetStateAction<boolean>>;
};

function AddTimeSlot2(props: Props) {
  return (
    <>
      <div id="Ad_time_slot">
        <div id="tenuremodal">
          <div className="ts-m-modalTop">
            <h2>Tenure</h2>
          </div>
          <div className="silverSlot">
            <div className="table-content">
            <h3>Days</h3>

            <table>
              <tr>
                <td>Platinum</td>
                <td className="days-one">15days</td>
              </tr>
              <tr>
                <td>Gold</td>
                <td className="days-two">10days</td>
              </tr>
              <tr>
                <td>Silver</td>
                <td className="days-three">7days</td>
              </tr>
            </table>
          </div>
          </div>
          <div className="silverSlot">
            <div className="table-content">
            <h3>Month</h3>

            <table>
              <tr>
                <td>Platinum</td>
                <td className="days-one">30days</td>
              </tr>
              <tr>
                <td>Gold</td>
                <td className="days-two">30days</td>
              </tr>
              <tr>
                <td>Silver</td>
                <td className="days-three">30days</td>
              </tr>
            </table>
          </div>
          </div>
          <button
            className="close__btn"
            onClick={() => {
              props.setOpenTimeSlot(false);
            }}
          >
            Close
          </button>
        </div>
      </div>

      <div id="ts-m-overlay"></div>
    </>
  );
}

export default AddTimeSlot2;
