import React, { useEffect, useState } from "react";
import DevicesDashboard from "../Components/DevicesDashboard";
import { fetchGetPWKey } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

type Props = {
  token: any;
};

function DevicesDashboardScreen(props: Props) {
  const [deviceData, setDeviceData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLoader, setIsLoader] = useState(false);
  const [moreInfo, setMoreInfo] = useState(false);
  const [moreInfoIndex, setMoreInfoIndex] = useState(0);
  const [blockState, setBlockState] = useState(false);

  const { state } = useLocation();
  const navigation = useNavigate();
  let role = localStorage.getItem("role");
  let id = localStorage.getItem("id");

  useEffect(() => {
    let id = localStorage.getItem("id");
    let name = localStorage.getItem("username");
    let email = localStorage.getItem("email");

    if (id === null && name === null && email === null) {
      navigation("/login");
    }
  }, []);

  useEffect(() => {
    if (props.token) {
      deviceDateRequest(role, id);
    }
  }, [props.token]);

  const refreshHandler = () => {
    deviceDateRequest2();
  };

  const deviceDateRequest = async (role: any, id: any) => {
    setIsLoader(true);
    let endPoint;

    try {
      if (role.toLowerCase() === "channel") {
        endPoint = `${ApiNames.deviceList}?channelId=${id}`;
      } else {
        endPoint = ApiNames.deviceList;
      }
      const result = await fetchGetPWKey(endPoint, props.token.jwttoken);
      if (result.status === 0) {
        if (state) {
          if (state.channelId) {
            let temp = result.response;
            const newArr = temp.filter((e: any) => {
              if (e.channel.id === state.channelId) {
                return e;
              }
            });
            setIsLoader(false);
            setDeviceData(newArr);
          } else if (state.AreaId) {
            let temp = result.response;
            const newArr = temp.filter((e: any) => {
              if (e.area.id === state.AreaId) {
                return e;
              }
            });
            setIsLoader(false);
            setDeviceData(newArr);
          }
        } else {
          setIsLoader(false);
          setDeviceData(result.response);
        }
      } else {
        setIsLoader(false);
        alert("something went wrong...!");
      }
    } catch (error) {
      setIsLoader(false);
      console.log(error);
    }
  };
  const deviceDateRequest2 = async () => {
    setIsLoader(true);
    try {
      const result = await fetchGetPWKey(
        ApiNames.deviceList,
        props.token.jwttoken
      );
      if (result.status === 0) {
        setIsLoader(false);
        setDeviceData(result.response);
      } else {
        setIsLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "something went wrong...!",
        });
      }
    } catch (error) {
      setIsLoader(false);
      console.log(error);
    }
  };
  const incrementPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const decrementPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = deviceData.slice(indexOfFirstItem, indexOfLastItem);
  const pageNumbers: number[] = [];
  for (let i = 1; i <= Math.ceil(deviceData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const getPageNumbersToShow = () => {
    const pages = [...pageNumbers];
    const index = pages.indexOf(currentPage);
    const start = index;
    const end = index + 2;
    return pages
      .slice(start, end)
      .filter((page) => page >= 1 && page <= pageNumbers.length);
  };

  const pageNumbersToShow = getPageNumbersToShow();
  const handleSearchQueryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };
  const filteredData = deviceData.filter(
    (item: any) =>
      item.area.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.channel.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.deviceInfo?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleDeviceInfo = (string: any, index: number) => {
    const str = string;

    const keyValuePairs = str.split(",");
    let data = "";

    keyValuePairs.forEach((keyValuePair: any) => {
      const [key, value] = keyValuePair?.split(":");
      if (index === 1) {
        if (key === "deviceName") {
          data = value;
        }
      } else if (index === 2) {
        if (key === "deviceModel") {
          data = value;
        }
      } else if (index === 3) {
        if (key === "IPAddress") {
          data = value;
        }
      } else if (index === 4) {
        if (key === "deviceBrand") {
          data = value;
        }
      } else if (index === 5) {
        if (key === "deviceHardware") {
          data = value;
        }
      } else if (index === 6) {
        if (key === "deviceOS") {
          data = value;
        }
      } else if (index === 7) {
        if (key === "OSVersion") {
          data = value;
        }
      } else if (index === 8) {
        if (key === "APILevel") {
          data = value;
        }
      } else if (index === 9) {
        if (key === "deviceType") {
          data = value;
        }
      } else if (index === 10) {
        if (key === "appVersion") {
          data = value;
        }
      } else if (index === 11) {
        if (key === "appVersionCode") {
          data = value;
        }
      } else if (index === 12) {
        if (key === "lastUpdateTime") {
          data = value;
        }
      } else if (index === 13) {
        if (key === "Carrier") {
          data = value;
        }
      }
    });
    return data;
  };

  const HandleDeviceBlock = (id: number) => {
    Swal.fire({
      title: "Are you sure you want to Block Device?",

      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Device Blocked", "", "success");
        blockChannel(id);
      }
    });
  };
  const blockChannel = async (id: number) => {
    try {
      const result = await fetchGetPWKey(
        ApiNames.deviceBlock + "?deviceId=" + id,
        props.token.jwttoken
      );
      if (result.status === 0) {
        refreshHandler();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
      console.log(error);
    }
  };
  const HandleDeviceUnBlock = (id: number) => {
    Swal.fire({
      title: "Are you sure you want to unblock the Device?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Device UnBlocked", "", "success");
        unblockChannel(id);
      }
    });
  };
  const unblockChannel = async (id: number) => {
    try {
      const result = await fetchGetPWKey(
        ApiNames.deviceUnblock + "?deviceId=" + id,
        props.token.jwttoken
      );
      if (result.status === 0) {
        refreshHandler();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <DevicesDashboard
      HandleDeviceBlock={HandleDeviceBlock}
      HandleDeviceUnBlock={HandleDeviceUnBlock}
      deviceData={deviceData}
      pageNumbers={pageNumbers}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      incrementPage={incrementPage}
      decrementPage={decrementPage}
      pageNumbersToShow={pageNumbersToShow}
      currentData={currentData}
      searchQuery={searchQuery}
      handleSearchQueryChange={handleSearchQueryChange}
      filteredData={filteredData}
      handleDeviceInfo={handleDeviceInfo}
      refreshHandler={refreshHandler}
      isLoader={isLoader}
      moreInfo={moreInfo}
      setMoreInfo={setMoreInfo}
      moreInfoIndex={moreInfoIndex}
      setMoreInfoIndex={setMoreInfoIndex}
      role={role}
    />
  );
}

export default DevicesDashboardScreen;
