import React, { useEffect, useState } from "react";
import ChannelDashboard from "../Components/ChannelDashboard";
import { fetchGetPWKey, fetchPosts } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ChannelReport from "../Components/ChannelReport";

type Props = {
  token: any;
};

function ChannelReportScreen(props: Props) {
  const [channelData, setChannelData] = useState<any>([]);
  const [selectedChannel, setSelectedChannel] = useState("");
  const [addDevicesEditData, setAddDevicesEditData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(3);
  const [clickedElement, setClickedElement] = useState<number>(-1);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [tempData, setTempData] = useState<any>([]);
  const [reverseList, setReverseList] = useState<boolean>(true);
  const [fromdate, setFromdate] = useState<any>("");
  const [todate, setTodate] = useState<any>("");
  const [channelStatsData, setChannelStatsData] = useState<any>("");
  const [showTalbleView, setShowTalbleView] = useState(false);

  let role = localStorage.getItem("role");
  let id = localStorage.getItem("id");

  const incrementPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const decrementPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const navigation = useNavigate();
  useEffect(() => {
    let id = localStorage.getItem("id");
    let name = localStorage.getItem("username");
    let email = localStorage.getItem("email");
    if (id === null && name === null && email === null) {
      navigation("/login");
    }
  }, []);

  useEffect(() => {
    if (props.token) {
      channelDateRequest();
    }
  }, [props.token]);

  const channelDateRequest = async () => {
    let endpoint;
    if (role === "CHANNEL") {
      endpoint = `${ApiNames.channelView}/byId?id=${id}`;
    } else {
      endpoint = ApiNames.channelView;
    }

    setMainPageLoading(true);
    try {
      const result = await fetchGetPWKey(endpoint, props.token.jwttoken);
      if (result.status === 0) {
        let temp;
        if (role === "CHANNEL") {
          let demo = [];
          demo.push(result.response);
          temp = demo;
        } else {
          temp = result.response;
        }
        setMainPageLoading(false);

        if (temp.length > 0) {
          setChannelData(temp.reverse());
          setSelectedChannel(result.response[clickedElement]);
        }
      }
    } catch (error) {
      setMainPageLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
      console.log(error);
    }
  };

  const onClickedElement = (index: number) => {
    setClickedElement(index);
    setSelectedChannel(channelData[index]);
    if (index === -1) {
      const ApiName =
        ApiNames.statsChannel +
        "?fromDate=" +
        fromdate +
        "&toDate=" +
        todate +
        "&channelId=";
      channelStats(ApiName);
    } else {
      const ApiName =
        ApiNames.statsChannel +
        "?fromDate=" +
        fromdate +
        "&toDate=" +
        todate +
        "&channelId=" +
        channelData[index].id;
      channelStats(ApiName);
    }
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = channelStatsData?.deviceDetails?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const pageNumbers: number[] = [];
  for (
    let i = 1;
    i <= Math.ceil(channelStatsData?.deviceDetails?.length / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  const getPageNumbersToShow = () => {
    const pages = [...pageNumbers];
    const index = pages.indexOf(currentPage);
    const start = index;
    const end = index + 2;
    return pages
      .slice(start, end)
      .filter((page) => page >= 1 && page <= pageNumbers.length);
  };

  const pageNumbersToShow = getPageNumbersToShow();

  const handleDropDown = () => {
    if (openDropdown === true) {
      setOpenDropdown(false);
    } else {
      setOpenDropdown(true);
    }
  };
  const sortHandler = () => {
    if (reverseList === true) {
      setReverseList(false);
      let temp = [...channelData];
      temp?.sort((a: any, b: any) => a.name.localeCompare(b.name));
      setTempData(temp);
    } else {
      let temp = [...tempData];
      temp?.reverse();

      setTempData(temp);
    }
  };
  const recentHandler = () => {
    setReverseList(true);
    setClickedElement(0);
  };

  const channelStats = async (ApiName: any) => {
    try {
      const result = await fetchGetPWKey(ApiName, props.token.jwttoken);
      if (result.status === 0) {
        setChannelStatsData(result.response);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
      console.log(error);
    }
  };
  const onDateHandler = (FDate: any, TDate: any) => {
    if (props.token) {
      if (channelStatsData && clickedElement !== -1) {
        const ApiName =
          ApiNames.statsChannel +
          "?fromDate=" +
          FDate +
          "&toDate=" +
          TDate +
          "&channelId=" +
          channelData[clickedElement]?.id;
        channelStats(ApiName);
      } else {
        if (role === "CHANNEL") {
          channelStats(
            ApiNames.statsChannel +
              "?fromDate=" +
              FDate +
              "&toDate=" +
              TDate +
              "&channelId=" +
              id
          );
        } else {
          channelStats(
            ApiNames.statsChannel +
              "?fromDate=" +
              FDate +
              "&toDate=" +
              TDate +
              "&channelId="
          );
        }
      }
      setFromdate(FDate);
      setTodate(TDate);
    }
  };

  const handleDeviceInfo = (string: any, index: number) => {
    const str = string;

    const keyValuePairs = str.split(",");
    let data = "";

    keyValuePairs.forEach((keyValuePair: any) => {
      const [key, value] = keyValuePair?.split(":");
      if (index === 1) {
        if (key === "deviceName") {
          data = value;
        }
      }
    });
    return data;
  };

  return (
    <>
      <ChannelReport
        currentData={currentData}
        incrementPage={incrementPage}
        decrementPage={decrementPage}
        pageNumbersToShow={pageNumbersToShow}
        pageNumbers={pageNumbers}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        channelData={channelData}
        selectedChannel={selectedChannel}
        addDevicesEditData={addDevicesEditData}
        clickedElement={clickedElement}
        onClickedElement={onClickedElement}
        mainPageLoading={mainPageLoading}
        handleDropDown={handleDropDown}
        openDropdown={openDropdown}
        sortHandler={sortHandler}
        recentHandler={recentHandler}
        reverseList={reverseList}
        tempData={tempData}
        onDateHandler={onDateHandler}
        channelStatsData={channelStatsData}
        handleDeviceInfo={handleDeviceInfo}
        setShowTalbleView={setShowTalbleView}
        showTalbleView={showTalbleView}
        role={role}
      />
    </>
  );
}

export default ChannelReportScreen;
