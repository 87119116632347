import React from "react";

type Props = {
  data: any;
};

const ChannelReportTable = (props: Props) => {
  const convertToCSV = () => {
    const csvRows = [];

    // Extract table headers
    const headers = Array.from(document.querySelectorAll("table th")).map(
      (header) => header.textContent
    );

    csvRows.push(headers.join(",")); // Add headers to CSV

    // Extract table data
    const tableRows = document.querySelectorAll("table tbody tr");
    tableRows.forEach((row) => {
      const rowData = Array.from(row.children).map((cell) => cell.textContent);
      csvRows.push(rowData.join(","));
    });

    return csvRows.join("\n");
  };
  const handleDownload = () => {
    const csvData = convertToCSV();
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "table_data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <div
        className="Container"
        style={{ minHeight: "78vh", width: "100%", padding: "0" }}
      >
        {/* <div className="title-btn">
          <div className="search">
            <label>
              Search:
              <input
                type="text"
                name="name"
                // value={}
                // onChange={(e) => {
                //   props.setSearchQuery1(e.target.value);
                //   props.search(e.target.value);
                // }}
              />
            </label>
          </div>
        </div> */}

        <div
          className="table"
          //   style={
          //     props.data && props.data?.length > 7
          //       ? { height: "502px", overflow: "auto" }
          //       : { height: "auto" }
          //   }
        >
          <table>
            <thead>
              <tr>
                <th colSpan={1} rowSpan={1} style={{ textAlign: "center" }}>
                  Total Campaign
                </th>
                <th colSpan={1} rowSpan={1} style={{ textAlign: "center" }}>
                  Total Subscriber
                </th>
                <th colSpan={1} rowSpan={1} style={{ textAlign: "center" }}>
                  Total UnSubscriber
                </th>
                <th colSpan={1} rowSpan={1} style={{ textAlign: "center" }}>
                  Total Sms Sent
                </th>
                <th colSpan={1} rowSpan={1} style={{ textAlign: "center" }}>
                  Completed
                </th>
                <th colSpan={1} rowSpan={1} style={{ textAlign: "center" }}>
                  Inprogress
                </th>
                <th colSpan={1} rowSpan={1} style={{ textAlign: "center" }}>
                  Not Started
                </th>
                <th colSpan={1} rowSpan={1} style={{ textAlign: "center" }}>
                  Stoped
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ textAlign: "center" }}>
                <td style={{ textAlign: "center" }}>
                  {props.data.campaignCount}
                </td>
                <td style={{ textAlign: "center" }}>
                  {props.data.subscriberCount}
                </td>
                <td style={{ textAlign: "center" }}>{props.data.unsubCount}</td>
                <td style={{ textAlign: "center" }}>
                  {props.data.smsSentCount}
                </td>
                <td style={{ textAlign: "center" }}>
                  {props.data.completedCampaignCount}
                </td>
                <td style={{ textAlign: "center" }}>
                  {props.data.inProgressCampaignCount}
                </td>
                <td style={{ textAlign: "center" }}>
                  {props.data.notStartedCampaignCount}
                </td>
                <td style={{ textAlign: "center" }}>
                  {props.data.stoppedCampaignCount}
                </td>
              </tr>
            </tbody>
          </table>
          <button onClick={handleDownload}>Download CSV</button>
        </div>
      </div>
    </>
  );
};

export default ChannelReportTable;
