import React, { useState } from "react";
import "../StyleSheets/AddTimeSlot.css";
import keyImg from "../Assets/fi_rr_key.webp";
import eyeImg from "../Assets/fi_rr_eye.webp";
import eyeCrossImg from "../Assets/fi_rr_eye_crossed.webp";
import copyImg from "../Assets/fi_rr_copy.webp";
import { fetchPut } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import Swal from "sweetalert2";
import editImg from "../Assets/pencil.png";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

type Props = {
  setAuthModal: React.Dispatch<React.SetStateAction<boolean>>;
  setAuthKey: React.Dispatch<React.SetStateAction<string>>;
  authKey: string;
  token: any;
  channelId: any;
  channelDateRequest: () => Promise<void>;
};

function AuthenticationModal(props: Props) {
  const [inputValue, setInputValue] = useState(props.authKey);
  const [passbool, setPassBool] = useState(true);
  const [loader, setLoader] = useState(false);

  var inputType = "";
  if (passbool) {
    inputType = "password";
  } else {
    inputType = "text";
  }

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleCopyButtonClick = () => {
    if (inputValue) {
      // Create a new temporary input element to copy the text
      const tempInput = document.createElement("input");
      tempInput.value = inputValue;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    }
  };

  const onChannelKeyRequest = async (id: number) => {
    const data = {
      channelId: id,
    };
    setLoader(true);
    try {
      const result = await fetchPut(
        ApiNames.channelRegenerateKey,
        data,
        props.token.jwttoken
      );
      if (result.status === 0) {
        let temp = result.response;
        setLoader(false);
        setInputValue(temp);
      } else {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something Went Wrong..!",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };
  const HandleChannelAuthKey = (id: number) => {
    Swal.fire({
      title: "Are you sure you want to Change the AuthKey?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("AuthKey Changed", "", "success");
        onChannelKeyRequest(id);
      }
    });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "0px",
        width: "25px",
        marginTop: "25px",
        marginBottom: "65px",
      }}
      spin
    />
  );

  const onCloseHandler = () => {
    props.setAuthModal(false);
    props.channelDateRequest();
  };
  return (
    <>
      <div id="Ad_time_slot">
        <div id="timeslotmodal">
          <div className="ts-m-modalTop">
            <h2>Authentication Key</h2>
          </div>
          <div className="silverSlot">
            <p className="greytext2">Authentication Key</p>
            <div className="boaderinput">
              <img src={keyImg} className="imgStyle" />
              <input
                type={inputType}
                value={inputValue}
                onChange={handleInputChange}
                className="inputFieled"
                placeholder=""
              />
              {passbool ? (
                <img
                  src={eyeCrossImg}
                  className="imgStyle"
                  onClick={() => setPassBool(false)}
                />
              ) : (
                <img
                  src={eyeImg}
                  className="imgStyle"
                  onClick={() => setPassBool(true)}
                />
              )}
            </div>

            <div className="copyContainer" onClick={handleCopyButtonClick}>
              <img src={copyImg} className="imgStyle2" />
              Copy Key
            </div>
            {loader ? (
              <Spin indicator={antIcon} />
            ) : (
              <div
                className="editContainer"
                onClick={() => HandleChannelAuthKey(props.channelId)}
              >
                <img src={editImg} className="imgStyle2" />
                Get New Key
              </div>
            )}
          </div>
          <button
            className="close__btn"
            onClick={() => {
              onCloseHandler();
            }}
          >
            Close
          </button>
        </div>
      </div>

      <div id="ts-m-overlay"></div>
    </>
  );
}

export default AuthenticationModal;
