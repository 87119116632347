import React, { useEffect, useState, useContext } from "react";
import ChannelDashboard from "../Components/ChannelDashboard";
import { fetchGetPWKey, fetchPosts } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import UserContext from "../Utils/User_Context";

type Props = {
  token: any;
};

function ChannelDashboardScreen(props: Props) {
  const [addDevices, setAdddevice] = useState(false);
  const [error, setError] = useState(false);
  const [openDevicesList, setOpenDevicesList] = useState(false);
  const [channelData, setChannelData] = useState<any>([]);
  const [channelSortData, setChannelSortData] = useState<any>([]);
  const [areaData, setAreaData] = useState<any>([]);
  const [openCreateChannel, setOpenCreateChannel] = useState(false);
  const [openEditChannel, setOpenEditChannel] = useState(false);
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [handelCrossStatus, setHandelCrossStatus] = useState(false);
  const [sideMenuIndex, setSideMenuIndex] = useState(0);
  const [selectedChannel, setSelectedChannel] = useState("");
  const [addDevicesEditData, setAddDevicesEditData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [clickedElement, setClickedElement] = useState<number>(0);
  const [apiError, setApiError] = useState<string>("");
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [initialColor, setInitialColor] = useState(true);
  const [onloader, setOnloader] = useState(true);
  const [editloader, setEditLoader] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [deviceData, setDeviceData] = useState<any>([]);
  const [tempData, setTempData] = useState<any>([]);
  const [reverseList, setReverseList] = useState(true);
  const [authModal, setAuthModal] = useState(false);
  const [authKey, setAuthKey] = useState("");
  const { isAdmin } = useContext(UserContext);

  let id = localStorage.getItem("id");

  const incrementPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const decrementPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const navigation = useNavigate();
  useEffect(() => {
    let id = localStorage.getItem("id");
    let name = localStorage.getItem("username");
    let email = localStorage.getItem("email");
    if (id === null && name === null && email === null) {
      navigation("/login");
    }
  }, []);

  useEffect(() => {
    if (props.token) {
      channelDateRequest();
      deviceDateRequest();
    }
  }, [props.token]);
  useEffect(() => {
    if (props.token && handelCrossStatus) {
      channelDateRequest();
    }
  }, [openCreateChannel, openEditChannel]);

  useEffect(() => {
    if (props.token && openSideMenu === false) {
      channelDateRequest2();
    }
  }, [openSideMenu]);

  const deviceDateRequest = async () => {
    try {
      const result = await fetchGetPWKey(
        ApiNames.deviceList,
        props.token.jwttoken
      );
      if (result.status === 0) {
        let data = result.response;
        let temp = [];
        for (let i = 0; i < data.length; i++) {
          temp.push(data[i].area.id);
        }
        setDeviceData(temp);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something Went Wrong..!",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
      console.log(error);
    }
  };

  // const handleSearchQueryChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setSearchQuery(event.target.value);
  // };
  // const filteredData = areaData.filter((item: any) =>
  //   item.name.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  const handleAddDevices = (
    name: string,
    address: string,
    id: number,
    index: any
  ) => {
    let tempData = { name: name, address: address, id: id, index: index };
    setAddDevicesEditData(tempData);
    setAdddevice(true);
  };
  const HandleOpneDevices = () => {
    setOpenDevicesList(true);
  };

  const channelDateRequest2 = async () => {
    setMainPageLoading(true);
    try {
      const result = await fetchGetPWKey(
        ApiNames.channelView,
        props.token.jwttoken
      );
      if (result.status === 0) {
        setMainPageLoading(false);
        let temp = result.response;
        if (!isAdmin) {
          const filteredArray = result.response.filter(
            (item: any) => item.id == id
          );
          console.log("filter data" + filteredArray);
          setChannelData(filteredArray);
          areaList(filteredArray);
        } else {
          if (temp.length > 0) {
            setChannelData(temp.reverse());
            setSelectedChannel(result.response[clickedElement]);
          }
        }
      }
    } catch (error) {
      setMainPageLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
      console.log(error);
    }
  };
  const channelDateRequest = async () => {
    setMainPageLoading(true);
    try {
      const result = await fetchGetPWKey(
        ApiNames.channelView,
        props.token.jwttoken
      );
      if (result.status === 0) {
        setMainPageLoading(false);
        let temp = result.response;
        if (!isAdmin) {
          const filteredArray = result.response.filter(
            (item: any) => item.id == id
          );
          console.log("filter data" + filteredArray);
          setChannelData(filteredArray);
          areaList(filteredArray);
        } else {
          if (temp.length > 0) {
            setChannelData(temp.reverse());

            areaList(result.response[0]);
          }
        }
      }
    } catch (error) {
      setMainPageLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
      console.log(error);
    }
  };
  const areaList = async (item: any) => {
    let endPoint;

    if (!isAdmin && id) {
      endPoint = `${ApiNames.channelareas}?channelId=${id}`;
    } else {
      endPoint = `${ApiNames.channelareas}?channelId=${item.id}`;
    }
    setSelectedChannel(item);

    try {
      const result = await fetchGetPWKey(endPoint, props.token.jwttoken);
      setAreaData(result.response.reverse());
    } catch (error) {}
  };

  const HandleOpenCreateChannel = () => {
    setOpenCreateChannel(true);
  };
  const HandleCrossSign = () => {
    setOpenCreateChannel(false);
    setOpenEditChannel(false);
    setOpenSideMenu(false);
  };
  const HandleSuccessSign = () => {
    setClickedElement(0);
    setHandelCrossStatus(true);
    setOpenCreateChannel(false);
    setOpenEditChannel(false);
    setOpenSideMenu(false);
  };

  const HandlesideMenu = (index: any) => {
    setOpenSideMenu(true);
    setSideMenuIndex(index);
  };
  const HandleEditChannel = () => {
    setOpenEditChannel(true);
  };
  const HandleChannelBlock = (id: number) => {
    Swal.fire({
      title: "Are you sure you want to Block Channel?",

      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Channel Blocked", "", "success");
        blockChannel(id);
      } else {
        setOpenSideMenu(false);
      }
    });
  };
  const blockChannel = async (id: number) => {
    try {
      const result = await fetchGetPWKey(
        ApiNames.blockChannel + "?channelId=" + id,
        props.token.jwttoken
      );
      setOpenSideMenu(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
      console.log(error);
    }
  };
  const HandleChannelUnBlock = (id: number) => {
    Swal.fire({
      title: "Are you sure you want to unblock the channel?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Channel UnBlocked", "", "success");
        unblockChannel(id);
      } else {
        setOpenSideMenu(false);
      }
    });
  };
  const unblockChannel = async (id: number) => {
    try {
      const result = await fetchGetPWKey(
        ApiNames.unblockChannel + "?channelId=" + id,
        props.token.jwttoken
      );
      setOpenSideMenu(false);
    } catch (error) {
      console.log(error);
    }
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = areaData?.slice(indexOfFirstItem, indexOfLastItem);
  const pageNumbers: number[] = [];
  for (let i = 1; i <= Math.ceil(areaData?.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const ChannelAreaAddHandler = async (
    name: string,
    address: string,
    id: number
  ) => {
    setOnloader(false);

    const data = {
      channelId: id,
      areas: [
        {
          name: name,
          address: address,
        },
      ],
    };
    try {
      let response = await fetchPosts(
        ApiNames.channelareasadd,
        data,
        props.token.jwttoken
      );
      if (response.status === 0) {
        setOnloader(true);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Channel Area Added successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        areaList({ id });
      } else if (response.status === 1) {
        setOnloader(true);
        // setApiError(response.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.response,
        });
      }
    } catch (err) {
      setOnloader(true);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };
  const ChannelAreaEditHandler = async (
    name: string,
    address: string,
    id: number,
    channelId: number
  ) => {
    const data = {
      id: id,
      name: name,
      address: address,
    };
    setEditLoader(true);
    try {
      let response = await fetchPosts(
        ApiNames.channelAreasEdit,
        data,
        props.token.jwttoken
      );
      if (response.status === 0) {
        setEditLoader(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Channel Area Edit successfully",
          showConfirmButton: false,
          timer: 1700,
        });

        setAdddevice(false);
        areaList({ id: channelId });
      } else if (response.status === 1) {
        setEditLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.response,
        });
      }
    } catch (err) {
      setEditLoader(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something Went Wrong..!",
      });
    }
  };

  const getPageNumbersToShow = () => {
    const pages = [...pageNumbers];
    const index = pages.indexOf(currentPage);
    const start = index;
    const end = index + 2;
    return pages
      .slice(start, end)
      .filter((page) => page >= 1 && page <= pageNumbers.length);
  };

  const pageNumbersToShow = getPageNumbersToShow();
  const handleDelete = () => {
    setOpenSideMenu(false);
  };
  const handleDropDown = () => {
    if (openDropdown === true) {
      setOpenDropdown(false);
    } else {
      setOpenDropdown(true);
    }
  };
  const sortHandler = () => {
    if (reverseList === true) {
      setReverseList(false);
      let temp = [...channelData];
      temp?.sort((a: any, b: any) => a.name.localeCompare(b.name));
      setTempData(temp);
    } else {
      let temp = [...tempData];
      temp?.reverse();

      setTempData(temp);
    }
  };
  const recentHandler = () => {
    setReverseList(true);
    setClickedElement(0);
    areaList(channelData[0]);
  };
  const handleSearchQueryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };
  const filteredData = currentData.filter(
    (item: any) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.address.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const onAuthKeyPress = (item: any) => {
    setAuthKey(item);
    setAuthModal(true);
  };

  return (
    <>
      <ChannelDashboard
        channelDateRequest={channelDateRequest2}
        onAuthKeyPress={onAuthKeyPress}
        setAuthModal={setAuthModal}
        authModal={authModal}
        addDevices={addDevices}
        handleAddDevices={handleAddDevices}
        error={error}
        openDevicesList={openDevicesList}
        HandleOpneDevices={HandleOpneDevices}
        channelData={channelData}
        areaList={areaList}
        areaData={areaData}
        openCreateChannel={openCreateChannel}
        HandleOpenCreateChannel={HandleOpenCreateChannel}
        HandleCrossSign={HandleCrossSign}
        HandleSuccessSign={HandleSuccessSign}
        openEditChannel={openEditChannel}
        HandlesideMenu={HandlesideMenu}
        HandleEditChannel={HandleEditChannel}
        openSideMenu={openSideMenu}
        token={props.token}
        selectedChannel={selectedChannel}
        HandleChannelBlock={HandleChannelBlock}
        HandleChannelUnBlock={HandleChannelUnBlock}
        sideMenuIndex={sideMenuIndex}
        setAdddevice={setAdddevice}
        addDevicesEditData={addDevicesEditData}
        currentData={currentData}
        pageNumbers={pageNumbers}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        ChannelAreaAddHandler={ChannelAreaAddHandler}
        searchQuery={searchQuery}
        handleSearchQueryChange={handleSearchQueryChange}
        filteredData={filteredData}
        ChannelAreaEditHandler={ChannelAreaEditHandler}
        incrementPage={incrementPage}
        decrementPage={decrementPage}
        pageNumbersToShow={pageNumbersToShow}
        clickedElement={clickedElement}
        setClickedElement={setClickedElement}
        handleDelete={handleDelete}
        apiError={apiError}
        mainPageLoading={mainPageLoading}
        setInitialColor={setInitialColor}
        initialColor={initialColor}
        onloader={onloader}
        editloader={editloader}
        handleDropDown={handleDropDown}
        openDropdown={openDropdown}
        sortHandler={sortHandler}
        recentHandler={recentHandler}
        deviceData={deviceData}
        reverseList={reverseList}
        tempData={tempData}
        setAuthKey={setAuthKey}
        authKey={authKey}
        isAdmin={isAdmin}
      />
    </>
  );
}

export default ChannelDashboardScreen;
