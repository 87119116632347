import React, { useState } from "react";
import EditCampagin from "../Components/EditCampagin";
import * as moment from "moment";
import { fetchPosts } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import Swal from "sweetalert2";
type Props = {
  setOpenEditChannel: React.Dispatch<React.SetStateAction<boolean>>;
  token: any;
  successBtnHandler: () => void;
  selectedCampaign: any;
};

function EditCampaginScreen(props: Props) {
  const [descriptionError, setDescriptionError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEditCampaign = async (title: string, smsText: string) => {
    let d = moment(props.selectedCampaign.startDatetime).format("YYYY-MM-DD hh:mm:ss")
    let data = {
      id: props.selectedCampaign.id,
      brandId: props.selectedCampaign.brandId,
      title: title,
      startDatetime: d,
      smsText: smsText,
    };
    setLoading(true);
    if (data.smsText && data.title) {
      try {
        let response = await fetchPosts(
          ApiNames.campaignEdit,
          data,
          props.token.jwttoken
        );

        if (response.status === 0) {
          setLoading(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Campaign Successfuly Edit",
            showConfirmButton: false,
            timer: 1500,
          });
          props.setOpenEditChannel(false);
          props.successBtnHandler();
        } else if (response.status === 1) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.response,
          });
        }
      } catch (err) {
        console.log(" failed: " + err);
        setLoading(false);
      }
    } else {
      setLoading(false);

      if (data.title) {
        setTitleError(false);
      } else {
        setTitleError(true);
      }
      if (data.smsText) {
        setDescriptionError(false);
      } else {
        setDescriptionError(true);
      }
    }
  };
  return (
    <>
      <EditCampagin
      selectedCampaign={props.selectedCampaign}
        titleError={titleError}
        setTitleError={setTitleError}
        descriptionError={descriptionError}
        setDescriptionError={setDescriptionError}
        setOpenEditChannel={props.setOpenEditChannel}
        handleEditCampaign={handleEditCampaign}
        loading={loading}
      />
    </>
  );
}

export default EditCampaginScreen;
