import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ChangePassword from '../Components/ChangePassword'
import ApiNames from '../Constants/ApiNames';
import { fetchPosts } from '../Utils/FetchApis';

type Props = {
  token: any;
}

const ChangePasswordScreen = (props: Props) => {
  const [errorMsg,setErrorMsg]=useState('')
  const [isLoader,setIsLoader]=useState(false)
  const { state } = useLocation();
  const navigation = useNavigate()
  const handleValues = async (
    password: string,
  ) => {
    setIsLoader(true)
    const data = {
      resetKey:state.resetKey,
      newPassword:password
    };
    try {
      let response = await fetchPosts(
        ApiNames.recoverAccount,
        data,
        props.token.jwttoken
      );
      if (response.status === 0) {
        setIsLoader(false)
        navigation('/login')
      } else if (response.status === 1) {
        setIsLoader(false)
        setErrorMsg(response.response)
      }
    } catch (err) {
      setIsLoader(false)
      setErrorMsg("Somthing Went Wrong...!")
    }

  };
  return (
    <ChangePassword  handleValues={handleValues} errorMsg={errorMsg} isLoader={isLoader}/>
  )
}

export default ChangePasswordScreen